.modal {
  height: 100%;
  width: 100%;
  background-color: rgba(0,0,0,0.4);
  position: fixed;
  top: 0;
  left: 0;
  display: flex !important;
  align-items: center;
  justify-content: center !important;
  opacity: 0;
  pointer-events: none;
  transition: 0.5s;
  z-index: 999;

  &__content {
    border-radius: 12px;
    background-color: white;
    transform: scale(0.5);
    transition: 0.4s all;
  }
}

.modal.active {
  opacity: 1;
  pointer-events: all;
  z-index: 999;
}

.modal__content.active {
  transform: scale(1);
  padding: 20px;
  //width: 25%;

  .react-calendar {
    border: none;
  }
}

.modal-width {
  width: 100%;
}

@media (max-width: 700px) {
  .modal__content.active {
    //width: 65%;
    width: 45%;
    // padding-left: 10px;
    // padding-right: 10px;
  }
}

//@media (max-width: 495px) {
//  .modal__content.active {
//    width: 85%;
//  }
//}

@media (max-width: 495px) {
  .modal__content.active {
    width: 65%;
  }
}

