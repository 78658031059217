.custom-select__control {
    border-radius: 0px !important;
    border: none !important;
    border-bottom: 1px solid #f05d33 !important;
  }
  
  .custom-select__control:hover {
    border-color: #f05d33 !important;
  }
  
  .custom-select__control--is-focused {
    border-color: #f05d33 !important;
    box-shadow: 0 0 0 0 #f05d33 !important;
  }