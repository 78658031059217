@import "../../variables/index";


.modal {
  display: flex;
  justify-content: space-between;

  &__head {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: $black-color;
  }

  &__head-icon {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }

  &__body {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: $black-color;
    padding-bottom: 30px;

    &-name {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: $gray-color;
    }

    &-card {
      width: 100%;
      padding-top: 20px;

      input {
        width: 100%;
        border-top-style: hidden;
        border-right-style: hidden;
        border-left-style: hidden;
        border-bottom-style: solid;
        border-bottom-color: $rgba-orange-color-o;
        background-color: $white-color;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        padding-bottom: 12px;
      }

      .no-outline:focus {
        outline: none;
      }
    }

  }

  &__footer {
    display: flex;
    padding: 20px 0;

    &-button {
      width: 100%;
      height: 50px;
      background-color: $orange-color;
      border-radius: 15px;
      border: none;
      color: $white-color;
    }

    &-text {
      display: flex;
      align-self: center;
      justify-content: center;
      cursor: pointer;
    }
  }
}

.modal-auto-pay {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  //width: 400px;
  width: 26%;
  height: 135px;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%);
  padding: 22px;

  @media (max-width: 830px) {
    height: 100px;
    width: 30%;
  }

  @media (max-width: 768px) {
    height: 100px;
    width: 50%;
  }

  @media (max-width: 495px) {
    height: 100px;
    width: 50%;
  }

  @media (max-width: 450px) {
    height: 100px;
    width: 50%;
  }

  @media (max-width: 395px) {
    height: 100px;
    width: 60%;
  }

  @media (max-width: 330px) {
    height: 100px;
    width: 80%;
  }

}

.auto-payment-modal {
  display: flex;
  justify-content: space-between;
  width: 100%;



  &__title {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: $black-color;
    margin-right: 5px;

    &-img {
      height: 18px;
      width: 18px;

      @media (max-width: 768px) {
        height: 14px;
        width: 14px;
      }
    }

    @media (max-width: 768px) {
      font-size: 14px;
      line-height: 17px;
    }

  }

  &__body {
    display: flex;
    flex-direction: column;
    padding-top: 34px;
    width: 100%;

    @media (max-width: 768px) {
      padding-top: 25px;
      width: 100%;
    }

    @media (max-width: 495px) {
      padding-top: 19px;
      width: 100%;
    }

    &-line {
      border: 1px solid $white-color-border;
      margin: 20px 0;
    }

    &-item {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: $black-color;
      cursor: pointer;
      text-decoration: none;
    }

    &-item-new {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: $black-color;
      cursor: pointer;
      text-decoration: none;

      @media (max-width: 768px) {
        font-size: 12px;
        line-height: 17px;
      }
    }

    &-item-new:hover {
      color: $orange-color;
    }
  }
}

@media (max-width: 920px) {
  .auto-payment-modal__title {
    font-size: 16px;
  }
  .auto-payment-modal__body {
    padding-top: 30px;
  }
  //.modal-auto-pay {
  //  width: 30%;
  //}

}

@media (max-width: 900px) {
  .auto-payment-modal__title {
    font-size: 16px;
    line-height: 17px;
  }

  .modal-auto-pay {
    height: 120px;
    //height: 100px;
    width: 40%;
  }
}

@media (max-width: 830px) {
  .auto-payment-modal__body {
    padding-top: 25px;
    width: 100%;
  }
}

@media (max-width: 450px) {
  .auto-payment-modal__title {
    font-size: 16px;
    line-height: 17px;
  }

  .modal-auto-pay {
    //height: 120px;
    height: 100px;
    width: 70%;
  }
}
