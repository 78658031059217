.service {
  &_title {
    font-size: 24px;
    margin-bottom: 30px;
  }

  &_content {
    font-weight: 400;
    margin-bottom: 30px;
    overflow: hidden;
  }

  &_fields {
    margin-bottom: 45px;
    &:last-child {
      margin-bottom: 0;
    }
    @media (min-width: 680px) {
      .service_description-field,
      .service_debt-additional-field-items {
        display: none;
      }
    }
  }

  &_fields-item {
    margin-bottom: 30px;
    &:last-child {
      margin-bottom: 0;
    }
    &--mini-margin {
      margin-bottom: 10px;
    }
    *:disabled {
      background: #fff;
    }
    &--date {
      background: url('../../img/calendar-icon.svg') 100% 0/20px 20px
      no-repeat;
    }
    &--checkbox {
      display: flex;
      flex-direction: column;
      input {
        margin-left: 15px;
        margin-top: 10px;
        width: 20px !important;
        height: 20px;
      }
    }
  }

  &_fields-item-title {
    //font-family: $mM;
    font-size: 15px;
    color: #000;
    &--error {
      color: red;
    }
  }

  &_phone-field {
    display: flex;
  }

  &_description-field,
  &_debt-additional-field-items {
    background: #fff7e4;
    border-radius: 5px;
    padding: 15px;
    display: none;
  }

  &_description-field {
    line-height: 17px;
    font-weight: 500;
    &-title {
      margin-bottom: 10px;
    }
  }

  &_debt-additional-field-item {
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
    &-title {
      margin-bottom: 4px;
      font-weight: 700;
    }
    &-body {
      font-weight: 500;
    }
  }

  &_phone-placeholder {
    padding: 5px 0;
    padding-right: 8px;
    border-bottom: 1px solid #f05d33;
    color: #000;
  }

  &_error {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80vw;
    height: 100vh;
    margin: 0 auto;
    background: red;
    font-size: 20px;
    font-weight: bold;
    color: #fff;
  }

  .service_check-btn {
    font: 700 16px 'Montserrat';
    background-color: #f05d33;
    padding: 10px;
    width: 100%;
    color: #fff;
    border: none;
    border-radius: 5px;
  }

  input,
  select {
    border: none;
    border-bottom: 1px solid black;
    outline: none;
    padding: 5px 0;
    padding-right: 30px;
  }

  input:not(input[type='checkbox']) {
    width: 100%;
  }

  select {
    margin-left: -3px;
    &.select-not-value {
      opacity: 0.4;
    }
  }
}
