@import "../../variables/index";

.terms-link {
  color: $orange-color;
  text-decoration: none;
  width: fit-content;
  border-bottom: 1px dashed $orange-color
}

.label-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.label-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.custom-checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.6);
}

/* When the checkbox is checked, add a blue background */
.label-container input:checked ~ .custom-checkmark {
  background-color: #EF5C27;
  border: 1px solid #EF5C27;
}

/* Create the checkmark/indicator (hidden when not checked) */
.custom-checkmark:after {
  content: "";
  position: absolute;
  display: none;

}

/* Show the checkmark when checked */
.label-container input:checked ~ .custom-checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.label-container .custom-checkmark:after {
  left: 7px;
  top: 2px;
  width: 3px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}