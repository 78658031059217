.qr-container {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    p {
        margin: 10px auto;
    }
    a {
        text-decoration: none;
        color: #EF5C27;
    }
}

.failedInvoice, .invoice .card-loading {
    align-items: center;
    justify-content: center;
    display: flex;
    height: 70px;
    width: 450px;
    margin-bottom: 20px;
}

.invoice .card-loading {
    position: static;
    padding-bottom: 40px;
    padding-right: 40px;
}

@media (max-width: 900px) {
    .failedInvoice, .invoice .card-loading {
        width: 100%;
    }
    .loader {
        width: 100%;
    }
}

.loader {
    height: 100px;
}

.qr-modal {
    &__copy-link a {
        text-decoration: none;
        color:#EF5C27;
    }
    &__content {
        margin-bottom: 15px;
    }
}

.invoice-form {
    margin: 0 30px;
    &__field {
        margin-top: 25px;
        label {
            margin-right: 10px;
        }
        input {
            padding-bottom: 0;
            padding-left: 0;
        }
        .react-datepicker-wrapper {
            width: 120px;
            input {
                width: 120px;
                padding-right: 25px;
            }
        }
        img {
            right: 0;
            bottom: 1px;
            pointer-events: none;
        }
    }
}

@media (max-width: 840px) {
    .invoice-form {
        &__field {
            input {
                width: 100%;
            }
        }
    }
}