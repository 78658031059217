#modal-modal-title {
  width: 100%;
  display: flex;
  justify-content: space-between;

  .css-1xxajqc-MuiTypography-root {
    width: 85%;
  }
  .css-lc2yqr-MuiButtonBase-root-MuiIconButton-root {
    width: 15%;
  }
}

@media (max-width: 440px) {
  #modal-modal-title {
    .css-1xxajqc-MuiTypography-root {
      font-size: 18px;
    }
  }
}