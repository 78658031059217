.about {
  display: flex;
  width: 1180px;
  margin: 0 auto;
  flex-grow: 1;
  padding: 0 20px;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 50px;

  &__left {
    width: 43%;
  }

  &__right {
    width: 55%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 25px;

    &-head {
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      color: rgba(0, 0, 0, 1);
    }

    &-text {
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
    }
  }
}

@media (max-width: 900px) {
  .about {
    padding: 0 10px;
  }
  .about__left {
    display: none;
  }
  .about__right {
    width: 100%;
  }
}

@media (max-width: 550px) {
  .about__right-head {
    font-size: 16px;
  }
  .about__right-text {
    font-size: 10px;
  }
}