@import "../../../variables/index";

.success {
  max-width: $width;
  margin: 0 auto;
  display: flex;

  padding-bottom: 80px;

  &-body {
    min-width: 753px;
    padding-right: 20px;
    display: flex;
    flex-direction: column;

    &__content {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}
