@import "../../../variables/index";

.top-up-balance {
  max-width: $width;
  margin: 0 auto;
  flex-grow: 1;
  display: flex;
  width: 100%;
  padding: 0 10px;
  flex-direction: column;

  &__field-box {
    display: flex;
    flex-direction: column;
    margin: 30px 0 5px 0;
  }

  &__field-title {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: $gray-color;
  }

  &__field {
    margin-top: 10px;
  }

  &__cards-box {
    margin-top: 32px;
  }

  &__wrap {
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;
  }

  &__left-wrap {
    width: 66%;
    p {
      margin-top: 5px;
    }
    .valid-input {
      color:#000000B3;
    } 
    .error-input {
      color:#D32F2F;
    }

    &-btn {
      margin-top: 30px;
      width: 100%;
      display: flex;
      gap: 15px;
      button {
        flex: 1;
      }
    }

    &-banner {
      width: 100%;
      background: $banner-background;
      clear: both;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      margin: 15px 0 30px 0;
      border-radius: 15px;
      padding: 10px 15px;

      &-textFor {
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        margin-bottom: 5px;
      }

      &-textColor {
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        margin-left: 5px;
        color: $rgba-orange-color-o;
        cursor: pointer;
        text-decoration: none;
      }
    }

    &-wallet {
      display: flex;
      flex-direction: column;
      
      &-head {
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: $gray-color;
      }

      &-box {
          display: flex;

        &-itemN {
          width: 176px;
          border-radius: 15px;
          margin-top: 15px;
          border: 1px solid $rgba-white-color;
          cursor: pointer;

          &-walN {
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            padding-top: 15px;
            padding-left: 20px;
            color: $black-color;
          }

          &-priceN {
            font-weight: 500;
            font-size: 22px;
            line-height: 100%;
            padding-bottom: 15px;
            padding-left: 20px;
            color: $black-color;
          }
        }

        &-item {
          width: 200px;
          border-radius: 15px;
          margin-top: 15px;
          border: 1px solid $rgba-orange-color-o;
          margin-right: 15px;
          cursor: pointer;

        &-wal {
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
          color: $orange-color;
          padding-top: 15px;
          padding-left: 20px;
        }

          &-price {
            font-weight: 500;
            font-size: 22px;
            line-height: 100%;
            color: $orange-color;
            padding-bottom: 15px;
            padding-left: 20px;
          }

        }
      }
    }




    &-summa {
      display: flex;
      flex-direction: column;
      margin: 30px 0;

      &-text {
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: $gray-color;
        margin-bottom: 5px;
      }

      &-textP {
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: $black-color;
      }
    }
  }

  &__right-wrap {
    width: 33%;
  }
}

@media (max-width: 900px) {
  .top-up-balance__wrap {
    display: flex;
    flex-direction: column;
  }
  .top-up-balance__left-wrap {
    width: 100% !important;
  }
  .top-up-balance__left-wrap-banner {
    width: 100% !important;
    display: flex;
    flex-direction: column;
  }
  .top-up-balance__right-wrap {
    width: 100% !important;
  }
  .top-up-balance__left-wrap-btn {
    flex-direction: column;
    .custom-button {
      width: 100% !important;
    }
  }
  .top-up-balance__left-wrap-banner-textFor {
    text-align: center;
  }
  .top-up-balance__left-wrap-banner-textColor {
    text-align: center;
  }
}

// @media (max-width: 540px) {
//   .top-up-balance__left-wrap-wallet-box {
//     flex-direction: column;
//     gap: 0;
//   }
// }

@media (max-width: 420px) {
  .top-up-balance__left-wrap-btn {
    .custom-button {
      min-width: 300px !important;
    }
  }
  .top-up-balance__field {
    input {
      width: 300px !important;
    }
  }
}