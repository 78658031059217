.other-payment-panel {
  display: flex;
  flex-direction: column;

  &__title {
    display: flex;
    font-size: 18px;
    font-family: 'Montserrat';
    color: rgba(0, 0, 0, 1);
    padding-top: 10px;
  }

  &__category {
    margin-top: 24px;

    &-item {
      display: flex;
      padding-bottom: 10px;
      text-decoration: none;
    }

    &-icon {
      display: flex;
      width: 46px;
      height: 46px;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
    }
    &-text {
      display: flex;
      align-items: center;
      padding-left: 10px;
      font-size: 14px;
      color: rgba(71, 71, 71, 1);
    }

    &-image{
      width: 30px;
      height: 30px;
      &-img {
        width: 30px;
        height: 30px;
      }
    }

    &-more-info {
      cursor: pointer;
      color: rgba(239, 92, 39, 1);
      display: inline-block;
      border-bottom: 1px dotted rgba(239, 92, 39, 1);
    }
  }
}

.slider-container-item__right {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 615px) {
  .other-payment-panel__category-icon {
    width: 36px;
    height: 36px;
  }
  .other-payment-panel__category-image {
    height: auto;
    width: auto;
  }
  .other-payment-panel__category-image-img {
    height: 20px;
    width: 20px;
  }
}