@import '../../variables/index.scss';

.bottom-bar {
  display: none;

  &__content {
    display: flex;
    position: relative;
    padding: 6px 20px;
    margin: 0 auto;
    justify-content: space-between;
  }

  &__item {
    display: flex;
    align-items: center;
    position: relative;

    //padding: 0 40px;
  }

  &__item--home {
    min-width: 73px;
  }

  &__tab {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
  }

  &__tab-image {
    width: 30px;
    height: 30px;
  }

  &__icon {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &__image {
    position: absolute;
    top: -36px;
    left: 0;
    right: 0;
    width: 73px;
    height: 73px;
  }

  &__tab-image {
    width: 30px;
    height: 30px;
    margin-bottom: 7px;
    position: relative;
  }

  &__tab-title {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: $gray-color;
  }

  &__count {
    width: 9px;
    height: 9px;
    position: absolute;
    background: #EF5C27;
    border-radius: 50%;
    top: 0;
    right: -3px;
  }
}

@media only screen and (max-width: 900px) {
  .bottom-bar {
    display: block;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $white-color;
    z-index: 20;
    box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.1);
  }
}

@media only screen and (max-width: 550px) {
 .bottom-bar__image {
   width: 66px;
   height: 66px;
 }

  .bottom-bar__tab-image {
    width: 25px;
    height: 25px;
  }
  .bottom-bar__tab-title {
    font-size: 14px;
    line-height: 17px;
  }
}

@media only screen and (max-width: 490px) {

  .bottom-bar__tab-image {
    width: 30px;
    height: 30px;
  }
  .bottom-bar__tab-title {
    display: none;
  }
}

@media only screen and (max-width: 400px) {

  .bottom-bar__item--home {
    min-width: 50px;
  }
  .bottom-bar__image {
    width: 55px;
    height: 55px;
  }

  .bottom-bar__tab-image {
    width: 20px;
    height: 20px;
  }
}