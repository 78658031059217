@import "../../variables/index";

.serviced {
  width: $width;
  margin: 0 auto;
  padding-bottom: 50px;
  flex-grow: 1;
  margin-top: 20px;

  &__sum {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: $gray-color;
  }

  &__wrap {
    display: flex;
    justify-content: space-between;
  }

  &__left {
    width: 753px;
  }

  &__right {
    width: 367px;
    flex-direction: column;

    &-install {
      margin-top: 20px;
    }
  }

  &__body {
    margin-top: 30px;
    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: $gray-color;
      margin-bottom: 15px;
    }

    &-wrap {
      display: flex;

      &-inputLabel {
        input {
          text-align: center;
        }
      }

      &-inputLabel-icon {
        position: absolute;
        margin-top: 13px;
        margin-left: 10px;

        input {
          padding-left: 40px;
          height: 52px;
          border-radius: 15px;
          text-align: center;
          border: 1px solid $red-color;
        }
      }

      &-inputLabel-inp {
        border: 7px solid $orange-color;
        height: 44px;
        border-radius: 15px;
        padding-left: 45px;
        padding-top: 9px;
      }

      &-input {
        width: 50%;
      }

      &-text {
        width: 50%;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #474747;
        background: $banner-background;
        padding: 10px 15px;
        border-radius: 15px;
      }
    }

    &-input-wrap{
      display: flex;
      justify-content: space-between;
    }

    &-input {
      margin-top: 55px;

      p {
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: $gray-color;
      }

      &-text {
        width: 50%;
        height: 71px;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: $black-color;
        background: $banner-background;
        padding: 10px 15px;
        border-radius: 15px;
        margin-top: 37px;
      }
    }

    &-comment {
      margin-top: 56px;
      margin-bottom: 36px;
    }
  }

  &__footer-btn {
    margin-top: 30px;
  }
}

.service__number {
  .dNqPxa {
    color: $red-color;
    padding-top: 5px;
  }
}
