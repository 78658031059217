.recurring-payments {
  display: flex;
  flex-direction: column;
  width: 100%;

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    margin-top: 15px;

    @media (max-width: 768px) {
      .header-text {
        display: none;
      }
    }
  }

  &__items {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  &__btn {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 35px 0;
    border: 1px solid rgba(239, 92, 39, 0.25);
    border-radius: 15px;
  }

  &__btn-small {
    border: none;
    background: #EF5C27;
    border-radius: 15px;
    padding: 10px 25px;
    color: #FFFFFF;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    max-width: 237px;
    text-align: center;
    cursor: pointer;
    height: 50px;
  }

  &__btn-icon {
    margin-bottom: 10px;
  }

  &__btn-text {
    color: #EF5C27;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
  }
}

.auto-payment-skeleton {
  display: flex;
}

.media900-auto-payment-skeleton {
  display: none;
}

.media530-auto-payment-skeleton {
  display: none;
}
.media455-auto-payment-skeleton {
  display: none;
}
.media340-auto-payment-skeleton {
  display: none;
}

@media(max-width: 900px) {
  .auto-payment-skeleton {
    display: none;
  }

  .media900-auto-payment-skeleton {
    display: flex;
  }
  .media530-auto-payment-skeleton {
    display: none;
  }
  .media455-auto-payment-skeleton {
    display: none;
  }
  .media340-auto-payment-skeleton {
    display: none;
  }
}

@media (max-width: 768px) {
  .recurring-payments {
    display: flex;
    flex-direction: column;
    width: 100%;

    &__btn-small {
      border-radius: 15px;
      padding: 10px 25px;
      background: white;
      color: #EF5C27;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      text-align: center;
      cursor: pointer;
      min-width: 100%;
      height: 50px;
      border: 1px solid #EF5C27;
    }
  }
}

@media (max-width: 535px) {
  .auto-payment-skeleton {
    display: none;
  }

  .media900-auto-payment-skeleton {
    display: none;
  }
  .media530-auto-payment-skeleton {
    display: flex;
  }
  .media455-auto-payment-skeleton {
    display: none;
  }
  .media340-auto-payment-skeleton {
    display: none;
  }
}

@media (max-width: 455px) {
  .auto-payment-skeleton {
    display: none;
  }

  .media900-auto-payment-skeleton {
    display: none;
  }
  .media530-auto-payment-skeleton {
    display: none;
  }
  .media455-auto-payment-skeleton {
    display: flex;
  }
  .media340-auto-payment-skeleton {
    display: none;
  }
}

@media (max-width: 450px) {
  .recurring-payments {
    display: flex;
    flex-direction: column;
    width: 100%;

    //&__btn-small {
    //  min-width: 95%;
    //  margin: 0 auto;
    //}
    //&__items {
    //  display: flex;
    //  flex-direction: column;
    //  gap: 20px;
    //  width: 90%;
    //  margin: 0 auto;
    //}

  }
}

@media (max-width: 340px) {
  .recurring-payments {
    display: flex;
    flex-direction: column;
    width: 100%;

    &__btn-small {
      min-width: 90%;
      margin: 0 auto;
    }
  }

  .auto-payment-skeleton {
    display: none;
  }

  .media900-auto-payment-skeleton {
    display: none;
  }
  .media530-auto-payment-skeleton {
    display: none;
  }
  .media455-auto-payment-skeleton {
    display: none;
  }
  .media340-auto-payment-skeleton {
    display: flex;
  }
}