@import "../../../variables/index";

.add-checkbox {
  display: flex;

  input[type="checkbox"] {
    position: relative;
    appearance: none;
    -webkit-appearance: none;
    height: 30px;
    width: 30px;
    background-color: white;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    border: 2px solid $orange-color;
  }

  input[type="checkbox"]:after {
    position: absolute;
    font-weight: 900;
    font-size: 20px;
    color: $white-color;
    display: none;
    padding-top: 18px;
    //margin-top: 5px;
    content: "";
    width: 5px;
    border: solid white;
    border-width: 0 4px 4px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);

  }

  input[type="checkbox"]:hover {
    background-color: $white-color;
  }

  input[type="checkbox"]:checked {
    background-color: $orange-color;
  }
  input[type="checkbox"]:checked:after {
    display: block;
    top: -1px;
    left: 10px;
  }

  &__title {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: $black-color;
    align-self: center;
    margin-left: 11px;
    cursor: pointer;
    max-width: 80%;
  }
}
