.invoice-container {
    display: flex;
    justify-content: center;
    margin: 20px 20px 80px;
    .top-up-balance__field-box {
        width: 80%;
    }
    &__title {
        font-size: 24px;
        text-align: center;
        margin-top: 15px;
    }
    &__check {
        width: 367px;
        display: flex;
        flex-direction: column;
        margin-top: 30px;
        padding: 15px;
        gap: 10px;
        border-radius: 25px;
        border: 2px #EF5C27 solid;
        &__row {
            display: flex;
            justify-content: space-between;
            &__right {
                max-width: 50%;
                word-break: break-word;
            }
        }
    }
    &__card {
        margin-top: 30px;
    }
    button {
        margin-top: 30px;
    }
}

@media screen and (max-width: 670px) {
    .invoice-container__check {
        width: 100%;
    }
}

@media screen and (max-width: 430px) {
    .invoice-container__check {
        width: 100%;
    }
}