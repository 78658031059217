@import "../../../variables/index";

.notifications {
  max-width: $width;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  width: 100%;
  //padding: 0 10px 80px 10px;

  &-body {
    min-width: 66%;
    padding-right: 20px;

    &__title {
      padding: 15px 0 30px 0;
      font-size: 26px;
      font-weight: 600;
    }
  }

  &-right {
    width: 33%;
    //padding-top: 10px;
  }
}

.checkmark {
  position: absolute;
  top: -8px;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: $white-color;
  border: 2px solid $orange-color;
  border-radius: 8px;
}

.container:hover input ~ .checkmark {
  background-color: $white-color;
}

.container input:checked ~ .checkmark {
  background-color: $orange-color;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.container input:checked ~ .checkmark:after {
  display: block;
}

.container .checkmark:after {
  left: 7px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid $white-color;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.notifications-item:last-child {
  border-bottom: 1px solid #474747;
}

.sms-notifications {
  display: flex;
  align-items: center;

  &__title {
    font-weight: 500;
    font-size: 14px;
    color: $gray-color;
    margin-left: 10px;
  }
}

@media (max-width: 900px) {
  .notifications {
    border-radius: 25px 25px 0 0;
    margin-top: -22px;
    background: white;
    height: 100vh;
  }
  .notifications-right {
    display: none;
  }
  .notifications-body {
    width: 100%;
    padding: 10px 0;
  }

}

@media (max-width: 500px) {
  .notifications-body__title {
    font-size: 20px;
  }
  .add-checkbox__title {
    font-size: 14px;
  }
  .sms-notifications {
    img {
      width: 25px;
      height: 25px;
    }
  }
  .border {
    margin: 6px 0;
  }
  .message-info__header-back-text {
    font-size: 14px;
  }
}

