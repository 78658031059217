@import "../../../variables/index";

.debt {
  &__body {
    &-right {
      border: 1px solid $white-color;
      //width: 370px;
      width: 100%;
      box-shadow: 0px 2px 8px $shadow-color;
      border-radius: 10px;


      &-search {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 20px 20px 10px 20px;

        &-head {
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 22px;
          color: $black-color;
        }

        &-wrap {
          display: flex;
          padding: 20px 0;
        }
      }

      &-container {
        overflow: auto;
        height: 582px;
        margin-bottom: 5px;
      }
      &-container::-webkit-scrollbar {
        width: 12px;
      }
      &-container::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 20px #cdcdcd;
      }

      &-main {
        display: flex;
        cursor: pointer;
        margin-bottom: 5px;
        justify-content: space-between;
        width: 100%;
      }

      &-wrap {
        padding: 0 20px;

        &-title {
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 15px;
          color: $gray-color;
        }

        &-text {
          display: flex;
          padding: 10px 0;
          flex-direction: column;
          cursor: pointer;
          text-decoration: none;

          &-img {
            width: 46px;
            height: 46px;
            border-radius: 50%;
            border: 1px solid $white-color-border;

            img {
              width: 100%;
              height: 100%;
              object-fit: contain;

            }
          }
          &-wrap {
            display: flex;
            flex-direction: column;
            width: 30%;
          }
          &-title {
            align-self: center;
            padding-left: 13px;
            width: 40%;

            &-price {
              //align-self: center;
              display: flex;
              justify-content: end;
              //padding-left: 15px;

              &-item {
                margin-right: 10px;
                color: #EF5C27;
              }
            }
            &-money {
              display: flex;
              justify-content: end;
            }

            &-item {
              font-style: normal;
              font-weight: 600;
              font-size: 14px;
              line-height: 17px;
              color: $black-color;
              margin-bottom: 7px;
            }

            &-com {
              font-style: normal;
              font-weight: 500;
              font-size: 12px;
              line-height: 15px;
              color: $gray-color;
            }
          }
        }
      }
    }
    &-not-found {
      text-align: center;
      font-size: 20px;
      color: #888888;
    }
  }
}
