@import "../../../variables/index";

.tabs-main {
  display: flex;

  &__last {
    display: flex;
    border: 1px solid $red-color;
  }
}
.tabs-title {
  display: flex;
  padding: 13px 0 13px 20px;
  cursor: pointer;

  &:hover{
    background-color: $background-orange-color-o;
    border-left: 5px solid $red-color;
  }
}

.tabs-title {
  display: flex;
  padding: 13px 0 13px 20px;
  cursor: pointer;

  &__information {
    display: flex;
    justify-content: space-evenly;
    padding: 8px 0;
    margin: 0 auto;

    &:hover{
      background-color:$background-orange-color-o;
      border-left: 5px solid $red-color;
    }

    &-main {
      display: flex;
      width: 100%;
      cursor: pointer;

      &-div {
        display: flex;
        width: 20%;
        padding-left: 20px;
      }
    }

    &-image-wrap {
      width: 46px;
      height: 46px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &-image {
      width: 27px;
      height: 25px;
    }

    &-title {
      display: flex;
      align-self: center;
      flex-direction: column;
      width: 60%;
      padding-left: 10px;

      &-street {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: $black-color;
      }
      &-service {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        color: $gray-color;
      }
    }

    &-count-wrap {
      display: flex;
      align-self: center;
      width: 20%;
      padding-left: 10px;
    }

    &-price {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      padding-right: 5px;
      color: $black-color;
    }

    &-currency {
      color: $gray-color;
    }
  }
}