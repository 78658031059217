.select-payment-method {
  width: 100%;

  &__card-box {
    width: 60%;
  }

  &__wrap {
    display: flex;
    justify-content: space-between;
  }

  &-head {
    display: flex;
    padding-bottom: 40px;
    text-align: start;
  }

  &-wrapBtn {
    margin-top: 40px;
  }
}

@media only screen and (max-width: 1000px) {
  .select-payment-method {
    &__wrap {
      flex-direction: column-reverse;
      .right-basket {
        max-width: 420px;
        width: 97%;
        margin-bottom: 40px;
      }
    }
    &__card-box, .swiper-container  {
      width: 97%;
    }
  }
}