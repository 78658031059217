@import "../../../variables/index";

.phone-input {
    input{
        border-bottom: rgba(0, 0, 0, 0.6) 1px solid;
        padding-bottom: 0;

    }
}

.errorInvalid {
    color: #d32f2f;
    font-size: 12px;
    line-height: 1.66;
    letter-spacing: 0.03333em;
}