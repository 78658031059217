@import "../../../variables/index";

.debt-replenishment {
  width: $width;
  margin: 0 auto;

  &__container {
    display: flex;
    justify-content: space-between;
    padding-bottom: 50px;

    &-left {
      width: 753px;
    }
    &-right {
      width: 367px;
    }
  }

  &__telephone {
    padding-top: 30px;

    &-text {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: $gray-color;

      &-number {
        width: 368px;
      }
    }

    &-wrap {
      font-style: normal;
      font-weight: 500;
      font-size: 40px;
      line-height: 49px;

      input {
        width: 367px;
        border-top-style: hidden;
        border-right-style: hidden;
        border-left-style: hidden;
        border-bottom-style: solid;
        border-bottom-color: $rgba-orange-color-o;
        background-color: $white-color;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        padding-bottom: 10px;
      }

      .no-outline:focus {
        outline: none;
      }
    }
  }

  &__input-div {
    width: 55%;
  }

  &__sum-main {
    display: flex;
    padding: 20px 0 12px;
    width: 368px;
    justify-content: space-between;
    border-bottom: 2px solid $orange-color;

    input {
      border-top-style: hidden;
      border-right-style: hidden;
      border-left-style: hidden;
      border-bottom-style: hidden;
      border-bottom-color: $rgba-orange-color-o;
      background-color: $white-color;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      padding-top: 16px;
      width: 100%;
      outline: none;
    }

    &-button {
      height: 38px;
      width: 38px;
      border-radius: 15px;
      outline: none;
      border: none;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color:$gray-color;
      cursor: pointer;
    }
  }

  &__button-div {
    width: 45%;
    display: flex;
    justify-content: space-between;
  }

  &__min-max {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: $gray-color;
    padding-top: 5px;
  }

  &__data-main {
    display: flex;
    padding: 36px 0 70px;

    &-image {
      height: 26px;
      width: 26px;
      margin-right: 16px;
      cursor: pointer;
    }

    &-text {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: $black-color;
      align-self: center;
    }

    &-date {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: $orange-color;
      align-self: center;
      padding-left: 5px;
    }
  }

  &__footer {
    padding-top: 30px;
  }
}
