@import "../../variables/index";

.payment-add {
  display: flex;
  flex-direction: column;
  //max-width: 750px;
  width: 66%;
  //margin: 0 auto;
  position: relative;

  &__loader {
    position: absolute;
    left: 50%;
    margin-left: -50px;
    top: 50%;
    margin-top: -50px;
  }

  &__service-title {
    font-weight: 600;
    font-size: 26px;
    line-height: 32px;
    color: $black-color;
    margin-bottom: 30px;
    text-align: center;
  }

  &__content {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
    justify-content: space-between;
    &-left {
      width: 70%;
    }
  }

  input {
    padding-bottom: 0;
    padding-left: 0;
    margin-left: 0;
    width: 100%;
  }

  select {
    border: none;
    border-bottom: 1px solid $colorDesign;
    outline: none;
    padding: 5px 30px 5px 0;
    margin-left: -3px;
    width: 100%;
  }

  &__btns {
    display: flex;
    //width: 49%;
  }

  &__guest-wrap-btn {
    display: flex;
    justify-content: space-between;

  }

  &__footer-btn {
    display: flex;
    justify-content: space-between;
  }

  &__btn {
    width: 100%;
    text-align: center;
    background: $orange-color;
    border: none;
    outline: none;
    padding: 14px;
    border-radius: 15px;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: $white-color;
    cursor: pointer;
  }
  &__btn-guest {
    width: 100%;
    text-align: center;
    background: #ffffff;
    border: 1px solid #EF5C27;
    outline: none;
    padding: 14px;
    border-radius: 15px;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #EF5C27;
    cursor: pointer;
  }

  &__btn--disabled-guest {
    color: #EF5C27 !important;
    pointer-events: none;
    background-color: #ffffff !important;
    opacity: 0.3;
    border: 1px solid #EF5C27 !important;
  }

  &__btn-basket {
    width: 100%;
    text-align: center;
    background: $white-color;
    border: 1px solid $orange-color;
    outline: none;
    padding: 14px;
    border-radius: 15px;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: $orange-color;
    cursor: pointer;
  }

  &__card-box {
    display: flex;
  }

  &__btn--disabled {
    pointer-events: none;
    opacity: 0.7;
  }
}
.payment-wrap {
  display: flex;
  justify-content: space-between;
}
.payment-add-alert {
  display: flex;
  flex-direction: column;
  //margin: 0 auto;
  padding: 20px 10px;
  background: #FFEAE9;
  border-radius: 15px;
  margin-bottom: 20px;

  &-info {
    text-align: left;
    font-size: 18px;
    padding-bottom: 10px;
  }
}

@media (max-width: 850px) {
  .payment-add {
    width: 80% !important;
  }
  .payment-add__service-title, .category__title {
    font-size: 20px;
    line-height: 24px;
    word-wrap: break-word;
  }
  .service_fields-item-title {
    font-size: 14px;
    line-height: 17px;
  }
  .select-not-value {
    font-size: 10px;
  }
  .service_fields-item {
    input::placeholder {
      font-size: 16px;
    }
  }
  .payment-add__btn--disabled {
    font-size: 16px;
  }
  .payment-add__btn {
    font-size: 16px;
  }
}

@media (max-width: 700px) {
  .payment-add {
    display: flex;
    width: 100%;
  }
}

@media screen and (max-width: 600px) {
  .payment-add__content {
    width: 100%;
  }
}

@media (max-width: 500px) {
  .payment-add__service-title, .category__title {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 20px;
  }
  .service_fields-item-title {
    font-size: 14px;
    line-height: 17px;
  }
  .select-not-value {
    font-size: 10px;
  }
  .service_fields-item {
    input::placeholder {
      font-size: 16px;
    }
  }
  .payment-add__btn--disabled {
    font-size: 14px;
  }
  .payment-add__btn {
    font-size: 14px;
  }
}

.payment-recaptch{
  margin-bottom: 25px;
  width: 304px;
  height: 78px;
}
