@import "../../../variables/index";

.install-app {
  display: flex;
  &__app-modal {
    width: 100%;
    &-wrap {
      width: 100%;
      border-radius: 15px;
      background: $violet-back-color;
      box-shadow: 0px 2px 8px $shadow-color;
      display: flex;
      flex-direction: column;
      padding: 0 10px;

      @media only screen and (max-width: 890px) {
        width: 85%;
      }

      &-text {
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        color: $white-color;
        padding: 25px 10px 0 10px;

        @media (max-width: 1140px) {
          font-size: 14px;
        }

        @media (max-width: 950px) {
          font-size: 15px;
        }
      }

      &-texB {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: $white-color;
        margin: 0 auto;
        padding: 10px 10px 0 10px;
      }

      #lang_az {
        padding: 20px 10px 0px 10px;
      }

      &-foot {
        display: flex;
        width: 100%;
        padding: 20px 10px 15px 10px;
      }
    }
  }

}

.app-wrap {
  display: flex;
  margin-right: 10px;
}
.app-wrap-app {
  display: flex;
}

.sn_logo2 {
  width: 170px;
}

.sn_logo2-ru {
  width: 150px;
}

.sn_logo2-en {
  height: 65px;
}

.app-logo-ru {
  width: 140px;
}

@media (max-width: 1075px) {
  .app-logo-az {
    width: 160px;
  }
  .sn_logo2 {
    width: 155px;
  }
}

@media (max-width: 1070px) {
  #lang_az {
    flex-direction: column;
    .google_az {
      width: 155px;
    }
  }
}

@media (max-width: 955px) {
  .app-logo-az {
    width: 140px;
  }
  .sn_logo2 {
    width: 135px;
  }
}

@media (max-width: 950px) {
  .install-app__app-modal-wrap-foot {
    display: flex;
    width: 100%;
    padding: 20px 0 15px 0;
    #lang_az {
      padding: 20px 0px 0px 10px;
    }
  }
}

@media (max-width: 925px) {
  .sn_logo2-en {
    width: 155px;
  }
}

@media (max-width: 970px) {
  .install-app__app-modal-wrap-foot {
    justify-content: space-around;
  }
}

@media (max-width: 945px) {
  .sn_logo2-ru {
    width: 140px;
  }

  .app-logo-ru {
    width: 130px;
  }
}

@media (max-width: 900px) {
  .install-app__app-modal {
    display: none;
  }
}
