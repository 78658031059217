@import "../../../../variables/index";

.password {
  max-width: $width;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  width: 100%;
  //padding: 0 10px 80px 10px;
  flex-grow: 1;

  &-body {
    min-width: 66%;
    //padding-right: 20px;

    &__title {
      padding-top: 15px;
      //padding-bottom: 30px;
      font-size: 26px;
      font-weight: 600;
    }
  }

  &-right {
    width: 33%;
    //margin-top: 10px;
  }

  &__form {
    width: 45%;
    display: flex;
    flex-direction: column;
    //height: 150px;
    justify-content: space-between;
  }

  &__form-input {
    border: none;
    border-bottom: 1px solid $orange-color;
    outline: none;
  }

  &__buttons {
    //margin-top: 30px;
    display: flex;
    width: 50%;
    align-items: center;
    justify-content: space-between;
  }
}

.edit-password-footer {
  display: flex;
  width: 100%;

  .password__buttons {
    display: flex;
  }
  .forgot-password-wrap {
    display: flex;
    width: 50%;

    span {
      display: flex;
      align-self: center;
      margin: 0 auto;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: #EF5C27;
      border-bottom: 1px dotted #EF5C27;
    }
  }
}

@media (max-width: 900px) {
  .password-right {
    display: none;
  }
  .password-body {
    width: 100%;
    padding: 10px 0 0 0;
  }
  .password {
    border-radius: 25px 25px 0 0;
    margin-top: -22px;
    background: white;
    height: 100vh;
  }
  .password__form {
    width: 100%;
  }
}

@media (max-width: 550px) {
  .edit-password-footer {
    display: flex;
    flex-direction: column;
  }
  .forgot-password-wrap {
    margin-top: 30px;
    width: 100% !important;
  }
  .password__buttons {
    width: 100% !important;
  }
}
