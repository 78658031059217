@import "../../../variables/index";

.recurring-transaction-wrap {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.recurring-right-wrap {
  width: 33%;
  margin-left: 20px;
}
.recurring-transaction {
  width: 66%;

  &__content {
    display: flex;
    flex-direction: column;
  }

  &__title {
    margin-bottom: 30px;
    font-weight: 600;
    font-size: 26px;
    line-height: 32px;
    color: $black-color;
  }

  &__form {
    display: flex;
    flex-direction: column;
  }

  &__btn {
    width: 100%;
    text-align: center;
    background: $orange-color;
    border: none;
    outline: none;
    padding: 14px;
    border-radius: 15px;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: $white-color;
    cursor: pointer;

    &:disabled {
      pointer-events: none !important;
      background: #ece8e8 !important;
      color: #a3a3a3 !important;
    }
  }

  &__btn-cancel {
    width: 100%;
    text-align: center;
    background: $white-color;
    border: 1px solid $orange-color;
    outline: none;
    padding: 14px;
    border-radius: 15px;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: $orange-color;
    margin-top: 30px;
    cursor: pointer;
  }

  input {
    padding-bottom: 0;
    padding-left: 0;
    margin-left: 0;
    width: 100%;
  }

  select {
    border: none;
    border-bottom: 1px solid $colorDesign;
    outline: none;
    padding: 5px 30px 5px 0;
    margin-left: -3px;
  }

  &__card-box {
    margin-bottom: 30px;
  }

}

.service {
  &_fields {
    margin-bottom: 45px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  &_fields-item {
    margin-bottom: 30px;
    &:last-child {
      margin-bottom: 0;
    }
    &--mini-margin {
      margin-bottom: 10px;
    }
    &-error {
      text-align: left !important;
    }
  }

  &_phone-field {
    display: flex;
  }


  &_description-field,
  &_debt-additional-field-items {
    background: #fff7e4;
    border-radius: 5px;
    padding: 15px;
  }

  &_description-field {
    line-height: 17px;
    font-weight: 500;
    border-radius: 15px;
    &-title {
      margin-bottom: 10px;
      //font-family: Montserrat, sans-serif;
    }
  }

  &_debt-additional-field-item {
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
    &-title {
      margin-bottom: 4px;
      font-weight: 700;
    }
    &-body {
      font-weight: 500;
    }
  }
}

@media (max-width: 900px) {
  .recurring-right-wrap {
    width: unset;
  }
}
