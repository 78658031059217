
@import "../../../variables/index";


.auto-payments {
  display: flex;
  flex-direction: column;
  width: $width;
  margin: 0 auto 100px auto;
  flex-grow: 1;
  margin-bottom: 50px;

  &__wrapper {
    display: flex;
  }

  &__content {
    display: flex;
    flex-direction: column;
    min-width: 753px;
    margin-top: 14px;
    margin-right: 20px;
  }

  &__content-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
  }

  &__content-body {
    display: flex;
    flex-direction: column;
  }

  &__app-container {
    min-width: 367px;
  }

  &__btn {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 35px 0;
    min-width: 753px;
    border: 1px solid rgba(239, 92, 39, 0.25);
    border-radius: 15px;
  }

  &__btn-small {
    border: none;
    background: #EF5C27;
    border-radius: 15px;
    padding: 10px 25px;
    color: #FFFFFF;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    max-width: 237px;
    text-align: center;
    cursor: pointer;
    height: 50px;
  }

  &__btn-icon {
    margin-bottom: 10px;
  }

  &__btn-text {
    color: #EF5C27;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
  }

}




