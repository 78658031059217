@import "../../../variables/index";

.sima {
  width: $width;
  margin: 0 auto;
  display: flex;
  flex-grow: 1;
  padding-bottom: 120px;
  flex-direction: column;

  &__body {
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
  }

  &__content {
    width: 100%;
  }

  &__title {
    font-weight: 600;
    font-size: 26px;
    line-height: 32px;
    color: $black-color;
    margin-bottom: 25px;
  }

  &__subtitle {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: $black-color;
    margin-bottom: 33px;
  }

  &__qr {
    padding-left: 39px;
    //text-align: center;
    margin-bottom: 15px;
  }

  &__docs{
    margin-bottom: 65px;
  }

  &__docs-box{
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }

  &__docs-icon {
    margin-right: 15px;
    flex-shrink: 0;
  }

  &__img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &__hint {
    font-weight: 500;
    line-height: 17px;
    color: $black-color;
    margin-bottom: 30px;
  }

  &__btn {
    display: none;
    padding: 14px;
    //width: 330px;
    height: 50px;
    background: $orange-color;
    border-radius: 15px;
    color: $white-color;
    text-align: center;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    cursor: pointer;
    margin-bottom: 30px;
  }

  &__qr-small {
    display: none;
    justify-content: center;
  }
}

@media only screen and (max-width: 900px) {
  .sima__left {
    display: none;
  }
}

@media only screen and (max-width: 550px) {
  .sima__left, .sima__qr {
    display: none;
  }

  .sima__btn{
    display: block;
  }

  .sima__qr-small {
    display: flex;
  }

  .sima__title {
    font-size: 18px;
  }

  .sima__subtitle {
    font-size: 14px;
  }

  .sima__docs-text {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
  }

}