.auth-page {
  max-width: 600px;
  width: 100%;
  margin: 0 auto;
}

@media (max-width: 900px) {
  .auth-page {
    max-width: 100%;
    border-radius: 25px 25px 0 0;
    margin-top: -22px;
    background: white;
  }
}