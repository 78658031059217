@import "../../../../variables/index";

.cvv {
  max-width: $width;
  margin: 0 auto;
  display: flex;

  padding-bottom: 80px;
  flex-grow: 1;

  &-body {
    min-width: 753px;
    padding-right: 20px;

    &__title {
      padding-bottom: 30px;
      font-size: 26px;
      font-weight: 600;
    }
  }

  &__item-cvv {
    width: 100%;
    display: flex;
    flex-direction: column;
    //justify-content: space-between;
    //height: 120px;
  }
}
