@import "../../variables/index";

.footer {
  background: $violet-footer-color;
  flex-shrink: 0;
  padding: 0 20px;
  position: relative;

  &__body {
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    max-width: $width;
    padding-bottom: 35px;
    justify-content: space-between;

    &__item4 {
      width: 270px;
      padding: 0 20px 0 0 ;
    }

    &__item1, &__item2, &__item3  {
      width: 270px;

      &__title {
        color: $white-color;
        margin: 40px 0 30px 0;
        font-size: 16px;
        font-weight: 700;
      }

      &__phone {
        font-weight: 700 !important;
        font-size: 20px !important;
      }

      &__mail {
        font-weight: 700 !important;

      }

      ul {
        list-style: none;
        padding: 0;

        li {
          padding-bottom: 20px;
          font-size: 14px;
          font-weight: 500;
          color: $white-color;

          a {
            text-decoration: none;
            font-size: 14px;
            font-weight: 500;
            color: $white-color;
            .appstore_en, .appstore_az {
              width: 135px;
            }
          }
        }
      }
    }

    &__item2 {
      &__messenger {
        display: flex;
        span {
          align-self: center;
        }
        a {
          align-items: center;
          padding-left: 10px
        }
      }
    }

    &__item4 {
      padding-right: 0;
      &__logo {
        margin-bottom: 20px;
      }

    }
  }
}

.googleApple-icon {
  border: 1px solid $border-color-gray;
  border-radius: 7px;
  box-sizing: content-box;
}

.footer-partners {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__head {
    margin-top: 70px;
  }

  li {
    padding-right: 20px;
  }
  li:last-child {
    padding-right: 0;
  }
}

.social-icon {
  padding-right: 15px;
}

@media screen and (max-width: 1140px) {
  .footer {
    &__body {
      &__item4 {
        max-width: 500px;
        width: 100%;
        display: flex;

        ul {
          li {
            display: flex;
          }
        }

      }
    }
  }
}

@media (max-width: 900px) {
  .footer {
    padding: 0 10px;
    &__body {
      &__item1, &__item2, &__item3 {
        width: 100%;
        //padding: 0 0 0 20px;
        ul {
          li {
            a {
              .appstore_en {
                width: 120px;
              }
              .appstore_az {
                width: 155px;
              }
            }
          }
        }
      }

      &__item4 {
        display: flex;
        width: 100%;
        margin: 0;
      }
    }
  }
  .footer-partners {
    padding: 0 10px 0 0 !important;
    display: flex;
    justify-content: flex-start;
  }
  .footer-app-wrap {
    display: flex;
    gap: 10px;
  }
  .footer-app-android {
    display: flex;
  }
  .footer-app-apple {
    display: flex;
  }
}