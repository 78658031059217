@import "../../variables/index";

.relax-right-info {
  width: 100%;
  border-radius: 15px;
  padding: 30px 0;
  background-color: $orange-color;

  &__main {
    width: 85%;
    margin: 0 auto;

    &-div {
      padding-bottom: 25px;
    }

    &-title {
      font-style: normal;
      font-weight: 600;
      font-size: 22px;
      line-height: 27px;
      color: $white-color;
    }

    &-text {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: $white-color;
    }
  }
}