@import "../../variables/index";

.transfer-card {
  max-width: $width;
  margin: 25px auto;
  flex-grow: 1;
  width: 100%;
  padding: 0 10px;

  &__app {
    display: flex;
    width: 33%;
    flex-direction: column;

    &-other {
      margin-bottom: 30px;
      background: #ffffff;
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12);
      border-radius: 15px;
      overflow: hidden;
      padding: 24px;
    }
  }

  &__form {
    width: 100%;
  }

  &__body {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  &__content {
    width: 66%;
    background: $white-color;
    box-shadow: 0px 4px 4px rgba(211, 211, 211, 0.25);
    border-radius: 15px;
    padding: 0 50px 70px 50px;
  }

  &-text {
    padding: 20px 50px 40px;
    font-size: 18px;
  }

  &__cards-box {
    margin-bottom: 42px;
    width: 100%;
  }

  &__field {
    display: flex;
    flex-direction: column;
    margin-bottom: 23px;
    width: 100%;
    p {
      margin-top: 10px;
      font-size: 14px;
    }
    .valid-input {
      color:#000000B3;
    } 
    .error-input {
      color:#D32F2F;
    }
  }

  &__field-title {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: rgba(0, 0, 0, 0.7);
    margin-bottom: 8px;
  }

  &__field-input {
    outline: none;
    width: 80%;
    height: 50px;
    background: #FFFFFF;
    border: 1px solid #C7C7C7;
    border-radius: 15px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: rgba(0, 0, 0, 0.7);
    padding: 17px 19px;
  }

  &__card-wrap {
    display: flex;
    gap: 61px;
  }

  &__field-input--short {
    width: 240px;
  }

  &__hint {
    width: 80%;
    background: #FFF7E4;
    border-radius: 15px;
    padding: 12px 16px;
    margin-bottom: 26px;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: rgba(0, 0, 0, 0.7);
  }

  &__btn {
    outline: none;
    width: 80%;
    height: 50px;
    background: $orange-color;
    border-radius: 15px;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: $white-color;
    padding: 14px;
  }

  .enabled-btn {
    opacity: 1;
    cursor: pointer;
  }
  .disabled-btn {
    opacity: 0.5;
  }

  &__custom-select {
    display: flex;
    flex-direction: column;
    position: relative;
  }

  &__select-header {
    display: flex;
    align-items: center;
  }

  &__select-title {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #EF5C27;
    margin-right: 10px;
  }

  &__option-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 15px;
    position: absolute;
    top: 20px;
    left: -10px;
    z-index: 10;
    background: #FFFFFF;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
    border-radius: 15px;
    max-height: 300px;
    min-width: 100px;
  }

  &__option-content {
    overflow-x: hidden;
    overflow-y: auto;
  }

  &__option {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #474747;
    cursor: pointer;
    margin: 12px 0;

    &:hover {
      color: #EF5C27;
    }
  }
}

.transfer-card__app-swiper {
  display: none;
}

.transfer-card__app-other {
  .layout__app-otherWrap {
    display: none;
  }
}

@media (max-width: 1000px) {
  .transfer-card__content {
    padding: 20px;
  }
}

@media (max-width: 970px) {
  .swiper-container {
    width: 550px;
  }
}

@media (max-width: 900px) {
  .transfer-card__app {
    display: none;
  }

  .swiper-container {
    width: 100%;
  }

  .transfer-card__content {
    width: 80%;
    padding: 24px 10px 70px 10px !important;
    box-shadow: none;
  }

}

@media (max-width: 900px) {
  .transfer-card__content, .transfer-card-text {
    padding: 10px 0;
  }

  .transfer-card {
    border-radius: 25px 25px 0 0;
    margin-top: -22px;
    background: white;
  }
}

.transfer-card__btn.btn-disabled {
  opacity: 0.5;
}

@media (max-width: 585px) {
  .swiper-container {
    width: 100%;
  }
}

@media (max-width: 500px) {
  .transfer-card__field-input {
    width: 100%;
    text-align: center;
  }
  .transfer-card__field-input::placeholder {
    font-size: 10px;
    text-align: center;
  }
  .transfer-card__field-input::before {
    font-size: 20px;
    text-align: center;
  }
  .transfer-card__hint {
    width: 100%;
  }
  .transfer-card__btn {
    width: 100%;
    font-size: 16px;
  }

}

.kASjOW {
  margin-top: 10px;
  margin-right: 90px;
}

.input-phone {
  width: 100%;
  outline: none;
  border: 1px solid #C7C7C7;
  height: 50px;
  padding: 10px;
  border-radius: 15px;
}

.cgZNUk {
  width: 100%;
}