@import "../../variables/index";

.pay-communal {
  width: $width;
  display: flex;
  margin: 0 auto;
  justify-content: space-between;

  &__left {
    width: 753px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    &-add-templates {
      display: flex;
      padding: 40px 0 15px;

      &-headtext {
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        color: $black-color;
      }
    }

    &-add-icon {
      display: flex;
      padding-left: 25px;
      cursor: pointer;
      text-decoration: none;

      &-text {
        font-weight: 500;
        font-size: 14px;
        line-height: 100%;
        color: $orange-color;
        align-self: center;
        padding-left: 5px;
      }
    }

    &-wrap {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
      padding: 0px 0 40px;

      &-line {
        border-bottom: 1px solid $white-color-border;
      }

      &-container {
        display: flex;
        flex-direction: column;
        width: 243px;
        padding-top: 30px;
        text-decoration: none;

        &-title {
          display: flex;
          margin: 0 auto;
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
          color: $black-color;
          padding-top: 20px;
          text-align: center;
        }

        &-image {
          //width: 100px;
          //height: 100px;
          border-radius: 50%;
          border:1px solid $white-color-border;
          margin: 0 auto;
          display: flex;

          &-pic {
            display: flex;
            margin: 0 auto;
            width: 100px;
            height: 100px;
          }
        }
      }
    }
  }

  &__right {
    width: 367px;
  }
}
