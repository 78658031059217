.recurring-item {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;

  &__image-wrapper {
    width: 7%;
    //height: 45px;
    overflow: hidden;
    //margin-right: 10px;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &__payment-info {
    display: flex;
    flex-direction: column;
    //margin-right: 20px;
    width: 37%;
  }

  &__payment-title {
    width: 100%;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #474747;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__payment-subtitle {
    width: 100%;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #888888;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__payment-price {
    line-height: 100%;
    color: #474747;
    font-size: 16px;
    text-transform: uppercase;
    //width: 100px;
    width: 12%;
    //margin-right: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__price-value {
    font-weight: 600;
  }

  &__payment-date {
    display: flex;
    flex-direction: column;
    font-weight: 500;
    //width: 138px;
    width: 23%;
    //margin-right: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__payment-switch {
    //margin-right: 20px;
    //width: 56px;
    width: 10%;

  }

  &__date-title {
    font-size: 12px;
    line-height: 15px;
    color: #888888;
    margin-bottom: 4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__date-subtitle {
    font-size: 16px;
    line-height: 100%;
    color: #474747;
  }

  &__icon {
    display: flex;
    align-content: center;
    cursor: pointer;
    width: 4%;
  }
}

@media (max-width: 535px) {
  .recurring-item {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;


    &__payment-price {
      display: none;
    }

    &__payment-info {
      display: flex;
      flex-direction: column;
      //margin-right: 20px;
      width: 50%;
    }
  }
}

@media (max-width: 465px) {
  .recurring-item {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;

    &__image-wrapper {
      width: 7%;
      overflow: hidden;
      margin-right: 5px;
    }

    &__payment-info {
      display: flex;
      flex-direction: column;
      //margin-right: 20px;
      width: 50%;
    }
  }
}

@media (max-width: 455px) {
  .recurring-item {
    &__icon {
      display: flex;
      align-content: center;
      cursor: pointer;
      width: 4%;
      margin-left: 5px;
    }

    &__payment-info {
      display: flex;
      flex-direction: column;
      width: 60%;
    }

    &__payment-date {
      display: none;
    }
  }
}

@media (max-width: 340px) {
  .recurring-item {
    display: flex;
    align-items: center;
    width: 95%;
    justify-content: space-between;

    &__icon {
      margin-left: 11px;
    }
  }
}