@import "../../../variables/index";

.selected-category {

  &__catalog {
    width: $width;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    margin: 0 auto;
    padding-bottom: 116px;

    &-item {
      width: 30%;
      display: flex;
      padding: 6px 0;

      &-div {
        display: flex;
        width: 95%;
        text-decoration: none;
      }

      &-input {
        display: flex;
        align-self: center;
        margin-right: 15px;
      }

      &-img {
        width: 45px;
        height: 45px;
        border-radius: 50%;
        display: flex;
        align-content: center;
        justify-content: center;
        margin-right: 10px;
      }

      &-name {
        display: flex;
        align-self: center;
      }

      &-image {
        width: 25px;
        height: 25px;
        align-self: center;
      }

      &-text {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: $black-color;
        cursor: pointer;
        transition: 1s;
      }
    }
  }
}
