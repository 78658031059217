@import "../../../variables/index";

.tab-panel {
  width: 100%;
  display: flex;
  padding: 5px 0;

  &__btnPay {
    margin-right: 20px !important;
    text-decoration: none;
  }

  .css-1iji0d4 {
    padding: 0 !important;
  }

  &__img {
    width: 45px;
    height: 45px;
    display: flex;
    border: 1px solid $white-color-border;
    border-radius: 50%;
    margin: 0 5px;
    flex-shrink: 0;

    &-pic {
      width: 30px;
      height: 30px;
      align-self: center;
      margin: 0 auto;
      object-fit: contain;
    }
  }

  &__adress {
    display: flex;
    flex-direction: column;
    width: 40% !important;
    margin-right: 15px;

    &-street {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color:$black-color;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &-info {
      display: flex;

      &-item {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        color: $gray-color;
        padding-right: 5px;
        max-width: 220px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &-item-data {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        color: $gray-color;
        padding-right: 5px;
        max-width: 220px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  &__price {
    display: flex;
    align-self: center;
    width: 50%;
    justify-content: space-between;

    &-item {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 145.4%;
      color: $orange-color;
      width: 66%;
    }

    &-text {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      align-self: center;
      color: $green-color;
      width: 35%;
    }
  }

  &__dots {
    display: flex;
    padding-left: 30px;

    &-img {
      align-self: center;
    }
  }
  &__btn {
    display: flex;
    align-self: center;
    margin-left: 35px;
    text-decoration: none;
  }
  &__not-found {
    font-weight: 500;
    font-size: 18px;
    line-height: 32px;
    color: #888888;
    width: 100%;
    position: absolute;
    top: 500%;
    text-align: center;
  }
  .css-1n3tkec.Mui-expanded {
    width: 100% !important;;
    border-radius: 15px !important;
    box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px !important;
  }
  .css-15v22id-MuiAccordionDetails-root {
    padding: 8px 10px 10px ;
  }
}

.tab-panel-accordion {
  display: flex;
  width: 100% !important;
  align-items: center;

  &__footer {
    display: flex;
    width: 100%;

    &-wrapl {
      display: flex;
      //width: 51%;
      width: 50.8%;
      flex-direction: column;

      &-item {
        display: flex;
        flex-direction: column;
        padding-bottom: 20px;

        &-text {
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 15px;
          color: $gray-color;
        }

        &-com {
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
          color: $black-color;
        }
      }
    }

    &-wrapr {
      display: flex;
      width: 40%;
      flex-direction: column;

      &-item {
        display: flex;
        flex-direction: column;
        padding-bottom: 20px;
      }
    }
  }
}

.css-1udso76-MuiPaper-root-MuiAccordion-root.Mui-expanded {
  border-radius: 15px;
}

.css-1udso76-MuiPaper-root-MuiAccordion-root.Mui-expanded:first-of-type {
  border-radius: 15px;
}

.css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root {
  padding: 0 !important;
}


@media (max-width: 900px) {
  .tab-panel-accordion__footer-wrapl {
    width: 50.5% !important;
  }
}

@media (max-width: 768px) {
  .tab-panel-accordion__footer-wrapl {
    width: 50.5%;
  }
}
@media (max-width: 800px) {
  .tab-panel__adress {
    width: 100% !important;
  }
}

@media (max-width: 800px) {
  .tab-panel__adress-info-item-data {
    display: none;
  }
  .tab-panel__adress-info-item-dot {
    display: none;
  }
}

@media (max-width: 615px) {
  .tab-panel-accordion__footer-wrapl {
    width: 49.7% !important;
  }
}
@media (max-width: 600px) {
  .tab-panel__price-item {
    display: none;
  }
}
@media (max-width: 500px) {
  .tab-panel {
    .css-17o5nyn {
      width: 95% !important;
    }
    .css-1fx8m19 {
      width: 5% !important;
    }
    .css-1iji0d4 {
      width: 100% !important;

      .css-17o5nyn {
        width: 95% !important;
      }
      .css-1fx8m19 {
        width: 5% !important;
      }
    }
  }
}

@media (max-width: 410px) {
  .tab-panel-accordion__footer-wrapl-item-com {
    font-size: 12px;
  }
  .css-15v22id-MuiAccordionDetails-root {
    padding: 0;
  }
  .tab-panel {
    .css-1fx8m19 {
      display: none;
    }
  }
  .tab-panel__adress {
    width: 60% !important;
  }
}
