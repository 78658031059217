@import "../../variables/index";

.masterPass-modal {
  display: flex;
  flex-direction: column;
  width: 600px;
  padding: 20px;

  &__header {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 30px;
  }

  &__content-body {
    display: flex;
    flex-direction: column;
    //align-items: center;
  }

  &__content-text {
    color: $black-color;
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 30px;
  }

  &__content-field {
    width: 100%;
    color: $black-color;
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 30px;
  }

  &__content-btn {
    max-width: 150px;
    padding: 15px;
    background: $orange-color;
    border: none;
    color: $white-color;
    font-size: 16px;
  }

  &__content-btn--cancel {
    background: $white-color;
    border: 1px solid $orange-color;
    color: $orange-color;
    font-size: 16px;
    margin-left: 30px;
  }
}