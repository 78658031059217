@import "../../variables/index";

.invalid {
  //border:1px solid red !important;
}
input, textarea {
  outline: none !important;
  box-shadow: none !important;
  width: 350px;
  height: 35px;
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: 1px solid $orange-color;
  padding-bottom: 12px;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  padding-left: 6px;
  //margin-left: -11px;
}
.text-danger {
  color: $red-color;
  margin-top: -3px;
  display: flex;
}

.prefiks {
  display: flex;
  align-self: center;
  border-bottom: 1px solid $orange-color;
  padding-bottom: 11px;
  margin-bottom: 13px;
  width: 50px;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
}

.wrap-number {
  display: flex;
}

.form-group {
  display: flex;
  flex-direction: column;
}

.text-danger :active {
  content: "";

  input {
    margin-top: 3px;
  }
}
