@import "../../variables/index";

.faq {
  width: $width;
  display: flex;
  margin: 0 auto;
  flex-grow: 1;
  .css-o4b71y-MuiAccordionSummary-content{
    margin: 15px 0 !important;
  }
  .css-i4bv87-MuiSvgIcon-root{
    color: $orange-color;
  }
  .css-o4b71y-MuiAccordionSummary-content.Mui-expanded {
    color: $orange-color;
  }
  &-body {
    width: 100%;

    &-accordion {
      margin-bottom: 40px;
      width: 100%;

      .css-i4bv87-MuiSvgIcon-root {
        color: $orange-color;
      }

      .css-o4b71y-MuiAccordionSummary-content.Mui-expanded {
        color: $orange-color;
      }

      .css-ahj2mt-MuiTypography-root {
        word-wrap: break-word;
      }
      .css-9l3uo3 {
        word-wrap: break-word;
      }
      .MuiTypography-root .MuiTypography-body1 .css-9l3uo3 {
        word-wrap: break-word;
      }
      p {
        margin-left: 10px;
      }
  }

    &__title {
      padding: 5px 0 30px 0;
      font-size: 26px;
      font-weight: 600;
    }
  }
}

.faq-body-accordion > div {
  padding: 0 16px !important;
}

@media (max-width: 900px) {
  .faq-body__title {
    padding: 15px 0 30px 0;
  }

}

.css-ahj2mt-MuiTypography-root {
  @media (max-width: 400px) {
    font-size: 12px;

  }
}

@media (max-width: 400px) {
  .faq {
    &-body{
      &__title {
        padding: 0 0 15px 0;
        font-size: 18px;
      }
    }
  }
}


