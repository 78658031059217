@import "../../../variables/index";

.settings {
  max-width: $width;
  margin: 0 auto;
  display: flex;
  width: 100%;
  //padding: 10px 20px;
  //padding: 0 10px;

  //padding-bottom: 120px;

  &__skeleton {
    display: flex;
  }

  &__skeleton-900 {
    display: none;
  }

  &__skeleton-768 {
    display: none;
  }

  &__skeleton-560 {
    display: none;
  }

  &__skeleton-425 {
    display: none;
  }
  &__skeleton-375 {
    display: none;
  }
  &__skeleton-320 {
    display: none;
  }

  &-body {
    min-width: 66%;


    &__wrapper {
      display: flex;
    }

    &__title {
      font-size: 26px;
      font-weight: 600;
      //margin: 15px 0;
    }
  }

  &__avatar-wrapper {
    position: relative;
  }

  &__avatar-box {
    width: 109px;
    height: 109px;
    border-radius: 50%;
    //border: 1px solid #EF5C27;
    padding: 5px;
    margin-top: 20px;
  }

  .avatar-box__border {
    border: 1px solid #EF5C27;
  }

  &__image {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    overflow: hidden;
    background-color: rgba($gray-border-color, 0.2);

  }

  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__img-add {
    position: absolute;
    right: 0;
    top: 85px;
    cursor: pointer;

    //&:hover {
    //  transform: scale(1.1);
    //}
  }

  .profile-details {
    padding-bottom: 14px;
    margin-bottom: 20px;
    display: flex
  }
}

.settings-right {
  width: 33%;
  margin-left: 20px;
}

.avatar {
  &__select {
    display: none;
  }

  &__select--open {
    position: absolute;
    top: 100px;
    right: -215px;
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    box-shadow: 0px 2px 8px rgb(0 0 0 / 27%);
    border-radius: 15px;
    padding: 20px;
    z-index: 1;
  }

  &__select-option {
    font-weight: 500;
    font-size: 16px;
    line-height: 17px;
    display: flex;
    align-items: center;
    cursor: pointer;
    color: $black-color;

    &:hover {
      color: $orange-color;
    }

    margin-bottom: 10px;
  }

  &__upload-label {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 17px;
    cursor: pointer;
    color: $black-color;

    &:hover {
      color: $orange-color;
    }
  }

  &__upload-input {
    display: none;
  }

  &__select-cancel {
    text-align: center;
    border-radius: 15px;
    padding: 10px;
    color: $white-color;
    background-color: $orange-color;
    cursor: pointer;
    font-weight: 500;
    font-size: 16px;
    line-height: 17px;
    border: 1px solid $white-color;

    &:hover {
      color: $orange-color;
      background-color: $white-color;
      border: 1px solid $orange-color;
    }
  }
}


.editable-form {
  margin-left: 25px;
  width: 85%;

  &__icon {
    display: none;
  }

  &__content-fullName {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $white-color-border;
    //margin: 30px 0px;
  }

  &__content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $white-color-border;
    //margin: 30px 0px;
  }

  &__inner {
    //width: 70%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__label {
    min-width: 220px;
    font-size: 14px;
    padding-top: 5px;
    color: rgba(0, 0, 0, 0.38);
  }

  &__input {
    border: none;
    outline: none;
    font-size: 14px;
    background: none;
  }

  &__input:active {
    border-bottom: 1px solid $orange-color;
  }

  &__btns {
    //display: flex;
    width: 37%;
    justify-content: space-between;
    //height: 30px;

    &-wrap {
      display: flex;
      justify-content: flex-end;
    }
  }

  &__btn {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #EF5C27;
    margin-left: 5px;
    border-bottom: 1px dotted #EF5C27;
    cursor: pointer;
    border-top: none;
    outline: none;
    background: white;
    border-left: none;
    border-right: none;
  }

  &__dropdown {
    //width: 64%;
    border: none;
    outline: none;
    background-color: $white-color;
    color: $orange-color;
    //margin-top: 4px
  }
}

.warning-message {
  display: flex;
  align-items: center;
  background: $banner-background;
  font-size: 14px;
  padding: 10px 15px;
  border-radius: 15px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.border {
  width: 100%;
  border: 1px solid $white-color-border;
  margin: 13px 0px;
}

.modal-profile {
  padding: 30px;

  &__name {
    display: flex;
    justify-content: space-between;

    &-text {
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
    }

    &-btn {
      cursor: pointer;
    }
  }

  &__edition {
    margin: 20px 0;
  }
}

.hidden {
  opacity: 0;
  height: 0;
  width: 0;
  line-height: 0;
  overflow: hidden;
  padding: 0;
  margin: 0;
}
.profile-details-extra{
  padding-bottom: 14px;
  margin-bottom: 20px
}

.profile-details {
  &__gender {
    min-width: 220px;
    &__wrap{
      display: flex;
      margin: 20px 0;
    }
  }

  &__form {
    padding-left: 130px;
  }
}

.calendar__wrap {
  width: 60%;
}

@media (max-width: 900px) {

  .settings__skeleton {
    display: none;
  }

  .settings__skeleton-900 {
    display: flex;
  }

  .settings__avatar-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .settings__img-add {
    left: 52%;
    top: 65%;
  }

  .avatar__select--open {
    top: 85%;
    left: 50%;
    margin-left: -123px;
    right: unset;
  }


  .editable-form__content {
    .custom-calendar__calendar {
      width: 100%;
    }
    .react-calendar {
      width: 100%;
    }
  }
  .custom-calendar__calendar-button {
    .custom-button {
        width: 90%;
    }
  }

  .settings {
    padding: 0;
    flex-direction: column;
    display: flex;

    .custom-button {
      padding: 0;
    }

    &-body {
      &__wrapper {
        flex-direction: column;
        align-items: center;
      }
    }

    &__title {
      display: none;
    }

    .editable-form {
      width: 100%;
      margin: 0;
    }

    .profile-details{

      &__form {
        padding: 0;

        .calendar__wrap {
          display: flex;
          width: 100%;
        }
      }
    }

  }

  .settings-right {
    width: 100%;
    margin-left: 0;

  }
  .registration-block {
    box-shadow: none;
    padding: 0;

    .menu-registration {
      padding-left: 0;
    }
  }

}


@media (max-width: 768px) {
  .settings-right {
    width: 100%;
  }

  .settings__skeleton-900 {
    display: none;
  }
  .settings__skeleton-768 {
    display: flex;
  }
  .registration-block {
    box-shadow: none;
  }
  .settings {
    padding: 0;
    flex-direction: column;
    display: flex;

    .profile-details {
      &__gender {
        min-width: 0;

        &__wrap {
          display: flex;
          align-items: center;
          margin-bottom: 10px;
        }
      }
    }

    .settings__avatar-box {
      height: 90px;
      width: 90px;
    }

    .custom-button {
      padding: 0;
    }

    &-body {
      &__wrapper {
        flex-direction: column;
      }

      &__title {
        display: none;
      }
    }

    .message-info__header-back {
      display: none;
    }
  }
  .editable-form {
    width: 100%;
    margin: 0;

    &__label {
      display: none;
    }

    &__icon {
      display: block;
      padding-right: 10px;
    }
  }



}

@media (max-width: 560px) {
  .editable-form__content {
    .modal__content .active {
      width: 80%;
    }
  }

  .settings__skeleton-768 {
    display: none;
  }

  .settings__skeleton-560 {
    display: flex;
  }

/*  .avatar__select--open {
    left: 30%;
  }*/
}

@media (max-width: 500px) {
  .editable-form__content {
    .modal__content .active {
      width: 80%;
    }
  }
}

@media (max-width: 425px) {
  .settings__skeleton-560 {
    display: none;
  }
  .settings__skeleton-425 {
    display: flex;
  }
}

@media (max-width: 400px){
  .editable-form {
    .css-1x51dt5-MuiInputBase-input-MuiInput-input {
      padding: 0 5px;
      text-overflow: ellipsis;
    }
  }
  .editable-form__btns {
    width: 43%;
  }
}

@media (max-width: 375px){
  .settings__skeleton-425 {
    display: none;
  }
  .settings__skeleton-375 {
    display: flex;
  }
}

@media (max-width: 320px){
  .settings__skeleton-375 {
    display: none;
  }
  .settings__skeleton-320 {
    display: flex;
  }
}
.otp-modal {
  width: 100%;
  margin: auto;
  .otp-container {
    text-align: center;
    h1 {
      font-size: 16px;
      margin: 15px;
    }
    .otp-inputs {
      display: flex;
      justify-content: center;
      gap: 10px;  
      input {
        padding: 5px;
        width: 40px;
        height: 40px;
        font-size: 18px;
        border: 2px solid #ccc;
        border-radius: 5px;
        text-align: center;
      }
    }
    button {
      margin-top: 20px;
      padding: 10px 20px;
      font-size: 16px;
      background-color: #EF5C27;
      color: white;
      border: none;
      border-radius: 5px;
      cursor: pointer;
    }
    .disabled {
      opacity: 0.5;
      cursor: auto
    }
  }
}