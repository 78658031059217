@import "../../../variables";

.qr-modal {
  width: 100%;
  display: flex;
  flex-direction: column;

  &__header {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
  }

  &__close {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 20px;
  }

  &__user {
    font-weight: 600;
    font-size: 22px;
    line-height: 27px;
    color: $black-color;
    margin: 20px 0;
    text-align: center;
    max-width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__footer, &__links, &__link  {
    display: flex;
    flex-direction: column;
  }

  &__links-title {
    font-weight: 500;
    font-size: 16px;
    line-height: 17px;
    color: $gray-color;
    text-align: center;
    margin-bottom: 15px;
  }

  &__links-box {
    display: flex;
    justify-content: space-between;
    //margin-bottom: 20px;
  }

  &__link {
    align-items: center;
  }

  &__link-img {
    width: 25px;
    height: 25px;
    cursor: pointer;
    margin-bottom: 3px;
  }

  &__link-title {
    font-size: 10px;
    color: $black-color;
  }

  &__copy {
    display: flex;
    //flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
  }

  &__copy-link {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: $black-color;
    margin-right: 10px;
  }

  &__copy-img {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }

  &__print {
    border-radius: 15px;
    width: 100%;
    padding: 14px;
    text-align: center;
    background-color: $orange-color;
    color: $white-color;
    margin-bottom: 20px;
  }
}

.qr-print {
  //display: none;
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
    padding: 20px;
    background: $orange-color;
    -webkit-print-color-adjust: exact;
  }

  &__phone {
    color: $white-color;
    font-size: 28px;
    font-weight: 600;
  }

  &__qr {
    min-width: 320px;
  }
}