.breadcrumb {
    //margin-left: 50px;
    margin-bottom: 30px;
    display: flex;
    div {
        line-height: 30px;
    }
    a {
        margin: 5px 0;
        text-decoration: none;
        text-align: center;
        color: #EF5C27
    }
    .divider {
        margin: 0 5px;
    }
}

@media screen and (max-width: 600px) {
    .breadcrumb {
        margin-left: 0;
        width: 60%;
        text-wrap: wrap;

        a {
            font-size: 13px;
            display: flex;
            align-items: center;
        }

        .divider {
            display: flex;
            align-items: center;
            margin: 0 10px;
        }

        div {
            line-height: 15px;
            font-size: 13px;
            display: flex;
            align-items: center;
        }
    }
}

@media (max-width: 555px) {
    .breadcrumb {
        width: 75%;
    }
}
