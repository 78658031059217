@import "../../../variables/index";


.wallet-office {
    width: $width;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 120px;

  &__content{
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  &__content-text{
    display: flex;
    flex-direction: column;
    padding-top: 14px;
    flex-grow: 1;
  }

  &__content-header {
    font-weight: 600;
    font-size: 26px;
    line-height: 32px;
    color: $black-color;
    margin-bottom: 15px;
  }

  &__content-body {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: $black-color;
  }

  &__docs{
    margin-bottom: 25px;
  }

  &__docs-title {
    margin-bottom: 20px;
  }

  &__docs-box{
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }

  &__docs-icon {
    margin-right: 15px;
  }

  &__office-info{
    display: flex;
    flex-direction: column;
    font-size: 14px;
    line-height: 17px;
    color: $black-color;
    margin-bottom: 20px;
  }

  &__info-title {
    font-weight: 600;
    margin-bottom: 5px;
  }
  &__info-text {
    font-weight: 500;
    margin-bottom: 5px;
  }

  &__info-link {
    text-decoration: none;
    color: inherit;
  }

  &__map-text {
    font-weight: 500;
    color: $orange-color;
    border-bottom: 1px dashed $orange-color;
    cursor: pointer;
  }

  &__map-content {
    margin-top: 10px;
    width: 100%;
  }
}
.wallet-office__content-menu {
  width: 33%;
}

@media only screen and (max-width: 900px) {
  .wallet-office__content-menu {
    display: none;
  }

  .wallet-office__content-header {
    font-size: 18px;
  }
}
