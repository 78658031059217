@import "../../../variables/index";

.modal-virtual-card {
  display: flex;
  justify-content: space-between;
  padding-bottom: 17px;

  p{
    display: flex;
    align-self: center;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: $black-color;
  }

  img{
    cursor: pointer;
  }

  &__text {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: $black-color;
  }

  &__input-number {
    display: flex;
    padding: 23px 0 30px;
    margin: 0 auto;
    justify-content: space-between;

    input {
      border-left: none;
      border-right: none;
      border-top: none;
      width: 78px;
      outline: none;
      border-bottom-color: $input-color;
      padding-bottom: 10px;

      &::placeholder {
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        display: flex;
        color: $gray-color;
        text-align: center;
      }
    }
  }


}
