@import "../../../variables/index";


.wallet-online {
    width: $width;
    margin: 0 auto 80px auto;
    display: flex;
    flex-direction: column;


    &__content {
        display: flex;
    }

    &__content-body{
        padding-top: 14px;
        display: flex;
        flex-direction: column;
        color: $black-color;
    }

    &__content-header {
        font-weight: 600;
        font-size: 26px;
        line-height: 32px;
        color: inherit;
        margin-bottom: 25px;
    }

    &__content-title {
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        color: inherit;
        margin-bottom: 10px;
    }

    &__content-text {
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: inherit;
        margin-bottom: 15px;
    }

    &__hint {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px 15px;
        width: 315px;
        background: $banner-background;
        border-radius: 15px;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        color: inherit;
        margin-bottom: 40px;
    }

    &__title-header {
        display: flex;
        align-items: center;
        margin-bottom: 15px;
    }

    &__title-icon {
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background-color: #F3EFFF;
        color: #9673FF;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        margin-right: 10px;
    }

    &__title-text {
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: inherit;
    }

    &__image-box {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 20px;
    }

    &__image {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__image-title {
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: inherit;
        margin-bottom: 10px;
    }

    &__select-image-box {
        width: 182px;
        height: 112px;
        overflow: hidden;
    }

    &__select-image{
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    &__upload-label {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 367px;
        height: 50px;
        background: #FFFFFF;
        border: 1px solid rgba(239, 92, 39, 0.25);
        border-radius: 15px;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: $orange-color;
        margin: 15px 0 40px 0;
    }

    &__upload-icon {
        margin-right: 10px;
    }
    &__upload-input {
        display: none;
    }

    &__subtitle {
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: $gray-color;
    }

    &__field {
        margin-top: 19px;
        margin-bottom: 32px;
        width: 367px;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        color: $black-color;
        padding-left: 0;
        margin-left: 0;
        ::placeholder {
            color: $gray-color;
        }
    }

    &__contacts-tabs {
        display: flex;
        gap: 15px;
        margin: 10px 0 12px 0;
    }

    &__contact {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
        background-color: $white-color-border;
        border-radius: 15px;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        color: inherit;
        cursor: pointer;
    }

    &__submit-btn {
        margin-top: 8px;
    }
}

@media only screen and (max-width: 900px) {
    .wallet-online__menu, .wallet-online__content-header {
        display: none;
    }

    .wallet-online__content-title {
        font-size: 26px;
    }
}

@media only screen and (max-width: 395px) {
    .wallet-online__image, .wallet-online__upload, .wallet-online__upload-label {
        width: 100%;
    }

    .wallet-online__image-box {
        padding-right: 10px;
    }

    .wallet-online__address-box, .wallet-online__phone-box, .wallet-online__contacts-box, .wallet-online__field {
        width: 100%;
    }

    .wallet-online__address-box, .wallet-online__phone-box, .wallet-online__contacts-box {
        padding-right: 10px;
    }

    .wallet-online__submit-btn {
        padding-right: 10px;
        .custom-button {
            min-width: 220px !important;
            padding: 14px !important;
            text-align: center !important;
        }
    }



    .wallet-online__content-title {
        font-size: 18px;
    }

    .wallet-online__hint {
        width: 100%;
    }
}



