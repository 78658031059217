.right-basket {
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12);
  border-radius: 15px;
  overflow: hidden;
  width: 367px;
  padding: 30px 18px;
  &-wrapBtn {
    display: flex;
  }
  &-sum {
    display: flex;
    flex-direction: column;
    &__item {
      display: flex;
      justify-content: space-between;
      &-head {
        font-size: 16px;
        color: #474747;
        display: flex;
        align-items: center;
        span {
          margin-right: 5px;
        }
      }
      .add-checkbox {
        width: 100%;
        padding: 24px 0 !important;
      }
      &-sum {
        font-size: 12px;
        span {
          font-size: 24px;
        }
      }
    }
    &__recurring {
      display: flex;
      align-items: center;
      border-top: 1px solid #F1F1F1;
      border-bottom: 1px solid #F1F1F1;
      padding: 20px 0;
      .recurring-period__period-title {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
      }
      input {
        width: 40px;
        text-align: center;
        margin-right: 10px;
      }
    }
    &__date {
      background: #FFF6E4;
      border-radius: 15px;
      height: 37px;
      display: flex;
      align-items: center;
      padding: 0 10px;
    }
  }
  &-btn {
    text-decoration: none;
    width: 100%;
    button {
      width: 100%;
    }
  }

  &__right-other-payment {
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12);
    border-radius: 15px;
    overflow: hidden;
    padding: 24px;
  }
}

@media (max-width: 900px) {
  .right-basket {
    margin-right: 20px;
  }
}
