@import "../../variables/index";

.last-operation {
  height: max-content;
  width: 100%;
  margin: 20px 0;
  border-radius: 15px;
  box-shadow: $shadow-box-color 0 3px 8px;

  &__header {
    width: 92%;
    margin: 0 auto;
  }
  &__header-div {
    padding: 20px;
  }
  &__header-div-text {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
  }
  &__header-div-empty {
    display: flex;
    margin: 0 auto;
    padding: 20px;
    justify-content: center;
  }
  &__information {
    display: flex;
    justify-content: space-evenly;
    padding-bottom: 15px;
    width: 95%;
    text-decoration: none;

    &-main {
      display: flex;
      width: 100%;
      cursor: pointer;

      &-div {
        display: flex;
        width: 20%;
      }

      @media only screen and (max-width: 950px) {

      }
    }

    &-image-wrap {
      width: 46px;
      height: 46px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #F0FFD9;
    }

    &-image {
      width: 100%;
      height: 100%;
      object-fit: cover;

      @media only screen and (max-width: 950px) {
        width: 60%;
        height: 60%;
        object-fit: cover;
      }
    }

    &-title {
      display: flex;
      align-self: center;
      flex-direction: column;
      width: 60%;

      &-street {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: $black-color;

        @media only screen and (max-width: 950px) {
           font-size: 12px;
        }
      }
      &-service {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        color: $gray-color;

        @media only screen and (max-width: 950px) {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    &-count-wrap {
      display: flex;
      align-self: center;
      width: 20%;
      padding-left: 10px;
    }

    &-price {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      padding-right: 5px;
      color: $black-color;
    }

    &-currency {
      color: $gray-color;
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 20px;
    cursor: pointer;

    &-text {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: $orange-color;
      text-decoration: none;
    }
  }
}

@media only screen and (max-width: 960px) {
  .last-operation__header-div-text {
    font-size: 16px;
    line-height: 20px;
  }

  .last-operation__information-image-wrap {
    width: 40px;
    height: 40px;
  }

  .last-operation__information-title-street {
    font-size: 12px;
    line-height: 15px;
  }

  .last-operation__information-title-service {
    font-size: 10px;
  }

  .last-operation__information-price,
  .last-operation__information-currency {
    font-size: 14px;
  }


}