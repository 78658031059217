$width: 1180px;
$gray-color: #888888;
$black-color: #474747;
$white-color: #ffffff;
$orange-color: #EF5C27;
$violet-color: #9473D6;
$black-color-c: #000000;
$black-color-b: #dedede;
$rgba-white-color: rgba(241, 241, 241, 1);
$rgba-orange-color: rgba(239, 92, 39, 0.25);
$white-color-border: #F1F1F1;
$rgba-orange-color-o: rgba(239, 92, 39, 1);
$background-orange-color-o: #FFEAE9;
$background-white-color-w: #f9f9f9;
$shadow-color: rgba(0, 0, 0, 0.12);
$btn-hover-color: #e8dfdb;
$banner-background: #FFF6E4;
$violet-back-color: #9673FF;
$border-color-gray: rgba(255, 255, 255, 0.35);
$green-color: #18A750;
$red-color: #FF0000FF;
$gray-border-color: #7d7a7a;
$yellow-white-color: #F0FFD9;
$violet-footer-color: #403B4A;
$auth-color: rgba(255, 255, 255, 0.3);
$menu-box-color: rgba(0, 0, 0, .4);
$dividing-color: rgba(235, 235, 235, 1);
$shadow-box-color: rgba(0, 0, 0, 0.24);
$input-color: #F18E19;
$empty-color: rgba(229, 220, 255, 1);
$blue-white-color: #34495E;
$pink-color: #CB63C4;
$black-rgba: rgba(0, 0, 0, 0.1);
$black-rgba2: rgba(0, 0, 0, 0.2);
$gray-text: #525252;

$font: 'Montserrat';
$maxWidth: 1500px;
$colorDesign: #f05d33;
$textColor: rgba(0, 0, 0, 0.7);
$errorTextColor: red;
$textSize: 16px;
$linkColor: #ff3300;
$siteFont: $font, sans-serif;
$h1Size: 24px;

$mM: 'montserratMedium';
$mB: 'montserratBold';
$mainBackground: radial-gradient(
                56.22% 143.13% at -5.99% 106.25%,
                #f99b29 0%,
                rgba(239, 92, 39, 0) 100%
),
radial-gradient(
                137.1% 349.03% at 111.06% -5.15%,
                #9673ff 0%,
                rgba(239, 92, 39, 0) 100%
),
#ef5c27;
