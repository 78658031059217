@import "../../../variables/index";

.payment-period-page {
  width: $width;
  margin: 0 auto;
  padding-bottom: 204px;

  &__header {
    display: flex;
    justify-content: space-between;

    &-left {
      width: 65%;

      &-body {
        padding: 36px 0 30px;
      }
    }

    &-right {
      width: 33%;
    }
  }

  &__footer {
    display: flex;

    &-cancel {
      padding-left: 30px;
    }
  }
}
