
.main-mes {
  //max-width: 1180px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 auto;
  flex-grow: 1;
  //padding: 0 20px;
}
.wrap-dont-info {
  display: flex;
  align-items: center;
  height: 50vh;
}
.message {
  width: 66%;

  @media (max-width: 768px) {
    width: 100%;
  }

  &-right {
    width: 32%;
    @media (max-width: 768px) {
      display: none;
    }

  }

  &__header {
    display: flex;
    //margin: 25px 0;

    @media (max-width: 768px) {
      display: flex;
      flex-direction: column;
      margin: 5px 5px;
    }

    @media (max-width: 400px) {
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    &-text {
      width: 30%;
      display: flex;
      align-self: center;

      @media (max-width: 768px) {
        width: 100%;
        margin: 0 auto;
      }
    }

    &-message {
      font-family: 'Montserrat';
      font-size: 26px;
      font-weight: 600;
    }

    &-input {
      width: 70%;
      display: flex;
      align-self: center;
      justify-content: flex-end;

      @media (max-width: 768px) {
        width: 100%;
      }
    }
    &-input-wrap {
      display: flex;
      width: 80%;
      height: 52px;
      background-color: #F1F1F1;
      border-radius: 15px;

      @media (max-width: 768px) {
        width: 100%;
        height: 40px;
      }

      @media (max-width: 430px) {
        width: 100%;
        height: 35px;
      }
    }
    &-search-input {
      width: 100%;
      height: 40px;
      display: flex;
      align-self: center;
      outline: none;
      border: none;
      padding: 10px;
      background-color: #F1F1F1;
      border-radius: 15px;
    }

    &-search-icon {
      display: flex;
      align-self: center;
    }

    input[type="search"] {
      border: none;
      background: transparent;
      margin: 0;
      padding: 7px 8px;
      font-size: 14px;
      color: inherit;
      border: 1px solid transparent;
      border-radius: inherit;
    }

    input[type="search"]::placeholder {
      color: #bbb;
    }

    button[type="submit"] {
      text-indent: -999px;
      overflow: hidden;
      width: 40px;
      padding: 0;
      margin: 0;
      border: 1px solid transparent;
      border-radius: inherit;
      background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E") no-repeat center;
      cursor: pointer;
      opacity: 0.7;
    }

    button[type="submit"]:hover {
      opacity: 1;
    }
  }

  &__main {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    cursor: pointer;
    text-decoration: none;
    margin-top: 20px;

    &-info {
      width: 70%;
      padding: 5px;

      @media (max-width: 430px) {
        width: 100%;
      }
    }

    &-title {
      display: flex;
      align-items: center;
      margin-bottom: 6px;
    }

    &-circle {
      width: 10px;
      height: 10px;
      border: 1px solid red;
      border-radius: 50%;
      background: #EF5C27;
    }

    &-text {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #474747;
      margin-left: 8px;

      @media (max-width: 400px) {
        font-size: 12px;
      }

    }

    &-text-info {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      color: #474747;

      @media (max-width: 400px) {
        font-size: 10px;
        word-wrap: break-word;
      }
    }

    &-date-info {
      width: 15%;
      padding: 5px;

      @media (max-width: 430px) {
        display: none;
      }
    }

    &-date {
      justify-content: flex-end;
      display: flex;
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      color: #474747;

      @media (max-width: 430px) {
        display: none;
      }

    }

    &-title-read {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #888888;
    }
    &-text-info-read {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      color: #888888;
      margin-top: 6px;
      word-break: break-word;
    }
    &-date-read {
      justify-content: flex-end;
      display: flex;
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      color: #888888;

    }

  }

}

.header-text {
  @media (max-width: 768px) {
    margin: 0 auto;
    margin-bottom: 10px;

    .header-text__title {
      font-size: 22px;
    }
  }
}

@media (max-width: 400px) {
  .header-text {
    display: flex;
    margin: 0;
    margin-bottom: 15px;
  }
}

.mes-main {
  @media (max-width: 400px) {
    width: 75%;
  }
}

@media (max-width: 900px) {
  .main-mes {
    padding: 0;
  }

}
