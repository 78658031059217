@import "../../../variables/index";

.bonus-product {
    &__body {
        display: flex;
    }

    &__image-box {
        width: 367px;
        height: 367px;
        border: 1px solid #F1F1F1;
        border-radius: 15px;
        padding: 26px;
        flex-shrink: 0;
        margin-right: 20px;
    }

    &__image {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    &__content{
        display: flex;
        flex-direction: column;
        max-width: 753px;
    }

    &__content-title {
        font-weight: 600;
        font-size: 32px;
        line-height: 39px;
        color: $black-color;
        margin-bottom: 40px;
    }

    &__form-field {
        display: flex;
        align-items: center;

    }

    &__field-label {
        font-weight: 500;
        width: 256px;
        font-size: 14px;
        line-height: 17px;
        display: flex;
        align-items: flex-end;
        color: #888888;
        position: relative;
        &::after {
            content: '';
            position: absolute;
            height: 1px;
            background-color: #F1F1F1;
            left: 0;
            right: 0;
            bottom: -9px;
        }
    }

    &__cards-box{
        margin-top: 40px;
    }

    &__btn-box {
        display: flex;
        margin: 40px 0 80px 0;
    }

    &__btn{
        outline: none;
        padding: 14px;
        width: 237px;
        height: 50px;
        background: $orange-color;
        border-radius: 15px;
        text-align: center;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        color: $white-color;
        cursor: pointer;
        margin-right: 20px;
    }

    &__bonus-count {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    &__count {
        font-weight: 600;
        font-size: 26px;
        line-height: 100%;
        color: #9473D6;
        margin: 0 10px 5px 0;
    }

    &__bonus-info {
        display: flex;
        flex-direction: column;
    }

    &__info-title{
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        letter-spacing: 0.02em;
        color: $black-color;
        margin-bottom: 10px;
    }

    &__text {
        font-weight: 500;
        font-size: 14px;
        line-height: 120%;
        color: $gray-color;
    }

    &__bonus-link {
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: $orange-color;
        //text-decoration: none;
        text-decoration-style: dashed;
        margin: 10px 0 30px 0;
    }

    &__field-input {
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: $black-color;
        margin: 5px 0;
    }
}

@media only screen and (max-width: 1090px) {
    .bonus-product__body {
        flex-wrap: wrap;
        justify-content: center;
    }

    .bonus-product__image-box {
        margin-bottom: 30px;
        padding: 0;
        width: 351px;
        height: 351px;
    }

    .bonus-product__content-title {
        text-align: center;
    }

    .bonus-product__form-field {
        flex-direction: column;
        margin-bottom: 30px;
        width: 100%;
        align-items: flex-start;
    }

    .bonus-product__field-label {
        margin-bottom: 10px;
        &::after {
            content: none;
        }
    }

    .bonus-product__field-input{
        width: 100%;
        input {
            width: 100%;
        }
    }
    .bonus-product__content-title {
        font-size: 28px;
    }

    .bonus-product__btn-box {
        margin: 40px 0;
    }
}

@media only screen and (max-width: 450px) {
    .bonus-product__image-box {
        width: 331px;
        height: 331px;
        margin-right: 0;
    }
    .bonus-product__btn-box{
        flex-direction: column;
        align-items: center;
    }
    .bonus-product__btn {
        margin-bottom: 20px;
        margin-right: 0;
        width: 100%;
    }
    .bonus-product__content-title {
        font-size: 24px;
        line-height: 28px;
    }

    .message-info__header-back {
        margin: 0;
    }
    .card-container{
        justify-content: center;
    }
}

@media only screen and (max-width: 350px) {

    .bonus-modal__header-text {
        font-size: 18px;
    }

    .bonus-modal__body-title {
        font-size: 16px;
    }
    .bonus-product__image-box {
        width: 290px;
        height: 290px;
        margin-right: 0;
    }
    .bonus-product__btn-box{
        flex-direction: column;
        align-items: center;
    }
    .bonus-product__btn {
        margin-bottom: 20px;
        margin-right: 0;
    }
    .bonus-product__content-title {
        font-size: 18px;
        line-height: 22px;
        letter-spacing: 0.02em;
    }
}
