@import '../../../variables/index';

.virtual-card {
  display: flex;
  flex-direction: column;

  &__title {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: $black-color;
    margin-bottom: 20px;

    span {
      color: $orange-color;
    }
  }

  &__info-box {
    display: flex;
    flex-wrap: wrap;
  }

  &__info-point {
    display: flex;
    width: 48%;
    &:nth-child(even) {
      margin-left: 20px;
    }

    margin-bottom: 20px;
  }

  &__point-image {
    width: 26px;
    height: 26px;
    flex-shrink: 0;
    margin-right: 20px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__point-text {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: $black-color;
  }

  &__hint {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: $black-color;
    border-radius: 15px;
    text-align: center;
    padding: 10px 15px;
    background-color: $banner-background;
    width: fit-content;
    margin-bottom: 20px;
  }

  &__form, &__field {
    display: flex;
    flex-direction: column;
  }

  &__form {
    margin-bottom: 20px;
  }

  &__field  {
    margin-bottom: 15px;
    position: relative;
  }

  &__select {
    position: absolute;
    z-index: 10;
    left: 0;
    top: 52px;
    padding: 10px 0;
    background-color: $white-color;
    border-radius: 15px;
    border: 1px solid red;
    width: fit-content;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &__select-option {
    padding: 0 10px;

    &:hover {
      background-color: $white-color-border;
    }
  }

  &__field-label {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: $gray-color;
  }

  &__field-input {
    padding-left: 0;
    width: 100%;
    padding-bottom: 0;
  }

  &__field-checkbox {
    display: flex;
    align-items: center;
  }

  &__field-checkbox {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: $black-color;

    a {
      text-decoration: none;
      color: $orange-color;
      margin-left: 5px;
    }
  }

  &__btn {
    background-color: $orange-color;
    text-align: center;
    color: $white-color;
    padding: 14px;
    border-radius: 15px;
    max-width: 367px;
    cursor: pointer;
    align-self: center;
    height: auto;
  }

  &__info-title {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 100%;
    color: $black-color;
    margin-bottom: 10px;
  }

  &__info-text {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 100%;
    color: $black-color;
    margin-bottom: 10px;
  }


}