@import "../../../variables/index";

.recurring-create {
  display: flex;
  flex-direction: column;

  &__title {
    font-weight: 600;
    font-size: 26px;
    line-height: 32px;
    color: $black-color;
    margin-bottom: 30px;
  }

  &__btns {
    position: relative;
    display: flex;
    gap: 30px;
  }

}

.delete-modal {
  padding: 20px;

  &__title {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #474747;
    margin-right: 40px;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 40px;
  }

  &__btns {
    display: flex;
    justify-content: center;
    gap: 30px;
  }

  &__btn {
    outline: none;
    border: none;
    height: 50px;
    min-width: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    padding: 15px;
    cursor: pointer;
  }

  &__close-icon {
    display: flex;
    cursor: pointer;
    justify-content: flex-end;
    margin-bottom: 30px;
  }

  &__btn--delete {
    background-color: #EF5C27;
    color: #ffffff;

    &:disabled {
      pointer-events: none !important;
      background: #ece8e8 !important;
      color: #a3a3a3 !important;
    }
  }



  &__btn--cancel {
    background-color: transparent;
    color: #EF5C27;
    border: 1px solid #EF5C27;
  }
}
