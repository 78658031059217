@import "../../../variables/index";

.header-text {

  &__title {
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 32px;
    color:$black-color;
    text-align: center;
  }
}

@media (max-width: 430px) {
  .header-text__title {
    font-size: 20px;
    line-height: 24px;
  }

}