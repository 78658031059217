@import "../../variables/index";

.custom-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border: none;
  font: inherit;
  cursor: pointer;
  font-size: 14px;
  &__img{
    padding-right: 10px;
    width: 34px;
    height: 34px;
  }
    &:hover{
        background: $btn-hover-color;
    }

  &:disabled {
    opacity: 0.7;
  }
}
