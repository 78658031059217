@import "../../variables/index";

.home-page {
  width: 100%;
  height: auto;
  &__container {
    display: flex;
    gap: 20px;
    &-wrap {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
    }
    &-right {
      width: 367px;
    }
  }
  &__header {
    margin: 0 0 15px;
    &-wrap {
      position: relative;
      input {
        border: 1px solid $black-rgba;
        padding: 12px 20px;
        width: 100%;
        height: 52px;
        border-radius: 15px;
        padding-left: 42px;
        font-size: 16px;
        background-color: $white-color-border;
        outline: none;
        border: none;
        &:focus {
          border: 1px solid $black-rgba2;
          border: none;
          outline: none;
        }
      }
      &-button {
        display: flex;
        align-self: center;
      }
      &-clear {
        width: 18px;
        height: 18px;
        opacity: 0.3;
        position: absolute;
        right: 15px;
        top: 17px;
        cursor: pointer;
        &:hover {
          opacity: 0.8;
        }
      }
      &-search-icon {
        width: 22px;
        height: 22px;
        opacity: 0.3;
        position: absolute;
        left: 14px;
        top: 17px;
      }
    }
    &-example {
      display: flex;
      padding-top: 14px;
    }
    &-text {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      color: $gray-text;
      margin-right: 5px;
    }
    &-infoText {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      color: $orange-color;
      margin-left: 5px;
      border-bottom: 1px dotted $orange-color;
      cursor: pointer;
    }
  }
  &__search {
    width: 100%;
    background: #F1F1F1;
    border-radius: 15px;
    padding: 13px 20px;
    display: flex;
    align-items: center;
  }
  &__search-icon,
  &__close-icon {
    border: none;
    background: none;
    cursor: pointer;
  }
  &__input {
    all: unset;
    width: 100%;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 17px;
    margin-left: 10px;
    &::-webkit-input-placeholder {
      color: #888888;
    }
    &:-ms-input-placeholder {
      color: #888888;
    }
    &::placeholder {
      color: #888888;
    }
  }
}

.home-modal-div {
  max-width: 500px;
}

.home-modal {
  display: flex;
  flex-direction: column;
  &__head-close {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
    img {
      cursor: pointer;
      padding-left: 15px;
    }
    &-text {
      font-style: normal;
      font-weight: 500;
      font-size: clamp(18px, 4vw, 26px);
      line-height: 32px;
      color: #474747;
    }
  }
  &__body-text {
    padding-top: 10px;
    cursor: pointer;
    color: #474747;
    font-size: 16px;
    line-height: 18px;
  }
}

@media (max-width: 900px) {
  .home-page__container-wrap {
    overflow: hidden;
  }
}
