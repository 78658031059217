@import "../../variables/index";

.add-favorite{
  display: flex;
  flex-direction: column;
  width: 753px;
  margin: 0 auto;
  &__service-title {
    font-weight: 600;
    font-size: 26px;
    line-height: 32px;
    color: $black-color;
    margin-bottom: 30px;
    text-align: center;
  }
  &__content {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
  }
  &__comment {
    width: 100%;
    margin-bottom: 30px;
  }
  input {
    padding-bottom: 0;
    padding-left: 0;
    margin-left: 0;
    width: 100%;
  }
  select {
    border: none;
    border-bottom: 1px solid $colorDesign;
    outline: none;
    padding: 5px 30px 5px 0;
    margin-left: -3px;
  }
}

.favotite-link {
  text-decoration: none;
  color: #474747;
}

.favorites {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  &__left {
    flex: 1;
    display: flex;
    align-items: center;
    * {
      width: 80%;
    }
  } 
  &__right {
    display: flex;
    align-items: center;
    gap: 16px;
  }
  &__img {
    width: 46px;
    height: 46px;
  }
  &__pay {
    display: flex;
    text-decoration: none;
  }
  &__info {
    display: flex;
    gap: 50px;
    margin-left: 10px;
    font-family: Montserrat;
    &__block {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      &__name {
        font-size: 14px;
        font-weight: 600;
        line-height: 17.07px;
      }
      &__value {
        font-size: 12px;
        font-weight: 500;
        line-height: 14.63px;
      }
    }
    &__debt {
      text-align: end;
      color: red;
    }
    &__trash {
      img {
        height: 20px;
      }
    }
  }
}

.favorite-info {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-right: 15px;
  &__head {
    display: flex;
    justify-content: space-between;
    &__title {
      align-self: center;
      font-size: 26px;
      font-weight: 600;
    }
    img {
      width: 45px;
      height: 45px;
    }
  }
  &__body {
      display: flex;
      flex-direction: column;
      gap: 20px;
      margin: 22px 0px 32px;
    &__row {
      display: flex;
      justify-content: space-between;
      &__value {
        font-weight: 500;
        display: flex;
        flex-direction: column;
        p:first-child {
          color: #888888;
          font-size: 14px;
        }
        p::nth-child(2) {
          font-size: 18px;
        }
      }
      img {
        width: 18px;
        height: 18px;
        cursor: pointer;
        margin-right: 10px;
      }
    }
  }
  &__pay {
    text-decoration: none;
  }
}

@media (max-width: 900px) {
  .favorites-list {
    margin-right: 15px;
  }
}

@media (max-width: 850px) {
  .add-favorite__comment-label {
    font-size: 14px;
    line-height: 17px;
  }
}

@media (max-width: 800px) {
  .add-favorite {
    width: 100%;
  }
  .add-favorite__btn {
    .custom-button {
      padding: 14px !important;
      text-align: center !important;
    }
  }
}

@media (max-width: 680px) {
  .favorites__left * {
    max-width: 200px !important;
  }
  .favorites__info__block * {
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@media (max-width: 550px) {
  .favorites__pay {
    display: none;
  }
}