@import "../../variables/index";

.forgot-auth-password{
  display: flex;
  justify-content: center;
  margin: 20px 0;
  color:$orange-color;
  cursor: pointer;
}

.no-registration-main__header .css-5vb4lz {
  display: flex;
  flex-direction: column;
}

.password-input-container {
  position: relative;
}

.password-conditions {
  position: absolute;
  top: calc(100% + 5px);
  left: 0;
  background-color: #EFEFEF;
  border: 1px solid #EFEFEF;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  padding: 10px;
  z-index: 1000;
  .valid {
    color: #000;
  }
  .invalid {
    color: #e74c3c;
  }
  li {
    list-style: none;
  }
}

.password-triangle {
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #EFEFEF;
}