@import "../../variables/index";

.invite-friend {
  display: flex;
  margin: 0 auto;
  flex-grow: 1;
  width: 1140px;
  justify-content: space-between;

  &__copy {
    width: 25px;
    height: 25px;
    display: flex;
    align-self: center;
    margin-left: 10px;
    cursor: pointer;
  }

  &__inp {
    width: 450px;
    border: 1px solid #EF5C27;
    border-radius: 10px;
    box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px !important;
    padding: 10px;
    margin-right: 20px;
  }

  &__input-wrap {
    padding: 10px;
    width: 100%;

    &-icon {
      display: flex;
      width: 30%;
      justify-content: space-between;
    }

    &-link {
      display: flex;
      width: 100%;

      span {
        display: flex;
        align-self: center;
        margin-right: 10px;
      }
    }
  }

  &__cont {
    padding: 10px;
    border-radius: 15px;
    //box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
    box-shadow: 0px 4px 15px -3px rgba(0, 0, 0, 0.05) !important;
    filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.1)) !important;
    margin-bottom: 10px;
    width: 100%;
  }

  &__body {
    margin-left: 25px;
    padding: 10px;
  }

  &__head {
    display: flex;
    flex-direction: column;
    width: 753px;
    padding-right: 20px;
  }

  &__wrap {

    &-text {
      padding: 5px 0 0 10px;

      &-head {
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 20px;
      }

      &-w {
        margin: 10px 0;
        padding: 5px 0 10px 10px;
      }

      &-txt {
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
      }
    }
  }
}

@media (max-width: 600px) {
  .invite-friend__input-wrap-link {
    display: flex;
    flex-direction: column;
  }
  .invite-friend__inp {
    margin: 10px 0;
    width: 100%;
  }

  .invite-friend__copy {
    margin: 0;
  }
}

@media (max-width: 450px) {
  .invite-friend__wrap {
    margin-top: 10px;
  }
}

@media (max-width: 370px) {
  .invite-friend__input-wrap-icon {
    width: 40%;
  }
}