
@import "../../../variables/index";

.transfer {
  max-width: $width;
  margin: 0 auto;
  flex-grow: 1;
  width: 100%;
  padding: 0 10px 50px;
  display: flex;
  flex-direction: column;

  &__body {
    display: flex;
    justify-content: space-between;
  }

  &__comment-input {
    padding-left: 0;
    width: 367px;
    height: 49px;
    font-weight: 500;
    font-size: 20px;
    line-height: 29px;
    //margin-bottom: 30px;
  }

  &__field-error {
    color: red
  }

  &__content {
    padding-top: 14px;
    width: 66%;
  }

  &__content-title {
    margin-bottom: 30px;
  }

  &__content-body {
    display: flex;
    flex-direction: column;
  }

  &__field-box {
    display: flex;
    flex-direction: column;
  }

  &__field-title {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: $gray-color;
  }

  &__field {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    p {
      margin-top: 10px;
    }
    .valid-input {
      color:#000000B3;
    } 
    .error-input {
      color:#D32F2F;
    }
  }

  &__field--phone {
    margin: 19px 0 30px 0;
  }

  &__field-hint {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: $black-color;
    margin-bottom: 32px;
    margin-top: 10px;
  }

  &__btn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 14px;
    width: 390px;
    height: 50px;
    background: $orange-color;
    border-radius: 15px;
    cursor: pointer;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: $white-color;
    margin-top: 30px;
  }

  &__field--phone {
    margin: 19px 0 30px 0;
    width: 100%;
    max-width: 367px;

    #phone-input {
      border-bottom: 1px solid #EF5C27;
    }
  }

  //&__wrap {
  //  display: flex;
  //  justify-content: space-between;
  //
  //  &-left {
  //    width: 753px;
  //
  //    &-sld {
  //      margin: 30px 0;
  //    }
  //
  //    &-number {
  //      margin-top: 30px;
  //
  //      &-sum {
  //        font-weight: 500;
  //        font-size: 14px;
  //        line-height: 17px;
  //        color: $gray-color;
  //        padding-bottom: 10px;
  //      }
  //
  //      &-sumN {
  //        font-weight: 400;
  //        font-size: 14px;
  //        line-height: 17px;
  //        color: $black-color;
  //      }
  //    }
  //
  //    &-phone {
  //      margin-top: 40px;
  //
  //      &-text {
  //        font-weight: 500;
  //        font-size: 14px;
  //        line-height: 17px;
  //        color: $gray-color;
  //        margin-bottom: 20px;
  //      }
  //    }
  //  }
  //
  //  &-right {
  //    width: 367px;
  //  }
  //}
}

.transaction__side-menu {
  width: 33%;
}
.transfer__payment-btn {
  margin-top: 30px;
}

@media (max-width: 900px) {
  .transfer__content {
    width: 100%;
  }
  .transfer__payment-btn {
    .custom-button {
      width: 100% !important;
    }
  }
}

@media (max-width: 430px) {
  .input-box__field {
    width: 100px;
  }
  .transfer__content {
    padding: 0;
  }
  .transfer__field {
    input {
      width: 276px !important;
    }
  }
}

