.basket {
  display: flex;
  &-loading {
    position: fixed;
    left: 0;
    right: 0;
  }
  &-wrapper {
    display: flex;
    flex-direction: column;
    margin-right: 20px;
    &-head {
      display: flex;
    }
    &-footer {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-top: 40px;
      &__title {
        font-size: 20px;
        color: rgba(71, 71, 71, 1);
      }
      &-categories {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        margin-top: 20px;
        &__item {
          display: flex;
          width: calc((100% / 2) - 20px);
          margin: 0 20px 8px 0;
          text-decoration: none;
          transition-duration: 0.3s;
          &-image {
            display: flex;
            align-items: center;
            justify-content: center;
            img {
              height: 30px;
              width: 30px;
            }
          }
          &-icon {
            width: 64px;
            height: 64px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            cursor: pointer;
          }
          &-text {
            display: flex;
            align-items: center;
            padding-left: 15px;
            font-size: 16px;
            color: rgba(71, 71, 71, 1);
          }
        }
      }
    }
  }
}

.basket_content {
  margin-top: 20px;
  display: flex;
  padding: 0 15px 0 0;
  flex-direction: column;
  max-height: 971px;
  overflow-x: auto;
  overflow-y: auto;

  &::-webkit-scrollbar {
    height: 6px;
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #acacac;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
  }
  &__wrap {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }
  &__wrap:last-child {
    border-bottom: none;
  }
  &__item {
    display: flex;
    padding: 15px 15px 15px 0;
    &-check {
      display: flex;
      align-items: center;
      .add-checkbox {
        border-radius: 50%;
      }
      .add-checkbox input[type=checkbox] {
        border-radius: 50%;
      }
    }
    &-image {
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        height: 50px;
        width: 50px;
      }
    }
    &-info {
      width: 120px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-left: 10px;
      &-field {
        font-size: 14px;
        color: rgba(71, 71, 71, 1);
        overflow: hidden;
        text-overflow: ellipsis;
      }
      &-serv {
        font-size: 12px;
        color: rgba(136, 136, 136, 1);
        padding-top: 5px;
      }
    }
  }
  &__right {
    display: flex;
    align-items: center;
    position: relative;
    &-price {
      padding-right: 35px;
      &-item {
        color: rgba(136, 136, 136, 1);
        font-size: 12px;
        padding-left: 5px;
      }
    }
    img {
      height: 22px;
      cursor: pointer;
    }
    .basket-edit {
      margin-right: 12px;
    }
  }
}

.basket_content-empty {
  display: flex;
  flex-direction: column;
  &-head {
    font-size: 26px;
    color: #474747;
    font-weight: 600;
    margin-bottom: 12px;
  }
  &-desc {
    font-size: 16px;
    color: #3D3D3D;
    margin-bottom: 28px;
  }
}

.edit-page-wrap {
  &__wrapper-btns {
    width: 90%;
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
  }
  &__name {
    font-weight: 600;
    font-size: 26px;
    line-height: 32px;
    color: #474747;
    margin-bottom: 30px;
    text-align: center;
  }
  &__input-wrapper {
    &-value {
      margin-bottom: 45px;
      width: 40%;
      .service_phone-placeholder {
        font-size: 18px;
      }
      &-phoneNumber {
        display: flex;
        span {
          border-bottom: 1px solid #EF5C27;
          padding: 6px;
          font-size: 18px;
        }
      }
    }
    &-amount {
      width: 40%;
      border-bottom: 1px solid #EF5C27;
      input {
        border: none;
      }
    }
    input {
      padding: 0;
    }
  }
}

.edit-page-wrap__wrapper-btns-btn {
  width: 45%;
}

.edit-page-wrap__wrapper-btns-btn-save {
  border-radius: 15px;
  background: #EF5C27;
  color: #FFFFFF;
  padding: 14px;
  width: 100%;
  border: none;
  cursor: pointer;
}

.slider-popular-categories {
  display: none;
}

.basket-wrapper-slider-categories__item {
  width: 82px;
  height: 96px;
  text-decoration: none;

  &-icon {
    margin: 0 auto;
    width: 64px;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
  }
  &-text {
    text-align: center;
    padding: 0 10px;
    font-size: 12px;
    color: #474747;
  }
}

@media (max-width: 900px) {
  .edit-page-wrap__input-wrapper {
    &-value {
      width: 60%;
      input {
        width: 100%;
      }
    }
    &-amount {
      width: 60%;
    }
  }
  .basket-wrapper-footer-categories__item {
    width: calc(100% - 20px);
  }
}

@media (max-width: 870px) {
  .edit-page-wrap__wrapper-btns {
    display: flex;
  }
}

@media (max-width: 800px) {
  .basket_content {
    max-height: 323px;
  }
}

@media (max-width: 780px) {
  .edit-page-wrap__input-wrapper input {
    padding: 0 5px;
    width: 100%;
  }
}

@media (max-width: 600px) {
  .edit-page-wrap__input-wrapper {
    &-value {
      width: 90%;
    }
    &-amount {
      width: 90%;
    }
  }
}
@media (max-width: 500px) {
  .slider-popular-categories {
    display: flex;
    margin-top: 20px;
    .slick-track {
      height: 110px;
      display: flex;
    }
    .slick-slider {
      width: 100%;
    }
  }
  .basket-wrapper-footer-categories {
    display: none;
  }
}
