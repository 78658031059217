@import "../../../../variables/index";

.input-box {
  display: flex;
  align-items: center;

  &__field {
    display: flex;
    align-items: center;
  }
  &__phone-prefix {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #474747;
    height: 35px;
    border-bottom: 1px solid $orange-color;
    padding-right: 12px;
  }
}