@import "../../../variables/index";

.border {
  width: 100%;
  border: 1px solid $white-color-border;
}

.modal-exit {
  width: 400px;
  display: flex;
  flex-direction: column;
  padding: 20px 20px 10px 10px;

  &__btn {
    text-decoration: none;
  }

  &__text {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    //color: $black-color;
    color: #EF5C27;
    border-bottom: 1px dotted #EF5C27;
    display: flex;
    margin: 10px auto;
    cursor: pointer;
  }
}

.aside-menu {
  border-radius: 15px;
  width: 100%;
  box-shadow: 0px 2px 8px $shadow-color;

  &__wrap {
    padding-top: 10px;
    padding-bottom: 10px;

    &-item {
      display: flex;
      margin-top: 10px;
      cursor: pointer;
      text-decoration: none;
      color: $black-color;
      padding: 2px 20px;

      &:hover {
        background-color: $background-orange-color-o;
        border-left: 5px solid $red-color;
      }

      .m10 {
        display: block;
        width: 36px;
        color: #EF5C27;
      }

      &-text {
        display: flex;
        align-items: center;
        font-size: 14px;
        padding-left: 18px;
        padding-top: 5px;
      }

      &-textN {
        display: flex;
        align-items: center;
        font-size: 14px;
        padding-left: 10px;
      }

      &-pic {
        display: flex;
        padding: 5px 0;

        img{
          padding-left: 4px;
        }
      }
    }
  }
}


