@import "../../variables/index";

.menu-registration {
  display: flex;
  padding: 13px 0 13px 20px;
  cursor: pointer;
  text-decoration: none;
  position: relative;

  &:hover{
    background-color:$background-orange-color-o;
    //border-left: 5px solid red;

    &:before {
      position: absolute;
      content: '';
      width: 5px;
      top: 0;
      bottom: 0;
      left: 0;
      background: #EF5C27;
    }

  }

  &--active:before{
      position: absolute;
      content: '';
      width: 5px;
      top: 0;
      bottom: 0;
      left: 0;
      background: #EF5C27;
  }

  &__image-main {
    display: flex;
    text-decoration: none;
  }
  &__image {
    height: 26px;
    width: 30px;
    object-fit: contain;
  }
  &__text {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    align-self: center;
    margin-left: 15px;
    display: flex;
    align-items: center;
    color: $black-color-c;
  }

  &__count {
    display: flex;
    margin-left: 10px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    color:$white-color;
    background-color: $orange-color;
    justify-content: center;
    align-items: center;
  }
}
@media screen and (max-width: 1150px) {
 .menu-registration{
    padding: 13px 0 13px 10px;
  }
}