.right-component {
  display: flex;
  flex-direction: column;

  .modal__content.active {
    width: 420px;
  }

  &-panel {
    background: #ffffff;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12);
    border-radius: 15px;
    overflow: hidden;
    padding: 24px;
  }

  &-swiper {
    display: none;
  }
}
.right-component-other {
  display: flex;
}

.skeleton {
  height: max-content;
  //margin: 20px 0;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 0px 0 28px 5px;
}

.skeleton-swiper {
  display: none;
}

.right-skeleton {
  display: flex;
}
.right-skeleton1030 {
  display: none;
}

@media (max-width: 1030px) {
  .right-skeleton {
    display: none;
  }
  .right-skeleton1030 {
    display: flex !important;
  }

  .skeleton {
    padding: 0 0 0 5px;
  }
}

@media (max-width: 900px) {
  .right-component-panel {
    display: none;
  }
  .right-component-swiper {
    display: flex;
  }

  .right-component-other {
    display: none;
  }
  .skeleton-swiper {
    display: flex;
    margin-top: -60px;
  }
  .skeleton {
    display: none;
  }
}
