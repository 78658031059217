@import "../../variables/index";

.my-cards {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  margin-top: 15px;

  &__cards-box {
    display: flex;
    flex-direction: column;
    //flex-wrap: wrap;
  }

  &__box-title {
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 32px;
    color: $black-color;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
  }

  &__cards {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

  }

  &__add-card {
    text-decoration: none;
    display: flex;
    flex-direction: column;
    width: 270px;
    align-items: center;
    justify-content: center;
    padding: 25px 22px;
    border: 1px solid rgba(239, 92, 39, 0.25);
    border-radius: 15px;
    color: $orange-color;
  }

  &__add-icon {
    margin-bottom: 10px;
    width: 20px;
    height: 20px;
  }

  &__img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &__add-title {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: inherit;

  }

  &__add-card--purple {
    color: $violet-back-color;
    padding: 43px 22px;
    border: 1px solid #E5DCFF;
  }

  &__card {
    display: flex;
    flex-direction: column;
    width: 270px;
    //align-items: center;
    justify-content: center;
    padding: 15px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15);
    border-radius: 15px;
    background: $white-color-border;
  }

  &__card--active {
    background: radial-gradient(56.22% 143.13% at -5.99% 106.25%, #F99B29 0%, rgba(239, 92, 39, 0) 100%), radial-gradient(137.1% 349.03% at 111.06% -5.15%, #9673FF 0%, rgba(239, 92, 39, 0) 100%), #EF5C27;
  }

  &__card-header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    position: relative;
  }

  &__card-icon {
    position: absolute;
    right: -10px;
  }

  &__card-check {
    width: 24px;
    height: 24px;
    margin-right: 5px;
  }

  &__card-title {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: $black-color;
    flex-grow: 1;
  }

  &__card-title--active {
    color: #FFFFFF;
  }

  &__vertical-dots {
    height: 24px;
  }

  &__card-body {
    display: flex;
    align-items: center;
  }

  &__card-img {
    height: 27px;
    width: 55px;
    margin-right: 16px;
  }

  &__card-number {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: $black-color;
  }

  &__card-number--active {
    color: #FFFFFF;
  }

  &__card-footer {
    display: flex;
    margin-top: 15px;
  }

  &__card-amount {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: $orange-color;
  }

  &__card-currency {
    font-size: 16px;
    margin-left: 5px;
    line-height: 20px;
  }

  &__cards-box--top {
    margin-bottom: 80px;
  }

  &__hint-icon {
    display: inline-flex;
    margin-left: 10px;
    width: 22px;
    height: 22px;
  }

  &__modal {
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    border-radius: 15px;
    padding: 20px;
  }

  &__modal-header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__modal-title {
    flex-grow: 1;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: $black-color;
    width: 285px;
  }

  &__close-icon {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }

  &__card-info {
    display: flex;
    flex-direction: column;
    margin: 20px 0;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: $black-color;
    width: 100%;
  }

  &__card-label {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: $gray-color;
    margin-bottom: 20px;
  }

  &__card-input {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: $black-color;
    padding-left: 0;
    width: 100%;
  }

  &__btns {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__btn {
    padding: 14px;
    text-align: center;
    //width: 327px;
    background: $orange-color;
    border-radius: 15px;
    cursor: pointer;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #FFFFFF;
  }

  &__btn--disabled {
    opacity: 0.8;
    pointer-events: none;
  }

  &__btn--cancel {
    background: #FFFFFF;
    color: $orange-color;
  }
}

.my-card-skeleton-wrap {
  display: flex;
  flex-wrap: wrap;
}

.my-card-skeleton {
  display: flex;
}
.media900-my-card-skeleton {
  display: none
}

.media550-my-card-skeleton {
  display: none
}

@media (max-width: 900px) {
  .my-card-skeleton {
    display: none;
  }

  .media900-my-card-skeleton{
    display: flex;
    width: 50%;
  }

  .media550-my-card-skeleton {
    display: none;
  }
}

@media (max-width: 550px) {
  .media550-my-card-skeleton {
    display: flex;
    width: 100%;
  }

  .my-card-skeleton {
    display: none;
  }
  .media900-my-card-skeleton {
    display: none
  }
}



.add-my-card {
  display: flex;
  //min-width: $width;
  margin: 0 auto;
  flex-direction: column;
  //position: relative;
  width: 100%;

  &__footer-info {
    margin-bottom: 20px;
  }

  &__loader {
    position: absolute;
    top: 75%;
    left: 10%;
  }

  &__header {
    padding-bottom: 50px;

    &-myCard {
      font-style: normal;
      font-weight: 600;
      font-size: 26px;
      line-height: 32px;
      color: $black-color;
      margin-bottom: 22px;
    }
  }

  &__add-card-wrap {
    display: flex;
    margin-bottom: 20px;
    padding-top: 20px;

    &-add {
      padding-right: 25px;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: $gray-color;
      align-self: center;
    }

    &-image {
      display: flex;
      cursor: pointer;
    }

    &-img {
      width: 26px;
      height: 26px;
      cursor: pointer;
    }

    &-back {
      padding-left: 3px;
      display: flex;
      align-self: center;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: $orange-color;
    }


  }

  &__card-info {
    width: 387px;
    height: 213px;
    border-radius: 15px;
    background: radial-gradient(56.22% 143.13% at -5.99% 106.25%, #F99B29 0%, rgba(239, 92, 39, 0) 100%),
    radial-gradient(137.1% 349.03% at 111.06% -5.15%, #9673FF 0%, rgba(239, 92, 39, 0) 100%),
    linear-gradient(0deg, #EF5C27, #EF5C27);

    &-header {
      width: 327px;
      margin: 0 auto;


      &-text {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        padding: 16px 0 8px;
        color: $white-color;

      }

      &-input {
        width: 327px;
        height: 52px;
        border-radius: 15px;
        border: none;
      }
    }

    &-footer {
      display: flex;
      margin: 0 auto;
      width: 327px;
      position: relative;
    }
  }

  &__card-footer {
    width: 130px;
    padding-top: 3px;
    margin-right: 20px;

    &-image {
      top: 40px;
      right: 0;
      position: absolute;

      img {
        cursor: pointer;
      }
    }

    &-wrap {
      display: flex;
    }

    &-main {
      padding-right: 20px;
    }

    &-info-icon {
      width: 22px;
      height: 22px;
    }

    &-text {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: $white-color;
      padding-bottom: 4px;
    }

    &-input {
      width: 130px;
      height: 52px;
      border-radius: 15px;
      border: none;
    }
  }

  &__footer {
    width: 380px;
    justify-content: space-between;
    display: flex;
    margin-top: 20px;
    flex-direction: column;

    &-button {
      width: 100%;
      display: flex;
      margin-top: 30px;
      text-decoration: none;

      &-save {
        width: 100%;
        height: 50px;
        border-radius: 15px;
        background: rgba(239, 92, 39, 0.1);
        border: none;
      }
    }
  }
}

.created-virtual-card {
  display: flex;
  width: 1140px;
  margin: 0 auto;
  flex-direction: column;

  &__main {
    width: 65%;
  }

  &__header {

    &-textb {
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      color: $black-color;
    }

    &-text {
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      color: $orange-color;
    }
  }

  &__checkbox-wrap{
    display: flex;
    padding-bottom: 30px;

    input {
      width: 45px;
      height: 40px;
      border-radius: 5px;
      border: 1px solid $blue-white-color;
      outline: none;
      transition-duration: 0.3s;
      cursor: pointer;
    }

    p{
      align-self: center;
      padding-left: 9px;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: $black-color-c;
    }
  }

  &__input-wrap {
    padding: 30px 0 20px;

    &-container {
      padding-bottom: 20px;

      p {
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: $gray-color;
      }

      input {
        border-top: none;
        border-right: none;
        border-left: none;
        width: 360px;
        height: 30px;
        outline: none;
        border-bottom-color: $input-color;
        &::placeholder {
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
        }
      }
    }
  }

  &__body {
    margin-top: 20px;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    justify-content: space-between;

    &-wrap {
      display: flex;
      width: 48%;
      margin-bottom: 20px;

      &-img {
        width: 26px;
        height: 26px;
        margin-right: 15px;
      }

      &-text {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: $black-color;
      }
    }
  }

  &__condition {
    width: 407px;
    padding: 36px 0 32px;

    &-item {
      width: 100%;
      height: 37px;
      border: none;
      border-radius: 15px;
      background: $banner-background;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: $black-color;
      padding: 10px;
    }
  }

  &__footer {
    display: flex;
    flex-direction: column;
    margin-top: 15px;
    padding-bottom: 94px;

    &-head {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 100%;
      color: $black-color;
      padding-bottom: 10px;
    }

    &-text {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 100%;
      color: $black-color-c;
      padding-bottom: 10px;
    }
  }
}

.add-my-virtual-card {
  width: $width;
  margin: 0 auto;
  display: flex;
  flex-direction: column;

  &__right-wrap {
    width: 367px;
  }

  &__slider {
    padding-bottom: 50px;
  }

  &__checkbox {
    display: flex;
    padding: 25px 0;

    &-text {
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: $black-color;
      align-self: center;

      &-condition {
        color: $orange-color;
      }
    }
  }

  &__container {
    width: 753px;

    &-head {
      font-style: normal;
      font-weight: 600;
      font-size: 26px;
      line-height: 32px;
      color: $black-color;
      padding-bottom: 30px;
    }

    &-select {
      display: flex;
      width: 367px;
      justify-content: space-between;
      margin-bottom: 30px;
      padding-top: 30px;
      border-bottom: 1px solid $rgba-white-color;

      &-text {
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        color: $black-color;
        padding-bottom: 12px;
      }
    }

    &-sum {
      display: flex;
      flex-direction: column;
      width: 367px;

      &-text {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: $gray-color;
        padding-bottom: 10px;
      }

      &-num {
        font-style: normal;
        font-weight: 500;
        font-size: 40px;
        line-height: 49px;

        input {
          width: 367px;
          border-top-style: hidden;
          border-right-style: hidden;
          border-left-style: hidden;
          border-bottom-style: solid;
          border-bottom-color: $rgba-orange-color-o;
          background-color: $white-color;
          font-style: normal;
          font-weight: 500;
          font-size: 40px;
        }

        .no-outline:focus {
          outline: none;
        }
      }

      &-com {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: $black-color;
        padding-top: 5px;
      }
    }
  }

  &__payment {
    display: flex;
    margin-top: 32px;

    &-text {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: $gray-color;
      padding-right: 25px;
      display: flex;
      align-self: center;
    }

    &-addCart {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: $orange-color;
      display: flex;
      align-self: center;
      padding-left: 8px;
    }
  }
}

.card-container {
  display: flex;

  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  margin-top: 10px;
  position: relative;
  margin-left: 12px;
  @media (max-width: 620px) {
    flex-wrap: wrap;
    justify-content: center;
  }
}

.card-loading {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.card {
  display: flex;
  min-height: 136px;
  flex-direction: column;
  padding: 15px 19px;
  background: radial-gradient(56.22% 143.13% at -5.99% 106.25%, #F99B29 0%, rgba(239, 92, 39, 0) 100%), radial-gradient(137.1% 349.03% at 111.06% -5.15%, #9673FF 0%, rgba(239, 92, 39, 0) 100%), #EF5C27;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
  opacity:0.6;
  cursor: pointer;
  transition:all 0.15s;
  width: 33.3%;
  flex-grow: 1;
  max-width: 200px;
  @media (max-width: 620px) {
    padding-left: 10px;
    padding-right: 10px;
  }
  &--active {
    opacity: 1;
    transform: scale(1.1);
  }

  &__header {
    position: relative;
    display: flex;
    margin-bottom: 24px;
  }

  &__header-title {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #FFFFFF;
  }

  &__header-icon {
    position: absolute;
    top: 0;
    right: 0;
  }

  &__body {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #FFFFFF;
    margin-bottom: 13px;
    @media (max-width: 620px) {
      font-size: 15px;
    }
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  &__footer-title {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #FFFFFF;
    flex-grow: 1;
  }

  &__footer-icon {
    width: fit-content;
    height: 27px;
  }

  &__img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.swiper-container {
  position: relative;
  //width: 600px;
  @media (max-width: 650px) {
    width: 400px;
    margin: 0 auto;
  }
  @media (max-width: 420px) {
    width: 100%;
  }
  .swiper-slide-active {
    width: 250px !important;
  }

  //margin: 0 auto;

  .swiper-pointer-events {
    height: 150px;
    //width: 600px;
    //@media (max-width: 650px) {
    //  width: 400px;
    //}
    @media (max-width: 420px) {
      width: 100%;
    }
  }
}

.swiper-wrapper {
  transform: translate3d(-611px, 0px, 0px);
}

.swiper-container > .swiper-slide__content {
  position: absolute;
  top: 0;
}

.swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 200ms linear;
  transform: scale(0.8);
  overflow: hidden;
  //margin: 0 7px;
  @media (max-width: 420px) {
    transform: scale(1);
    //width: 100% !important;
  }


  &.swiper-slide-active {
    transform: scale(1);
    //width: 232px !important;
    //margin-right: -34px !important;
    z-index: 999;
    border-radius: 15px;
    //@media (max-width: 420px) {
    //  width: 100% !important;
    //  margin-right: 0 !important;
    //}
  }
}

.swiper-slide {
  flex-shrink: 0;
  //width: 232px !important;
  height: 100%;
  position: relative;
  transition-property: transform;
  border-radius: 15px;
  //margin-right: -34px !important;
  //@media (max-width: 420px) {
  //  width: 100% !important;
  //  margin-right: 1px !important;
  //}
}

.swiper-slide.swiper-slide-active {
  .swiper-slide__content {
    background: radial-gradient(56.22% 143.13% at -5.99% 106.25%, #F99B29 0%, rgba(239, 92, 39, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, radial-gradient(137.1% 349.03% at 111.06% -5.15%, #9673FF 0%, rgba(239, 92, 39, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, #EF5C27;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15);

  }
}

.swiper-slide__content-wap {
  display: flex;
  margin-top: 10px;
  text-align: start
}

.swiper-slide-prev {
  margin-right: -18px;
  @media (max-width: 420px) {
    margin-right: 0 !important;
  }
}

.swiper-slide__content {
  height: 150px;
  width: 100%;
  border-radius: 15px;
  //opacity: 0.6;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.6),
              rgba(255, 255, 255, 0.6)),
              radial-gradient(56.22% 143.13% at -5.99% 106.25%, #F99B29 0%,
              rgba(239, 92, 39, 0) 100%),
              radial-gradient(137.1% 349.03% at 111.06% -5.15%, #9673FF 0%,
              rgba(239, 92, 39, 0) 100%), #EF5C27;

  &-body {
    padding: 5px 14px;
    color: $white-color;
    font-size: 20px;
  }

  &-footer {
    display: flex;
    padding: 10px;
    justify-content: space-between;
    color: $white-color;

  }

  &-name-cart {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    padding: 15px 0 24px 20px;
    color: $white-color;
    width: 100%;
    max-height: 50px;
    text-align: start;
  }
}


.swiper {
  position: relative;

  .swiper-button-prev {
    position: absolute;
    top: 50%;
    left: 0;
    border: 1px solid $orange-color;
    padding: 20px;
    margin-top: -25px;
    background-color: $white-color;
    border-radius: 15px;
    width: 50px;
    height: 50px;

    &:after {
      font-size: larger;
      left: calc(50% - 16px / 2 + 3px);
      top: calc(50% - 8px / 2 + 12px);
      color: $rgba-orange-color-o;
    }
  }

  .swiper-button-next {
    position: absolute;
    top: 50%;
    right: 0;
    border: 1px solid $orange-color;
    padding: 20px;
    margin-top: -25px;
    background-color: $white-color;
    border-radius: 15px;
    width: 50px;
    height: 50px;

    &:after {
      font-size: larger;
      left: calc(50% - 16px / 2 + 3px);
      top: calc(50% - 8px / 2 + 12px);
      color: $rgba-orange-color-o;
    }
  }
}

.number-card {
  .coDRDP {
    background: $white-color;
    width: 327px;
    text-align: center;
    letter-spacing: 2px;
    border-radius: 15px;
    height: 52px;
    outline: none;
    border: none;
  }

  .cXkBeP {
    background: $white-color;
    width: 327px;
    text-align: center;
    letter-spacing: 2px;
    border-radius: 15px;
    height: 52px;
    outline: none;
    border: none;
    color: $black-color;
  }
}

.wrap-number-card {
  .dNqPxa {
    color: $red-color;
  }
}

.expiry-date {
  .coDRDP {
    background: $white-color;
    text-align: center;
    letter-spacing: 2px;
    border-radius: 15px;
    width: 130px;
    height: 52px;
    outline: none;
    border: none;
  }

  .cXkBeP {
    background: $white-color;
    text-align: center;
    letter-spacing: 2px;
    border-radius: 15px;
    width: 130px;
    height: 52px;
    outline: none;
    border: none;

  }
}

.cvc {
  .coDRDP {
    background: $white-color;
    text-align: center;
    letter-spacing: 2px;
    border-radius: 15px;
    width: 130px;
    height: 52px;
    outline: none;
    border: none;
  }

  .cXkBeP {
    background: $white-color;
    text-align: center;
    letter-spacing: 2px;
    border-radius: 15px;
    width: 130px;
    height: 52px;
    outline: none;
    border: none;

  }
}

.my-cards__cards--digital {
  position: relative;
}

.card-virtual {
  height: 132px;
  width: 350px;
  border-radius: 15px;
  border: 1px solid $pink-color;
  background: $white-color-border;
  margin-top: 25px;
  display: flex;
  cursor: pointer;

  &__left {
    display: flex;
    flex-direction: column;
    width: 180px;
    height: 122px;
    margin-left: 10px;
    margin-top: 10px;

    &-card {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: $black-color-c
    }

    &-cardNUmber {
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      color: $black-color-c;
      padding-top: 10px;
    }

    &-pan {
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      color: $black-color-c
    }

    &-info {
      display: flex;
      width: 80%;
      justify-content: space-between;
      padding-top: 5px;

      &-dot {
        display: flex;
        width: 50%;
      }
    }
  }

  &__right {
    width: 140px;
    margin-left: 5px;
    margin-top: 10px;


    &-price {
      display: flex;
      justify-content: space-between;
    }

    &-visa {
      display: flex;
      justify-content: flex-end;
      padding-top: 50px;
    }
  }
}

.modal-cvv {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__image {

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      padding: 10px;
    }
  }

  .custom-button {
    width: 100%;
  }


}

.digital-card {
  background: radial-gradient(56.22% 143.13% at -5.99% 106.25%, #9473D6 0%, #9673FF 100%), radial-gradient(137.1% 349.03% at 111.06% -5.15%, #9673FF 0%, rgba(62, 18, 156, 0) 100%), linear-gradient(0deg, #9473D6, #9473D6), #F3EFFF;
  border-radius: 15px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  width: 270px;
  flex-shrink: 0;


  &__header {
    display: flex;
    //justify-content: space-between;
    align-items: center;
    position: relative;
    margin-bottom: 36px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &__header-currency {
    width: 18px;
    height: 18px;
    margin-right: 6px;
  }

  &__header-logo {
    width: 40px;
    height: 12px;
  }

  &__title {
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: $white-color;
  }

  &__header-right {
    position: absolute;
    right: -15px;
    display: flex;
    top: -10px;
    //bottom: 0;
    align-items: baseline;
  }

  &__content {
    font-style: normal;
    font-weight: 500;
    font-size: 19px;
    line-height: 23px;
    color: $white-color;
    margin-bottom: 22px;
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__balance {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    color: $white-color;

    span{
      margin-left: 5px;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: -0.02em;
      color: $white-color;
    }
  }

  &__footer-info {
    display: flex;
    align-items: center;
  }

  &__info-box {
    display: flex;
    flex-direction: column;
  }

  &__info-title {
    font-weight: 500;
    font-size: 7px;
    line-height: 9px;
    letter-spacing: -0.02em;
    color: $white-color;
    margin-bottom: 3px;
  }

  &__info-text {
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -0.02em;
    color: $white-color;
  }

  &__cvv {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #A98EFB;
    border-radius: 3.31165px;
    width: 35px;
    height: 18px;
    padding: 6px;
    color: $white-color;
    font-size: 12px;
  }
}

.my-cards__loading {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(#ffffff, 0.5);
}

@media (max-width: 900px) {
  .my-cards__cards {
    display: flex;
    justify-content: space-between;
  }
  .my-cards__card {
    width: 48%;
  }
  .my-cards__add-card {
    width: 48%;
  }
}

@media (max-width: 550px) {
  .my-cards__cards {
    display: flex;
    justify-content: space-between;
  }
  .my-cards__card {
    width: 100%;
  }
  .my-cards__add-card {
    width: 100%;
  }

  //.add-my-card__footer-info {
  //  .add-checkbox input {
  //    width: 65px !important;
  //    height: 35px !important;
  //  }
  //}
}

@media (max-width: 400px) {
  .add-my-card__footer {
    width: 100% !important;

    .custom-button {
      min-width: 100% !important;
      padding: 10px 90px !important;
    }
  }

  //.add-my-card__footer-info {
  //  .add-checkbox input {
  //    width: 60px !important;
  //    height: 35px !important;
  //  }
  //}
}


@media (max-width: 350px) {
  //.add-my-card__footer-info {
  //  .add-checkbox input {
  //    width: 65px !important;
  //    height: 35px !important;
  //  }
  //}
}
