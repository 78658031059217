@import "../../../variables/index";


.new-card {
  width: 370px;

  &__form {
    width: 367px;

  }
  &__title {
    font-size: 16px;
    margin-bottom: 24px;
  }
  &__form-card-items {
    background: radial-gradient(56.22% 143.13% at -5.99% 106.25%, #F99B29 0%, rgba(239, 92, 39, 0) 100%), radial-gradient(137.1% 349.03% at 111.06% -5.15%, #9673FF 0%, rgba(239, 92, 39, 0) 100%), #EF5C27;;
    padding: 15px 16px 33px;
    color: $white-color;
    margin-bottom: 13px;
    border-radius: 15px;
    input {
      padding: 0px;
      border: none;
      width: 100%;
      border-radius: 15px;
      height: 52px;
      text-align: center;
    }
    span {
      margin-bottom: 8px;
      display: block;
      width: 100%;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: $white-color;
    }
  }
  &__form-item {
    margin-bottom: 12px;
    &:last-child {
      margin-bottom: 0;
      margin-top: 12px;
    }
  }
  &__form-top {
    position: relative;
    input {
      padding: 0px;
      height: 52px;
      border-radius: 15px;
      text-align: center;
    }
  }
  &__form-item-card-icon {
    position: absolute;
    margin-top: 19px;
    margin-left: 15px;
  }

  &__form-bottom {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    &-help {
      display: inline-block;
      width: 30px;
      height: 30px;
      position: absolute;
      right: 0;
      top: 34px;
    }
    label {
      width: 142px;
      margin-right: 16px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  &__form-checkbox {
    padding-left: 40px;
    font-size: 13px;
    cursor: pointer;
    margin-bottom: 20px;
  }
  &__form-checkbox-custom {
    position: absolute;
    margin-left: -40px;
    width: 30px;
    height: 30px;
    background: $black-color-c;
  }

  &__form-checkbox-input:checked + .new-card__form-checkbox-custom {
    //background: url('../../assets/check-icon.svg') 50% 50% no-repeat
    //$backgroundDesign;
  }

  &__form-btns {
    display: flex;
    flex-direction: column;
  }
}

//@media all and (-webkit-min-device-pixel-ratio:0) and (min-resolution:.001dpcm) {
//  .new-card__form-item-card-icon {
//    top: 0px;
//  }
//}

//
//@supports (-webkit-touch-callout: initial) and (not (-webkit-touch-callout: none)) {
//  .new-card__form-item-card-icon {
//    display: block;
//    top: auto;
//    bottom: 1px;
//  }
//}
//@supports (not (-webkit-touch-callout: none)) or (-webkit-touch-callout: initial) { /* Проверяем, что это не Safari */
//  .new-card__form-item-card-icon {
//    top: 0px;
//  }
//}


.errorMsg {
  color: #000;
  margin: 5px 0;
  font-size: 14px;
  border-radius: 10px;
  padding-left: 10px;
}

@media (max-width: 430px) {
  .new-card {
    width: 100%;

    &__form {
      width: 100%;
    }
  }

  .new-card__form-bottom label {
    width: 90%;
  }
  .add-my-card__card-footer-image {
    top: 50% !important;
    right: 0 !important;
    position: absolute !important;
  }

}


