@import "../../../variables/index";

.debt-invoice {
  width: $width;
  margin: 0 auto;

  &__select-category {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: $gray-color;
    padding: 6px 0 30px;
  }
}