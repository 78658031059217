@import "../../variables/index";

.categories {
  display: flex;
  flex-direction: column;
  width: 100%;

  &__items {
    margin-top: 30px;
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 12px;
  }
  .slick-slider {
    width: 985px;
    margin: 0 auto;
    padding-left: 10px;
  }

  &__item {
    display: flex;
    cursor: pointer;
  }

  &__image-bg{
    width: 45px;
    height: 45px;
    border-radius: 50%;
    padding: 11px 15px;
  }

  &__img{
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &__title {
    margin-left: 10px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    color: $black-color;
  }

  &__loading {
    //width: 100%;
    //margin: 20px 100px 0 0;
    //display: flex;
    //align-items: flex-start;
    //flex-wrap: wrap;

    margin-top: 30px;
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 12px;
  }
}

@media (max-width: 780px) {
  .categories__items {
    margin-top: 15px;
  }

}

@media (max-width: 630px) {
  .categories__items {
    grid-template-columns: auto auto;
  }

  .categories__loading {
    grid-template-columns: auto auto;
  }
}

@media (max-width: 400px) {
  .categories__items {
    margin-top: 20px;
    gap: 12px;
    flex-direction: column;
    display: flex;
  }

  .categories__loading {
    margin-top: 30px;
    gap: 12px;
    flex-direction: column;
    display: flex;
  }
}