@import "./variables/index.scss";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Montserrat, sans-serif !important;
  //color: $black-color;
}

html,
body {
  height: 100%;
}

.home-main {
  //width: 1140px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  // overflow: hidden;
  min-height: 100vh;
  position: relative;

}

.app-header {
  margin: 0 auto;
  width: 60%;

  &__image {
    display: flex;
    margin: 0 auto;
    padding-bottom: 10px;
  }

  &__payment {
    text-align: center;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 27px;
    color: #FFFFFF;
    padding-bottom: 14px;
  }

  &__recept {
    text-align: center;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #FFFFFF;
    padding-bottom: 19px;
  }
}

.token-modal {
  padding: 20px;
  display: flex;
  flex-direction: column;
  width: 600px;

  &__text {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
  }
}

.button-wrap-token {
  .custom-button {
    width: 100%;
    display: flex;
  }
}

.container {
  max-width: $width;
  margin: 0 auto;
}

.button-wrap-token {
  width: 100%;
}

.token-modal__text {
  font-size: 18px;
}

.token-modal__close-icon {
  width: 20px;
  height: 20px;
}

.token-modal__close {
  cursor: pointer;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.token-modal__header {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.token-modal__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

@media (max-width: 900px) {
  .home-main {
    .token-modal {
      width: 100%;
    }
    .modal__content.active {
      width: 60%;
      //width: 29%;
    }
  }

  .button-wrap-token {
    .custom-button {
      width: 100%;
    }
  }
}
.head-banner {
  display: flex;
}
.head-banner900 {
  display: none;
}

@media (max-width: 900px) {
  .head-banner900 {
    display: flex;
  }
  .head-banner {
    display: none;
  }
}

@media (max-width: 650px) {
  .button-wrap-token {
    .custom-button {
      min-width: 100% !important;
      padding: 10px 100px !important;
    }
  }

  .bonus-modal__btn {
    .custom-button {
      min-width: 50px !important;
    }
  }

  //.home-main {
  //  .token-modal {
  //    width: 100%;
  //  }
  //  .modal__content.active {
  //    width: 60%;
  //    height: 100vh;
  //    overflow: scroll;
  //    //width: 29%;
  //  }
  //}

  .token-modal-invilid {
    width: 100%;

    .modal__content.active {
        width: 90%;
    }
  }

  .token-modal__text {
    font-size: 14px;
    padding: 0 20px 0 0;
  }

  .token-modal__close {
    cursor: pointer;
    display: flex;
    align-self: self-start;
  }
}

@media (max-width: 570px){
  .bonus-modal__btn {
    .custom-button {
      min-width: 50px !important;
    }
  }

  .token-modal-invilid {
    width: 100%;

    .modal__content.active {
      width: 90%;
    }
  }

  //.home-main {
  //  .token-modal {
  //    width: 100%;
  //  }
  //  .modal__content.active {
  //    width: 70%;
  //    height: 100vh;
  //    overflow: scroll;
  //    //width: 29%;
  //  }
  //}
}

@media (max-width: 500px) {
  .home-main {
    .token-modal {
      width: 100%;
    }
    .modal__content.active {
      width: 95%;
    }
  }
}

@media (max-width: 450px) {
  .header-wrapper-head {
    .modal__content.active {
      width: 90%;
    }
  }

  .home-main {
    .token-modal {
      width: 100%;
    }
    .modal__content.active {
      width: 95%;
    }
  }
}

@media (max-width: 400px) {
  .token-modal__close-icon {
    width: 18px;
    height: 18px;
  }
  .token-modal {
    padding: 0
  }
  .token-modal__text {
    text-align: center;
  }

}
