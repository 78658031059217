@import "../../variables/index";

.not-found {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  margin: 50px auto;
  color: #EF5C27;
  &__link {
    margin-top: 10px;
    color: $textColor;
  }
  h1 {
    font-size: 50px;
  }
}
