@import "../../../variables/index";

.bonuses {
  max-width: $width;
  margin: 25px auto;
  flex-grow: 1;
  padding: 0 20px;
  width: 100%;

  &__btn {
    position: relative;
  }

  &__title {
    display: flex;

    &-text {
      font-weight: 600;
      font-size: 26px;
      line-height: 32px;
      color: $black-color;
      margin-right: 10px;
    }

    img {
      cursor: pointer;
    }
  }

  &__wrap {
    display: flex;
    justify-content: space-between;
    margin-top: 25px;
    width: 100%;

    &-left {
      width: 66%;

      &-banner {
        width: 100%;
        height: 100px;
        display: flex;
        justify-content: space-between;
        border-radius: 15px;
        background:
                radial-gradient(56.22% 143.13% at -5.99% 106.25%, #F99B29 0%, rgba(239, 92, 39, 0) 200%),
                radial-gradient(137.1% 349.03% at 111.06% -5.15%, #9673FF 0%, rgba(239, 92, 39, 0) 100%),
                linear-gradient(0deg, #EF5C27, #EF5C27), #FBFBFB;

        &-text {
          font-weight: 600;
          font-size: 24px;
          line-height: 29px;
          //padding: 35px 0 35px 30px;
          display: flex;
          align-self: center;
          justify-content: center;
          color: $white-color;
          width: 50%;
        }

        &-price {
          display: flex;
          width: 50%;
          justify-content: end;

          &-picture {
            display: flex;
            width: 50%;

            img {
              display: flex;
              margin-top: 20px;
              opacity: 0.1;
            }
          }

          &-total {
            display: flex;
            flex-direction: column;
            width: 50%;
            align-self: center;
            //padding: 26px 30px 26px 0;

            &-text {
              font-weight: 600;
              font-size: 28px;
              line-height: 100%;
              color: $white-color;
              align-self: center;
            }
            &-price {
              display: flex;
              align-self: center;

              &-txt {
                font-weight: 600;
                font-size: 17px;
                line-height: 100%;
                color: $white-color;
              }
              &-azn {
                font-weight: 500;
                font-size: 14px;
                line-height: 100%;
                color: $white-color;
                align-self: self-end;
              }
            }
          }
        }
      }

      &-title {
        margin: 25px 0;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        color: $black-color;
      }

      &-card {
        //display: grid;
        //grid-template-columns: repeat(3, 1fr);
        //grid-template-rows: repeat(2, 1fr);
        //grid-column-gap: 13px;
        //grid-row-gap: 20px;

        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        //width: 100%;
        margin-bottom: 20px;


        position: relative;
        max-height:528px;
        overflow: hidden;

        //width: 100%;


        &::before {
          content:'';
          background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 71.35%);;
          position:absolute;
          bottom:0;
          width:100%;
          height:218px;
        }

        &-skeleton {
          min-width: 165px;
          width: 31%;
          //height: 321px;
          border-radius: 15px;
          flex-direction: column;
          cursor: pointer;
          text-decoration: none;
          margin-bottom: 10px;
          display: flex;
          padding-bottom: 10px;
        }

        &-item {
          min-width: 165px;
          width: 31%;
          //height: 321px;
          border-radius: 15px;
          border: 1px solid #F1F1F1;
          flex-direction: column;
          cursor: pointer;
          text-decoration: none;
          margin-bottom: 10px;
          display: flex;
          padding-bottom: 10px;

          &-picture {
            margin: 15px 10px 10px 10px;
            width: 91%;

            &-img {
              width: 100%;
              height: 100%;
            }
          }

          &-title {
            margin: 15px 15px 10px 15px;
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            color: $black-color;
            text-align: center;
            flex-grow: 1;
          }

          &-price {
            display: flex;
            justify-content: center;


            &-count {
              font-weight: 600;
              font-size: 18px;
              line-height: 145.4%;
              color: $violet-color;
              padding-right: 5px;
              align-self: center;
            }
          }

          &-total {
            display: flex;
            justify-content: center;

            &-price {
              font-weight: 600;
              font-size: 18px;
              line-height: 145.4%;
              color: $black-color;
            }

            &-azn {
              display: flex;
              align-self: center;
            }
          }
        }
      }

      &-btn {
        display: flex;
        justify-content: center;
        text-decoration: none;
        position: absolute;
        left: 28%;
        bottom: 25%;
      }
    }

    &-right {
      width: 33%;
    }
  }
}

.bonus-modal {
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 367px;

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  &__header-text {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: $black-color;
  }

  &__header-icon {
    width: 24px;
    height: 24px;

    img{
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    margin-bottom: 33px;
  }

  &__body-title {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: $black-color;
    margin-bottom: 10px;
  }

  &__body-content {

  }

  &__content-title, &__content-text {
    font-weight: 500;
    font-size: 14px;
    line-height: 120%;
    color: #888888;
  }

  &__content-title {
    margin-bottom: 10px;
  }

  &__bonus-box {
    margin-top: 25px;
    display: flex;
    flex-direction: column;

  }

  &__bonus-item {
    display: flex;
    justify-content: space-between;
    padding: 12px;
    &:nth-last-child(2n) {
      background-color: #F1F1F1;
    }
  }

  &__bonus-price {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: $black-color;
  }

  &__bonus-convert {
    display: flex;
  }

  &__bonus-count {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #9473D6;
    margin-right: 5px;
  }

  &__bonus-icon {
    width: 16px;
    height: 16px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__btn {
    text-decoration: none;
  }

  &__footer {
    display: flex;
    justify-content: center;

    .custom-button {
      padding: 14px !important;
      text-align: center !important;
    }
  }
}

.bonuses__wrap-left-card-skeleton-big {
  display: none;
}

@media (max-width: 980px) {

  .bonuses {
    border-radius: 25px 25px 0 0;

    background: white;
    padding: 0 10px 10px;
  }
  .bonuses__wrap {
    display: flex;
    flex-direction: column;
  }
  .bonuses__wrap-left {
    width: 100%;
    padding-bottom: 40px;
    border-bottom: 1px solid #F1F1F1;
  }

  .bonuses__wrap-right {
    width: 100%;
    //margin-top: 40px;
  }

  .debt__body-right-search {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .debt__body-right-search-head {
    align-self: center;
    width: 30%;
  }
  .bonuses__wrap-left-card-item {
    max-height: 363px;
    display: flex;
    //border: none;
  }
  .debt__body-right-search-wrap {
    width: 60%;
  }
}

@media (max-width: 900px) {
  .bonuses {
    padding-top: 20px;
    margin-top: -22px;
  }
  .bonus-modal {
    width: 100% !important;

  }

  .bonus-modal__body {
    height: 500px !important;
    overflow: auto;
  }

}


@media (max-width: 720px) {
  .bonuses {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (max-width: 650px){
  .bonuses__wrap-left-banner {
    width: 99%;
  }
  .bonuses__title-text {
    font-size: 24px;
  }
}


@media (max-width: 620px){
  .bonuses__wrap-left-card-item{
    width: 48%;
  }
}

@media (max-width: 610px) {
  .bonuses__wrap-left-banner-text {
    font-size: 20px;
    padding-left: 10px;
  }
  .bonuses__wrap-left-banner-price-total-text {
    font-size: 22px;
  }

  .bonuses__wrap-left-card-item-picture {
    margin: 15px 5px 10px 8px;
  }
  .debt__body-right-search-head {
    font-size: 16px;
  }
  .search {
    padding: 7px 10px;
  }
  .search__input {
    font-size: 14px;
  }
  .bonuses__wrap-left-btn {
    left: 20%;
  }
}

@media (max-width: 550px) {
  .debt__body-right-search-head {
    font-size: 14px;
  }
  .search__input {
    font-size: 12px;
  }
}

@media (max-width: 520px) {
  .bonuses__wrap-left-btn {
    //left: 11%;
    //right: 1%;
    right: 20%;
  }

  .bonuses__wrap-left-card-skeleton-big {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .bonuses__wrap-left-card-skeleton {
    display: none;
  }

  .bonuses__btn {
    .custom-button {
      min-width: 300px !important;
      display: flex;
    }
  }
  .bonuses__wrap-left-title {
    margin: 25px 0 10px 0;
  }

  .bonuses__wrap-left-card-item {
    //max-height: 385px;
    display: flex;
    margin: 10px auto;
    width: 375px;
    border: 1px solid #F1F1F1;
  }

}

@media (max-width: 500px) {
  .bonuses__wrap-left-btn {
    left: 16%;
  }
}

@media (max-width: 450px) {
  .bonuses__wrap-left-btn {
    //left: 8%;
    left: 20%;
  }
}

@media (max-width: 420px) {
  .bonuses__wrap-left-card-item-picture {
    margin: 15px 5px 10px 5px;
  }
  .bonuses__wrap-left-btn {
    .custom-button {
      width: 220px;
    }
  }
}

@media (max-width: 400px) {
  .bonuses__wrap-left-btn {
    //left: 3%;
    left: 21%;
  }

  .bonuses__wrap-left-banner-text {
    font-size: 16px;
    padding-left: 10px;
  }
  .bonuses__wrap-left-banner-price-total-text {
    font-size: 18px;
  }

  .bonuses__btn {
    .custom-button {
      //min-width: 250px !important;
      display: flex;
    }
  }

}

@media (max-width: 390px) {
  .bonuses__wrap-left-btn {
    //left: 11%;
    //right: 1%;
    right: 22%;
  }

  .bonuses__btn {
    .custom-button {
      min-width: 300px !important;
      display: flex;
    }
  }
  .bonuses__wrap-left-title {
    margin: 25px 0 10px 0;
  }

  .bonuses__wrap-left-card-item {
    max-height: 385px;
    display: flex;
    margin: 10px auto;
    width: 100%;
  }

}

@media (max-height: 800px) {
  .bonus-modal__body {
    height: 400px;
    overflow: auto;
  }
}
