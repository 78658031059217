@import "../../variables/index";

.category__title {
  font-weight: 600;
  font-size: 26px;
  line-height: 32px;
  color: #474747;
  margin-bottom: 30px;
}

.services {
  display: flex;
  flex-direction: column;
  margin-right: -20px;
  
  &__loading {
    margin: 0 auto;
    padding: 50px 0;
  }

  &__empty-services {
    margin: 0 auto;
    padding: 50px 0;
    font-size: 20px;
    color: $black-color;
  }

  &__content {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    padding-bottom: 20px;
  }

  &__service {
    display: flex;
    width: calc((100% / 3) - 20px);
    text-decoration: none;
    margin-right: 20px;
    margin-bottom: 20px;
    cursor: pointer;

    @media only screen and (max-width: 400px) {
      width: calc((100% / 2) - 20px);
      text-decoration: none;
      margin-right: 20px;
      margin-bottom: 20px;
    }
  }

  &__service-image {
    width: 48px;
    height: 48px;
    display: flex;
  }

  .service-content__templates {
    display: flex;
    flex-direction: column;
    border-top: 1px solid rgba(241, 241, 241, 1);
    padding-top: 40px;
    margin-top: 20px;
    width: 100%;

    &-head {
      display: flex;
      margin-bottom: 30px;

      &-temp {
        padding-right: 25px;
        font-size: 18px;
      }

      &-addTemp {
        display: flex;
        align-items: center;

        img {
          padding-right: 5px;
        }

        span {
          color: rgba(239, 92, 39, 1);
          font-size: 14px;
        }
      }
    }

    &-body {
      padding-right: 25px;
      position: relative;

      .modal-service-code__delete-wrap {
        width: 311px !important;
      }

      .favorites__add-templates-wrap {
        width: 100%;
        justify-content: flex-start;
      }

      .favorites__add-templates-wrap-add {
        width: 100%;
      }

      .accordion__wrap-text {
        width: 44%;
      }

      .accordion {
        display: flex;
        align-items: center;
      }

      .accordion__wrap-button-add {
        text-decoration: none;
        padding-left: 10px;
      }

      .css-1c35hjw-MuiPaper-root-MuiAccordion-root {
        box-shadow: none;
        border-bottom: 1px solid rgba(241, 241, 241, 1);
      }

      .modal-service-code__delete-wrap {
        width: 367px;
      }

      .accordion__wrap-dots {
        display: flex;
      }

      .css-yw020d-MuiAccordionSummary-expandIconWrapper {
        margin-bottom: 0;
      }

      .accordion__wrap-text {
        width: 44% !important;
        display: flex;
        flex-direction: column;
      }

      .delete-temp-modal {
        border: 1px solid red;
        height: 144px;
        width: 367px;
      }

      &-acarWrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        &-wrap {
          display: flex;
          align-items: center;
        }

        &-serImg {
          height: 45px;
          width: 45px;
          border: 1px solid;
          border-radius: 50%;
        }

        &-btn {
          padding-left: 8px;
        }

        &-serv {
          display: flex;
          flex-direction: column;
          padding-left: 10px;

          &-catInfo {
            color: rgba(71, 71, 71, 1);
            font-size: 14px;
            padding-bottom: 4px;
          }

          &-catName {
            font-size: 12px;
            color: rgba(136, 136, 136, 1);
          }
        }

        &-amount {
          font-size: 16px;
          color: rgba(71, 71, 71, 1);
          padding-left: 70px;
          padding-right: 16px;
        }
      }
    }
  }

  &__icon {
    width: 48px;
    object-fit: contain;
    margin: 0 auto;
  }

  &__service-title {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: $black-color;
    text-align: left;
    display: flex;
    align-items: center;
    padding-left: 10px;
    overflow: hidden;

    @media only screen and (max-width: 850px) {
      font-size: 12px;
      word-wrap: break-word
    }

    @media only screen and (max-width: 768px) {
      font-size: 16px;
      word-wrap: break-word
    }
  }

  &__more-btn {
    display: flex;
    align-items: center;
    cursor: pointer;
    align-self: center;
  }

  &__btn-image {
    width: 48px;
    height: 38px;
    margin-right: 10px;
  }

  &__btn-title {
    font-size: 14px;
    color: $orange-color;
  }

  .category-content {
    margin-top: 50px;
    line-height: 1.5;

    h2 {
      margin: 20px 0;
      font-weight: 500;
    }

    ol, ul {
      margin-top: 0;
      margin-bottom: 10px;
    }

    li {
      margin-left: 20px;
    }
  }
}

@media only screen and (max-width: 850px) {
  .services__service-image {
    display: flex;
  }
  .services {
    margin: 0;
  }
  .services__icon {
    object-fit: contain;
  }
  .services__service-title {
    font-size: 14px;
    word-wrap: break-word;
    margin: 0;
  }

}

@media (min-width: 650px) {
  .favorites {
    display: none;
  }
}

@media (max-width: 615px) {
  .services__content {
    justify-content: space-between;
  }
  .services__service {
    width: calc(45% - 10px);
  }
}

@media only screen and (max-width: 520px) {
  .services__icon {
    width: 40px;
    height: 40px;
  }
  .services__service-image {
    width: auto;
    height: auto;
  }
  .services__service-title {
    padding-left: 2px;
  }
  .services {
    margin: 0;
  }
}

@media only screen and (max-width: 400px) {
  .services__service-image {
    display: flex;
  }
  .services__service-title {
    font-size: 12px;
    word-wrap: break-word;
    margin: 0;
  }

  .services__btn-title {
    font-size: 12px;
  }
  .services__btn-image {
    width: 38px;
    height: 38px;
    margin: 0;
  }
}