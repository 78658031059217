@import "../../variables/index";

.more-details {
  position: relative;
  background: $mainBackground;
  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    //background: $mainBackground;
    color: $white-color;
    padding-bottom: 30px;
  }

  .logoCenter {
    //width: 250px !important;
  }

  .hamburger-menu {
    top: 10px !important;
  }

  &__title {
    font-weight: 700;
    padding-top: 15px;
  }

  &__name {
    font-size: 26px;
    line-height: 32px;
    text-align: center;
  }

  &__content {
    padding: 10px 10px 0 10px;
    margin-top: -20px;
    width: 100%;
    background: white;
    border-radius: 20px 20px 0 0;

    .sidebar {
      box-shadow: none;
      &__content-item:nth-child(1), &__content-item:nth-child(2), &__content-item:nth-child(3){
        display: none;

      }
    }

    .sidebar__header {
      padding-bottom: 20px;

      &-link {
        width: 32%;
      }

      &-item {
        display: flex;
        align-items: center;
        padding: 20px 15px 20px 12px;
      }

      &-image {
        margin-bottom: 0;
        margin-right: 12px;
        height: 30px;
        width: 30px;
      }

      &-item {
        display: flex;
        position: relative;
      }

      &-title {
        font-size: 14px;
        padding-bottom: 5px;
      }

      &-subtitle {
        font-size: 18px;
        padding-top: 5px;
        span {
          font-size: 18px;
        }
      }

      &-currency-box {
        position: absolute;
        flex-direction: row;
        top: 20px;
        right: 6%;
      }
      &-currency {
        padding-right: 5px;
        font-size: 13px;
        line-height: 100%;
      }
    }
    .sidebar__content{
      &-title{
        font-size: 14px;
        line-height: 17px;
      }
    }
  }

}

@media only screen and (max-width: 768px) {
  .more-details {
    &__content{
      padding: 15px 10px 20px 10px;
    }
    &__header {
      //flex-direction: row;
    }
    &__title{
      display: none;
    }
    &__name{
      font-size: 18px;
    }
    .header__auth-setting {
      font-size: 14px;
      text-align: center;
      width: 100%;
      justify-content: center;
    }
    .sidebar{
      &__header{
        &-item{
          flex-direction: column;
          align-items: flex-start ;
          padding: 15px 0 9px 12px ;
        }
        &-image{
          margin-bottom: 15px ;
        }
        &-title{
          font-size: 14px;

        }
        &-subtitle{
          font-size: 14px ;
          span{
            font-size: 14px ;
          }
        }
        &-currency-box{
          top: 15px;
          flex-direction: column ;
        }
      }

    }

  }
  .settings{
    &__avatar-box{
      margin: 15px 15px 0 15px;
      //height: 67px;
      //width: 67px;
    }
  }
  .custom-button{
    padding: 14px 40px;
  }
}

@media (max-width: 450px) {
  .more-details {
    .modal__content .active {
      width: 90%;
    }
  }
}

