.wallet-details {
  max-width: 1140px;
  margin: 0 auto;
  display: flex;
  flex-grow: 1;
  width: 100%;
  justify-content: space-between;

  padding: 10px 0 80px;

  &-body {
    width: 66%;

    &__title {
      padding: 25px 0 30px 0;
      font-size: 26px;
      font-weight: 600;
    }

    &__sideMenu {
      width: 33%;
    }
  }

  &__content {

    &-banner {
      width: 539px;
      height: 35px;
      border-radius: 15px;
      background-color: rgba(255, 246, 228, 1);

      &-text {
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        color: #474747;
        padding: 10px 15px;
        width: 100%;
      }
    }


    &-wallets {
      display: flex;
      justify-content: space-between;
      padding: 20px 0px;
      width: 100%;
    }

    &-inner {
      width: 368px;
      height: 94px;
      display: flex;
      justify-content: space-between;
      line-height: 1.7;
      padding-left: 20px;
      margin-right: 20px;
      color: white;

      &-count {
        display: flex;
        flex-direction: column;
        align-self: center;
      }

      &-picture {
        margin-right: 30px;

        img {
          opacity: 0.2;
          margin-top: 16.83px;
        }
      }




    }

    &-empty {
      width: 368px;
      height: 94px;
      display: flex;
      align-content: center;
      justify-content: center;
      align-items: center;
      border: 1px solid #EF5C27;
      border-radius: 15px;
      cursor: pointer;

      &-picture {
        display: flex;
        flex-direction: column;

        img {
          width: 20px;
          height: 20px;
          display: flex;
          margin: 0 auto;

        }

        &-text {
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          color: #EF5C27;
          padding-top: 10px;
        }
      }
    }


  }
}

.bg-image {
  background-image: url("../../../img/bg-ewallet.svg");
}

@media (max-width: 900px) {
  .wallet-details {
    flex-direction: column;
    padding: 0 0 80px;

    &-body {
      width: 100%;
      margin-top: 10px;

      &__sideMenu {
        margin-top: 20px;
        width: 100%;
      }
    }

    .aside-menu {
      width: 100%;
    }


    .wallet-details__content-banner{
      width: 100%;
      height: 50px;
      text-align: center;
    }

    &__content {
      &-wallets {
        flex-direction: column;
      }

      &-empty {
        width: 100%;
      }
    }
  }
  .auto-payments__header-create-payment bg-image wallet-details__content-inner{
    width: 43.3%;
  }
}

@media (max-width: 380px){
  //.auto-payments__header-create-payment .bg-image .wallet-details__content-inner {
  //  width: 100%;
  //  border-radius: 10px;
  //}

  .bg-image {
    background-image: url("../../../img/bg-ewallet.svg");
    width: 100%;
    border-radius: 10px;
  }
}