@import "../../../variables/index";

.catalogue {
  max-width: $width;
  margin: 0 auto;
  flex-grow: 1;
  width: 100%;
  &-title {
    display: flex;
  }
  &-body {
    margin-bottom: 10px;
    &__title {
      padding-bottom: 30px;
      font-size: 26px;
      font-weight: 600;
      padding-right: 15px;
    }
  }
  &__item {
    max-width: 270px;
    margin: 10px 20px 30px 0;
    background: $white-color;
    border-radius: 15px;
    border: 1px solid $white-color-border;
    cursor: pointer;
    text-decoration: none;
    &-name {
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: #474747;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      margin: 10px 0 15px;
      text-align: center;
      flex-grow: 1;
      height: 35px;
    }
  }
}

.catalogue-blocks {
  &__wrap {
    width: 100%;
  }
  &__button {
    display: flex;
    justify-content: center;
    margin: 30px 0px;
  }
}

.categories-slider {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
  width: 100%;
  overflow: hidden;
  &__item {
    background: $white-color-border;
    border-radius: 15px;
    padding: 10px;
    text-align: center;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: $black-color;
    margin-right: 5px;
    cursor: pointer;
  }
  &__item--active {
    background: $orange-color;
    color: $white-color;
  }
}

.color {
  color: $orange-color;
}

.skeleton-wrap-catalogue {
  display: flex;
  width: 30% !important;
}

.skeleton-wrap-footer {
  display: flex;
  width: 100% !important;
  flex-wrap: wrap;
  justify-content: space-between;
  &-wrap {
    display: flex;
  }
}

@media (max-width: 900px) {
  .catalogue {
    border-radius: 25px 25px 0 0;
    margin-top: -22px;
    background: white;
  }
  .ads-blocks__body {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
  }
}

@media (max-width: 885px) {
  .catalogue-body {
    padding-right: 0;
  }
  .ads-blocks__catalogue {
    .ads-blocks__body {
      .ads-blocks__item-wrapper {
        padding: 10px;
        width: 46% !important;
      }
      .catalogue__item {
        max-width: none;
      }
    }
  }
}

@media (max-width: 800px) {
  .skeleton-wrap-catalogue {
    display: flex;
    width: 33% !important;
  }
}

@media (max-width: 790px) {
  .skeleton-wrap-footer {
    display: flex;
    width: 100% !important;
    flex-wrap: wrap;
    justify-content: space-around;
    &-wrap {
      display: flex;
      width: 35% !important;
    }
  }
}

@media (max-width: 765px) {
  .ads-blocks__bonus {
    display: none;
  }
}

@media (max-width: 670px) {
  .ads-blocks__body {
    .catalogue__item {
      margin: 0 0 10px 0;
    }
  }
}

@media (max-width: 630px) {
  .skeleton-wrap-catalogue {
    display: flex;
    width: 47% !important;
  }
}

@media (max-width: 580px) {
  .image-wrap {
    width: 185px;
    height: 189px;
  }
  .ads-blocks__body {
    .catalogue__item {
      margin-bottom: 10px;
    }
  }
  .catalogue__item {
    width: 200px;
  }
  .ads-blocks__image {
    width: 185px !important;
    height: auto !important;
  }
}

@media (max-width: 570px) {
  .skeleton-wrap-footer {
    display: flex;
    width: 100% !important;
    flex-wrap: wrap;
    justify-content: space-around;
    &-wrap {
      display: flex;
      width: 47% !important;
    }
  }
}

@media (max-width: 545px) {
  .skeleton-wrap-footer {
    display: flex;
    width: 100% !important;
    flex-wrap: wrap;
    justify-content: space-around;
    &-wrap {
      display: flex;
      width: 54% !important;
    }
  }
}

@media (max-width: 520px) {
  .catalogue-body {
    padding-right: 0;
  }
  .ads-blocks__catalogue {
    margin: 0;
    .ads-blocks__body {
      .ads-blocks__item-wrapper {
        padding: 10px;
        width: 375px !important;
        margin: 0 auto;
      }
      .catalogue__item {
        max-width: none;
        margin-bottom: 20px;
      }
    }
  }
  .ads-blocks__image {
    width: 100% !important;
    height: auto !important;
  }
}


@media (max-width: 480px) {
  .ads-blocks__body {
    .ads-blocks__item-wrapper {
      padding: 10px;
    }
  }
  .catalogue__item {
    width: 180px;
  }
  .ads-blocks__image {
    width: 100%;
    height: auto;
  }
  .ads-blocks__bonus {
    font-size: 20px;
  }
  .catalogue-body__title {
    font-size: 20px;
  }
}

@media (max-width: 450px) {
  .skeleton-wrap-catalogue {
    display: flex;
    width: 69% !important;
    margin: 0 auto;
  }
  .skeleton-wrap-footer {
    display: flex;
    width: 100% !important;
    flex-wrap: wrap;
    justify-content: space-around;
    &-wrap {
      display: flex;
      width: 64% !important;
    }
  }
}

@media (max-width: 440px) {
  .ads-blocks__body {
    display: flex;
    .catalogue__item {
      margin: 0;
    }
  }
  .catalogue__item {
    width: 160px;
  }
  .ads-blocks__image {
    width: 100%;
    height: auto;
  }
}

@media (max-width: 410px) {
  .ads-blocks__catalogue {
    .ads-blocks__body {
      .ads-blocks__item-wrapper {
        padding: 10px;
        margin: 0 0 10px 0;
        width: 100% !important;
      }
      .catalogue__item {
        max-width: none;
      }
    }
  }
}

@media (max-width: 360px) {
  .ads-blocks__body {
    .catalogue__item {
      width: 100% !important;
      margin: 0 auto !important;
      margin-bottom: 15px !important;
    }
  }
  .skeleton-wrap-footer {
    display: flex;
    width: 100% !important;
    flex-wrap: wrap;
    justify-content: space-around;
    &-wrap {
      display: flex;
      width: 82% !important;
    }
  }
  .ads-blocks__body {
    .ads-blocks__item-wrapper {
      padding: 10px;
      .catalogue__item {
        width: 100% !important;
      }
    }
  }
  .catalogue-body__title {
    font-size: 18px;
  }
  .ads-blocks__bonus {
    font-size: 16px;
  }
}

@media (max-width: 330px) {
  .ads-blocks__body {
    display: flex;
    justify-content: space-between;
    .catalogue__item {
      width: 150px;
    }
    .catalogue__item {
      width: 125px;
    }
  }
  .ads-blocks__image {
    width: 100%;
    height: auto;
  }
}