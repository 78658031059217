@import "../../variables/index";

.ads-blocks {
  max-width: $width;
  margin: 0 auto;

  &-wrapper {
    background: $orange-color;

    &-loading {
      display: flex;
      justify-content: center;
      width: 100%;
      align-items: center;
      background: white;
      padding: 10px 0 50px 0;
    }

  }

  &__catalogue {
    display: flex;
    flex-direction: column;
    margin-right: -20px;
  }

  &__body {
    //display: flex;
    //flex-wrap: wrap;
    //align-items: flex-start;
    //justify-content: space-between;


    display: flex;
    justify-content: flex-start;
    //align-items: flex-start;
    flex-wrap: wrap;
    padding-bottom: 20px;
    margin-right: -20px;



    &-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;

    }
  }

  &__item {
    max-width: 270px;
    //height: 386px;
    margin: 0 20px 30px 0;

    background: #ffffff;
    border-radius: 15px;
    text-decoration: none;


    &-wrapper {

      //width: calc(33.3333333333% - 20px);
      //text-decoration: none;
      //margin-right: 20px;
      //margin-bottom: 20px;

      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 15px 30px;
      //height: 100%;
    }
  }
  &__item:last-child{
    margin-right: 0;
  }
  &__title {
    font-size: 26px;
    font-weight: 600;
    line-height: 32px;
    text-align: left;
    color: $white-color;
    padding: 50px 0 32px 0;

  }

  &__image {
    width: 232px;
    height: 225px;
  }

  &__wrap {
    margin-left: 20px;
  }

  &__name {
    margin: 10px 0 15px;
    flex-grow: 1;
  }

  &__bonus {
    font-size: 26px;
    color: $violet-color;
    font-weight: 600;

    &-icon {
      padding-left: 5px;
    }
  }

  &__price {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: 700;
    color: $black-color;

    &__currency {
      font-family: Montserrat, sans-serif;
      font-size: 13px;
      font-weight: 400;
      padding-left: 3px;
      margin-top: 4px
    }
  }
}

.bonuses-list {
  background-color: $orange-color;
  //padding: 48px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__header {
    margin-bottom: 30px;
  }

  &__title {
    font-weight: 600;
    font-size: 26px;
    line-height: 32px;
    color: $white-color;
    padding-top: 20px;
  }

  &__content {
    //width: 100%;
    //max-width: $maxWidth;
    max-width: 1180px;
    padding: 0 20px;
    //display: flex;
  }

  &__slider {
    display: none;
  }
  &__list {
    display: flex;
    width: 100%;
    justify-content: space-between;
    //gap: 20px;
    //flex-wrap: wrap;
  }

  &__bonus {
    display: flex;
    flex-direction: column;
    padding: 15px;
    background: $white-color;
    border-radius: 15px;
  }

  //&__wrapper {
  //  text-decoration: none !important;
  //}

  &__bonus-image {
    //width: 233px;
    width: 100%;
    //height: 224px;
    margin-bottom: 24px;
    flex-grow: 1;
  }

  &__icon {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &__bonus-title {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: $black-color;
    margin-bottom: 13px;
    max-width: 238px;
    overflow: hidden;
    text-overflow: ellipsis;
    //flex-grow: 1;
  }

  &__count-box{
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__count {
    font-weight: 600;
    font-size: 22px;
    line-height: 145.4%;
    text-transform: uppercase;
    color: $violet-color;
    margin-right: 6px;
    text-align: center;
  }

  &__count-image {
    width: 17px;
    height: 17px;
    align-self: center;
  }

  &__price {
    font-weight: 600;
    font-size: 22px;
    line-height: 145.4%;
    text-transform: uppercase;
    text-align: center;
    color: $black-color;

    span {
      font-size: 20px;
    }
  }

  &__footer {
    margin-top: 20px;

    .custom-button {
      margin: 0;
    }
  }
}

.ads-blocks-skeleton {
  display: flex;
}

.line-header-skeleton {
  display: flex;
  width: 500px;
  margin-bottom: 20px;
}

.media700-line-header-skeleton {
  display: none;
}

.media530-ads-skeleton {
  display: none;
}

.media885-ads-skeleton {
  display: none;
}
.media620-ads-skeleton {
  display: none;
}

.media470-ads-skeleton {
  display: none;
}

.media420-ads-skeleton {
  display: none;
}

.media390-ads-skeleton {
  display: none;
}

.media350-ads-skeleton {
  display: none;
}

.media400-line-header-skeleton {
  display: none;
}

.media500-line-header-skeleton {
  display: none;
}

@media (max-width: 885px) {
  .bonuses-list__bonus {
    height: 378px;
  }

  .ads-blocks-skeleton {
    display: none;
  }

  .media885-ads-skeleton {
    display: flex;
  }
  .media620-ads-skeleton {
    display: none;
  }
}

@media (max-width: 850px) {
  .ads-blocks-skeleton {
    display: none;
  }

  .media400-line-header-skeleton {
    display: none;
  }
}

@media (max-width: 750px) {
  .line-header-skeleton {
    display: none;
  }
  .media500-line-header-skeleton {
    display: none;
  }
  .media700-line-header-skeleton {
    display: flex;
    width: 400px;
    margin-bottom: 20px;
  }
  .media400-line-header-skeleton {
    display: none;
  }
}

@media (max-width: 620px) {
  .bonuses-list {
    padding: 0;
  }
  .bonuses-list__title {
    padding-top: 10px !important;
  }
  .media885-ads-skeleton {
    display: none;
  }
  .media620-ads-skeleton {
    display: flex;
  }
}

@media (max-width: 530px) {
  .media885-ads-skeleton {
    display: none;
  }
  .media620-ads-skeleton {
    display: none;
  }
  .media530-ads-skeleton {
    display: flex;
  }
  .media470-ads-skeleton {
    display: none;
  }
  .media420-ads-skeleton {
    display: none;
  }
  .media390-ads-skeleton {
    display: none;
  }
  .media350-ads-skeleton {
    display: none;
  }

  .ads-blocks-skeleton {
    display: none;
  }

  .media700-line-header-skeleton {
    display: flex;
  }
  .media400-line-header-skeleton {
    display: none;
  }
}

@media (max-width: 500px) {
  .line-header-skeleton {
    display: none;
  }
  .media500-line-header-skeleton {
    display: flex;
    width: 300px;
    margin-bottom: 20px;
  }
  .media700-line-header-skeleton {
    display: none;
  }
  .media400-line-header-skeleton {
    display: none;
  }

  .bonuses-list {
    padding: 0;
  }
}

@media (max-width: 470px) {
  .media530-ads-skeleton {
    display: none;
  }
  .media470-ads-skeleton {
    display: flex;
  }
  .media420-ads-skeleton {
    display: none;
  }
  .media390-ads-skeleton {
    display: none;
  }
  .media350-ads-skeleton {
    display: none;
  }
}

@media (max-width: 420px) {
  .media530-ads-skeleton {
    display: none;
  }
  .media470-ads-skeleton {
    display: none;
  }
  .media420-ads-skeleton {
    display: flex;
  }
  .media390-ads-skeleton {
    display: none;
  }
  .media350-ads-skeleton {
    display: none;
  }
}

@media (max-width: 400px) {
  .line-header-skeleton {
    display: none;
  }
  .media500-line-header-skeleton {
    display: none;
  }
  .media700-line-header-skeleton {
    display: none;
  }
  .media400-line-header-skeleton {
    display: flex;
    width: 200px;
    margin-bottom: 20px;
  }

  //.bonuses-list {
  //  padding: 0;
  //}
}

@media (max-width: 390px) {
  .media530-ads-skeleton {
    display: none;
  }
  .media470-ads-skeleton {
    display: none;
  }
  .media420-ads-skeleton {
    display: none;
  }
  .media390-ads-skeleton {
    display: flex;
  }
  .media350-ads-skeleton {
    display: none;
  }
}

@media (max-width: 350px) {
  .media530-ads-skeleton {
    display: none;
  }
  .media470-ads-skeleton {
    display: none;
  }
  .media420-ads-skeleton {
    display: none;
  }
  .media390-ads-skeleton {
    display: none;
  }
  .media350-ads-skeleton {
    display: flex;
  }
}


.bonuses-list__slider {
  .bonuses-slider__wrapper {
    text-decoration: none !important;
  }
}

.wrap-product {
  display: flex;
  width: 24%;
  text-decoration: none;
}

.bonuses-list .slick-initialized .slick-slide {
  display: inline-table;
}

.bonuses-list .slick-slide {
  width: 270px !important;
}

.bonuses-list .slick-initialized .slick-slide{
  width: 270px !important;
  margin: 0 10px;
}

//.bonuses-list .slick-slide, .slick-active, .slick-cloned{
//  width: 270px !important;
//}
.bonuses-list .slick-slider .slick-active{
  width: 270px !important;
  //margin: 0 20px 0 20px;
}

@media screen and (max-width: 900px) {
  .bonuses-list__content {
    width: 100%;
  }
}


@media screen and (max-width: 720px) {
  .bonuses-list__content{
    padding: 0 10px;
  }
}




@media screen and (max-width: 885px) {
  .bonuses-list__slider {
    display: block;
    overflow: hidden;
    border-radius: 10px;

    .slick-arrow .slick-prev {
      display: none !important;
    }
    .slick-arrow .slick-next {
      display: none !important;
    }
  }

  .bonuses-list__list {
    display: none;
  }
}

@media (max-width: 830px) {
  .ads-blocks__body{
    justify-content: space-around;
  }
}

@media screen and (max-width:768px) {
  .bonuses-list .slick-slider .slick-list .slick-track {

    width: 3200px !important;
  }
}

@media (max-width: 500px) {
  .bonuses-list .slick-slide {
    width: 200px !important;
  }

  .bonuses-list .slick-initialized .slick-slide{
    width: 200px !important;
  }

  .bonuses-list .slick-slide, .slick-active, .slick-cloned{
    width: 200px !important;
  }
  .bonuses-list .slick-slider .slick-active {
    width: 200px !important;
    //margin: 0 20px 0 20px;
  }
}

@media (max-width: 470px) {
  .bonuses-list .slick-slide {
    width: 100px !important;
  }

  .bonuses-list .slick-initialized .slick-slide{
    width: 200px !important;
  }

  .bonuses-list .slick-slide, .slick-active, .slick-cloned{
    width: 200px !important;
  }
  .bonuses-list .slick-slider .slick-active{
    width: 200px !important;
  }
}

@media screen and (max-width: 360px) {
  .bonuses-list__bonus {
    width: 217px;
  }

  .bonuses-list__footer {
    .custom-button {
      min-width: 100% !important;
    }
  }

  .bonuses-list__bonus-image {
    width: 187px;
    height: 187px;
  }

  .bonuses-list__bonus-title {
    font-size: 14px;
    line-height: 17px;
    max-width: 197px;
  }

  .bonuses-list__count {
    font-size: 18px;
  }
}

@media screen and (max-width: 360px) {
  .bonuses-list .slick-initialized .slick-slide {
    width: 200px !important;
  }
}

//.slick-slider {
//  width: 100%;
//  overflow: hidden;
//}
//.slick-list {
//  position: relative;
//  display: block !important;
//  width: 100%;
//}
//.slick-slide div {
//  width: auto;
//  margin-right: 10px;
//}
//.slick-slide img {
//  width: calc(100% - 10px) !important;
//}
