.header-adaptiv {
  width: 100%;
  height: 65px;
  background: radial-gradient(56.22% 143.13% at -5.99% 106.25%, #F99B29 0%,
          rgba(239, 92, 39, 0) 100%),
  radial-gradient(137.1% 349.03% at 111.06% -5.15%, #ef5c27 0%,
                  rgba(239, 92, 39, 0) 100%), #EF5C27;
  display: flex;
  justify-content: space-between;
  padding: 0 10px;

  &__title {
    display: flex;
    width: 30%;
    align-items: center;

    &-img {

    }

    &-text {
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
      color: #FFFFFF;
      padding-left: 14px;
    }
  }

  &__right {
    display: flex;
    width: 30%;
    align-items: center;
    justify-content: end;

    &-bonus {
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #FFFFFF;
      padding-right: 10px;
    }

  }
}