@import "../../../../variables/index";

.security {
  max-width: $width;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  width: 100%;
  //padding: 0 10px 80px 10px;

  &-right {
    width: 33%;
    //margin-top: 10px;
  }

  &-body {
    min-width: 66%;
    padding-right: 20px;

    &__title {
      padding: 15px 0 30px 0;
      font-size: 26px;
      font-weight: 600;
    }
  }

  &__content {
    padding-left: 0;
  }

  &__content-item {
    list-style-type: none;
    font-weight: 600;
  }
}

@media (max-width: 900px) {
  .security-right {
    display: none;
  }
  .security-body {
    width: 100%;
    padding: 10px 0 0 0;
  }
  .security {
    border-radius: 25px 25px 0 0;
    margin-top: -22px;
    background: white;
    height: 100vh;
  }
}
