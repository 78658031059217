@import "../../../variables/index";

.recurring-modal {
  &__content {
    max-width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
  }

  &__message {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: $black-color;
    width: 100%;
    text-align: center;
  }

  &__btn {
    margin-top: 20px;
    align-self: center;
    padding: 15px;
    text-align: center;
    background-color: #EF5C27;
    border-radius: 15px;
    color: #fff;
    min-width: 100px;
  }
}