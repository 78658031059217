@import "../../../variables/index";

.identification {
  max-width: $width;
  margin: 0 auto;
  flex-grow: 1;
  padding: 0 10px;
  width: 100%;

  &__item {
    text-decoration: none;
    display: flex;
    flex-direction: column;
    //align-items: center;
    border: 1px solid rgba(239, 92, 39, 0.25);
    border-radius: 15px;
    padding: 21px 5px;
    width: 175px;
    margin-right: 20px;
  }

  &__item-image {
    //margin-bottom: 8px;
    height: 38px;
    width: auto;
    max-width: 110px;
    margin: 0 auto 8px auto;
  }

  &__icon {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &__item-title {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: $black-color;
    text-align: center;
  }

  &__pass {
    padding-top: 14px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__pass-img {
    width: 120px;
    height: 120px;

    img{
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__pass-title {
    font-weight: 600;
    font-size: 26px;
    line-height: 32px;
    text-align: center;
    color: $black-color;
    margin-bottom: 10px;
  }

  &__pass-subtitle {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;

    text-align: center;
    color: $gray-color;

  }


  &__wrap {
    display: flex;
    justify-content: space-between;
    width: 100%;

    &-left {
      width: 66%;
      margin-bottom: 50px;
      font-size: 28px;
      color: $black-color;
      padding-right: 20px;
      flex-grow: 1;

      &-text {
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color:$gray-color;
      }

      &-contain {
        display: flex;
        margin-top: 30px;
        margin-right: -20px;

        &-idenWallet {
          display: flex;
          flex-direction: column;
          margin-top: 40px;

          &-head {
            font-weight: 600;
            font-size: 20px;
            line-height: 24px;
            color: $black-color;
            margin-bottom: 20px;
          }

          &-body {
            display: flex;
            margin-top: 15px ;

            &-text {
              display: flex;
              flex-direction: column;
              margin-left: 15px;

              &-head {
                font-weight: 600;
                font-size: 18px;
                line-height: 22px;
                color: $black-color;
              }

              &-headN {
                font-weight: 500;
                font-size: 14px;
                line-height: 17px;
                color: $black-color;
              }
            }
          }
        }

        &-wrapText {
          display: flex;
          flex-direction: column;
          margin-top: 40px;

          &-textHead {
            font-weight: 600;
            font-size: 20px;
            line-height: 24px;
            color: $black-color;
          }

          &-textBody {
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            color: $black-color;
            margin-top: 15px;
          }
        }

        &-item {
          border: 1px solid $rgba-orange-color;
          width: 175px;
          border-radius: 15px;
          text-decoration: none;

          &-pic {
            display: flex;
            align-content: center;
            justify-content: center;
            padding-top: 20px;
          }

          &-text {
            display: flex;
            align-content: center;
            justify-content: center;
            text-decoration: none;
            padding-bottom: 15px;
            padding-top: 5px;
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            color: $black-color;
          }
        }
      }
    }

    &-right {
      width: 33%;
    }
  }
}

@media only screen and (max-width: 900px) {
  .identification__wrap-left {
    padding-right: 0;
  }

  .identification__wrap-right {
    display: none;
  }

  .identification__item {
    padding: 10px 5px;
  }

  .identification__item-title {
    font-size: 14px;
  }
}

@media only screen and (max-width: 550px) {

  .identification__wrap-left-contain {
    flex-direction: column;
    margin-bottom: -10px;
    padding-right: 20px;

  }

  .identification__wrap-left-text {
    font-size: 14px;
  }

  .identification__item {
    flex-direction: row;
    align-items: center;
    width: 100%;
    padding: 12px 20px;
    margin-bottom: 10px;
  }

  .identification__item-image {
    margin: 0 10px 0 0;
    width: 35px;
    height: 35px;
  }

  .identification__wrap-left-contain-wrapText-textHead {
    font-size: 18px;
  }
  .identification__wrap-left-contain-idenWallet-head {
    font-size: 18px;
  }

  .identification__pass-img {
    width: 94px;
    height: 94px;
  }

  .identification__pass-title {
    font-size: 18px;
  }

  //.identification__item-title {
  //  font-size: 14px;
  //}
}

