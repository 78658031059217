@import "../../variables/index";

.search {
  width: 100%;
  background: #F1F1F1;
  border-radius: 15px;
  padding: 13px 20px;
  display: flex;
  align-items: center;

  &__search-icon,
  &__close-icon {
    border: none;
    background: none;
    cursor: pointer;
  }

  &__search-icon {
    margin-right: 20px;
  }

  &__close-icon{
    margin-left: 20px;
  }

  &__input {
    all: unset;
    width: 100%;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 17px;

    &::-webkit-input-placeholder {
      color: #888888;
    }

    &:-ms-input-placeholder {
      color: #888888;
    }

    &::placeholder {
      color: #888888;
    }
  }
}
