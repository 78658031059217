.advantages {
  margin: 0 auto;
  max-width: 1140px;
  display: flex;
  flex-grow: 1;

  &__body {
    width: 100%;
    //margin-top: 80px;
    display: flex;
    flex-wrap: wrap;
    //display: grid;
    //grid-template-columns: repeat(4, 1fr);
    //grid-template-rows: repeat(3, 1fr);
    //grid-column-gap: 70px;
    //grid-row-gap: 20px;
    //margin-bottom: 250px;
    justify-content: space-between;

    &-item {
      min-width: 25%;
      //height: 200px;
      //border: 1px solid rgba(239, 92, 39, 1);
      display: flex;
      flex-direction: column;
      margin-bottom: 77px;
      text-decoration: none;
      cursor: pointer;
      //border-radius: 64%;
      position: relative;

      &-img {
        display: flex;
        justify-content: center;

        img {
          //width: 200px;
          width: 60%;
          height: 200px;
          border-radius: 50%;
          object-fit: cover;
        }
      }

      &-text {
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        margin-top: 15px;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        color: rgba(0, 0, 0, 0.7);
      }
    }
  }
}

.advantages__modal-wrap {
  width: 600px;
}
.advantages__modal-wrap-text {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  padding: 0 10px;
  color: #474747;
}

.advantages__modal-wrap-title {
  font-weight: 600;
  font-size: 26px;
  line-height: 32px;
  color: #474747;
}

@media (max-width: 1040px) {
  .advantages__body-item {
    width: 33%;
  }
  .advantages__body-item-img {
    img {
      width: 200px;
    }
  }

}

@media (max-width: 1000px) {
  .advantages {
    max-width: 850px;
  }
}

@media (max-width: 900px) {
  .advantages__modal-wrap {
    width: unset;
  }
  .advantages__modal-wrap-text {
    height: 450px;
    overflow: scroll;
  }
}
//@media (max-width: 900px) {
//  .advantages {
//    border-radius: 25px 25px 0 0;
//    margin-top: -22px;
//    background: white;
//    //max-width: 1180px;
//  }
//  .advantages__body {
//    border: 2px solid;
//    display: flex;
//    justify-content: space-between;
//  }
//}
@media (max-width: 800px) {
 .advantages__modal-wrap-text {
   overflow: scroll;
   display: flex;
   height: 500px;
 }
}

@media (max-width: 768px) {
  .advantages {
    //max-width: 530px;
  }
}

@media (max-width: 650px) {
  .advantages__body-item {
    width: 50%;
  }
  .advantages__body-item-text {
    font-size: 14px;
  }
  .advantages__body-item-img {
    img {
      width: 150px;
      height: 150px;
    }
  }
}

@media (max-width: 600px) {
  .advantages__modal-wrap-title {
    font-size: 20px;
  }
  .advantages__modal-wrap-text {
    font-size: 14px;
  }
}

@media (max-width: 500px) {
  .advantages__body-item-text {
    font-size: 12px;
  }
  .advantages__body-item {
    width: 30%;
    margin-bottom: 30px;
  }


  .advantages__body-item-img {
    img {
      width: 100px;
      height: 100px;
    }
  }
}

@media (max-width: 320px) {
  .advantages__body-item-text {
    font-size: 10px;
  }
  .advantages__body-item {
    width: 30%;
    margin-bottom: 20px;
  }
  .advantages__body-item-img {
    img {
      width: 50px;
      height: 50px;
    }
  }
}

