@import "../../../variables/index";

.recurring-edit {
  width: 100%;

  &__content-title {
    font-weight: 600;
    font-size: 26px;
    line-height: 32px;
    color: $black-color;
    margin-bottom: 32px;
  }

  &__content-box {
    display: flex;
    flex-direction: column;
    padding-bottom: 11px;
    position: relative;
    margin-bottom: 36px;
    &::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 1px;
      background-color: $white-color-border;
    }
  }

  &__content-field {
    display: flex;
    margin-bottom: 22px;
    align-items: center;
  }

  &__content-field-title {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: $gray-color;
    min-width: 30%;
  }

  &__content-field-value {
    display: flex;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: $black-color;
    align-items: center;
  }

  &__input {
    border: none;
    outline: none;
    padding: 0 6px 0 0;
    color: #474747;
    width: fit-content;
    max-width: 150px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    margin-left: 0;
    &:disabled {
      background: transparent;
      opacity: 0.7;
    }
  }

  &__icon {
    margin-left: 10px;
    cursor: pointer;
  }

  &__period-box {
    display: flex;
    width: 100%;
  }

  &__btns {
    display: flex;
    gap: 30px;
    width: 100%;
  }

}

.btns-box {
  display: flex;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #EF5C27;
  height: fit-content;

  &__btn {
    cursor: pointer;
    margin: 0 5px;
    border-bottom: 1px dashed #EF5C27;
  }
}

@media (max-width: 900px) {
  .recurring-edit__content-box {
    width: 100%;
  }

  .recurring-edit__content-field {
    width: 100%;
  }

  .recurring-edit__content-title {
    font-size: 22px;
  }

  .recurring-edit__content-field-title{
    width: 40%;
    padding-right: 10px;
  }

  .recurring-edit__content-field-value{
    width: 50%;
    padding-left: 10px;
  }
}

@media (max-width: 600px) {
  .recurring-edit__btns {
    flex-direction: column;
    gap: 10px;
  }
}
@media (max-width: 500px) {
  .recurring-edit__content-title {
    font-size: 18px;
  }
}