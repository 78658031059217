@import "../../../variables/index";

.create-auto-payment {
  width: $width;
  margin: 0 auto;
  flex-grow: 1;

  &__header {

    &-select-category {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: $gray-color;
      padding: 6px 0 20px;
    }
  }
}
