@import "../../../variables/index";

.asan-imza {
  width: $width;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  padding-bottom: 120px;

  &-body {
    flex-grow: 1;
  }

  &__content {
    &-title {
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      padding-top: 20px;
    }
  }

  &__label {
    color: $gray-color;
    font-weight: 500;
    font-size: 16px;
    line-height: 35px;
  }

  &__button {
    padding-top: 30px;
  }
}

.asan-imza__content {
  margin: 15px 0;
}

.replenishment-of-mobile__telephone-wrap, .replenishment-of-mobile__input-div {
  width: 70%;
  display: flex;
}

.asan-imza__label {
  min-width: 130px;
}

.replenishment-of-mobile__telephone-wrap input, .replenishment-of-mobile__input-div input {
  width: 300px;
}

.replenishment-of-mobile__telephone-wrap input, .replenishment-of-mobile__input-div input {
  padding-bottom: 0px;
}

@media only screen and (max-width: 900px) {
  .asan-imza-left {
    display: none;
  }
  .asan-imza__title {
    display:none;
  }
  .asan-imza__content-title {
    font-size: 26px;
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: 550px) {
  .asan-imza__button {
    width: 100%;
    .custom-button {
      width: 100%;
      padding: 14px !important;
      text-align: center;
    }
  }
  .asan-imza__content-title {
    font-size: 18px;
  }
  .office-body__content-text {
    font-size: 14px;
  }
}

@media only screen and (max-width: 370px) {
  .replenishment-of-mobile__telephone-wrap input, .replenishment-of-mobile__input-div input {
    width: 170px ;
  }
}