@import "../../variables/index";

.cards-box {
  display: flex;
  flex-direction: column;
  width: 100%;

  &__title {
    display: flex;
    margin-bottom: 21px;
    align-items: center;
  }

  &__text {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: $gray-color;
  }

  &__image {
    width: 20px;
    height: 20px;
    margin: 0 7px 0 25px;
  }

  &__icon {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &__text--orange {
    font-size: 12px;
    line-height: 15px;
    color: #EF5C27;
    cursor: pointer;
  }
}


@media (max-width: 400px) {
  .cards-box__image {
    margin-left: 10px;
  }
}