@import "../../../variables/index";

.debt {
  max-width: $width;
  margin: 0 auto;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  //padding: 0 10px;
  //height: 100vh;

  &__header {
    width: 100%;
  }

  &__body {
    justify-content: space-between;
    display: flex;
    //padding-top: 18px;

    &-left {
      width: 100%;
      flex-grow: 1;
      position: relative;
      //margin: 0 auto;

      &-head {
        display: flex;
        justify-content: space-between;
        margin: 5px 0;

        &-text {
          align-self: center;
          padding-bottom: 10px;
        }
      }

      &-tabs {
        padding-top: 25px;
      }
    }
  }

  &__body-left-content {
    //margin-top: 25px;
    display: flex;
    justify-content: space-between;

    &-left {
      width: 66%;
    }

    &-right {
      width: 33%;
      background: #ffffff;
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12);
      border-radius: 15px;
      overflow: hidden;
      padding: 24px;
    }

    .css-yw020d-MuiAccordionSummary-expandIconWrapper {
      padding-left: 5px;
    }
    .tab-panel {
      .css-u7qq7e {
        padding: 8px 10px 10px !important;
      }
      .css-1fx8m19 {
        padding-left: 5px !important;
      }
    }
  }
}

.modal-wrap {
  margin: 20px;

  &__body {
    display: flex;
    flex-direction: column;

    &-text {
      display: flex;
      flex-direction: column;
      padding-bottom: 20px;

      &-txt {
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: $gray-color;
      }

      &-pay {
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: $black-color;
        cursor: pointer;
        text-decoration: none;
      }
    }
  }

  .modal-debt {
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
    width: 367px;

    &__image {
      cursor: pointer;
      height: 24px;
      width: 24px;
    }

    &__head-text {
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      color: $black-color;
    }
    &__body {
      display: flex;
      flex-direction: column;

      &-btn {
        text-decoration: none;
      }

      &-number {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: $gray-color;
        padding-bottom: 20px;
      }

      &-numinput {
        padding-bottom: 20px;
      }
    }
  }
}

@media (max-width: 900px) {
  .debt__body-left {
    width: 100%;
  }

  .debt {
    border-radius: 25px 25px 0 0;
    //margin-top: -22px;
    background: white;
  }

  .debt__body-left-content-right {
    display: none;
  }

  .debt__body-left-content-left {
    width: 100%;
  }

}

@media (max-width: 770px) {
  .debt__body-left-head-text {
    padding: 0;
  }
}

@media (max-width: 615px) {
  .debt__body-left-content-right {
    padding: 12px;
  }
}

@media (max-width: 400px) {
  .debt__body-left-head-text {
    .header-text {
      margin-bottom: 10px;
    }
  }
}


