@import '../../variables/index';

.layout {
  max-width: $width;
  padding: 15px 20px 20px 20px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin: 0 auto;
  width: 100%;

  &__navigation {
    margin-bottom: 15px;
  }

  &__body {
    display: flex;
  }

  &__content {
    width: 66%;
    flex-grow: 1;
  }

  &__app-logout {
    display: none;
  }

  &__app {
    width: 33%;
    margin-left: 20px;
  }
}

.layout__app-otherWrap {
  display: none;
}

.layout__app-other {
  margin-bottom: 30px;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12);
  border-radius: 15px;
  overflow: hidden;
  padding: 24px;
}

.layout__app-otherSwiper {
  display: flex;
}

@media only screen and (max-width: 900px) {
  .layout {
    border-radius: 25px 25px 0 0;
    margin-top: -22px;
    background: white;
    padding: 20px 0 20px 20px;
  }
  .layout__navigation {
    margin: 0;
  }
  .layout__body {
    display: flex;
  }
  .layout__content {
    width: 100%;
  }
  .layout__app-other {
    display: none;
  }

  .layout__app {
    display: none;
    width: unset;
  }

  .layout__app-otherWrap {
    display: flex;
    width: 130px;
    margin-top: -51px;
    border-left: 1px solid rgba(228, 228, 228, 1);
    margin-left: 10px;

  }

  .layout__app-otherSwiper {
    display: flex;
    width: 130px;

    .slider-container {
      width: 100%;
      min-height: 100% !important;

      .slider-other-payment {
        margin: 16px 22px 22px 22px;
        font-size: 16px;
        text-align: center;
      }

      .slick-list {
        display: flex;
        margin-bottom: 20px;
        overflow: hidden;
        position: relative;

        &::before {
          content: '';
          background: linear-gradient(180deg, #fff0, #fff 100%);
          position: absolute;
          bottom: 0;
          width: 100%;
          height: 100px;
          z-index: 1;
        }

        &::after {
          content: '';
          background: linear-gradient(180deg, #fff0, #fff 100%);
          position: absolute;
          top: 0px;
          width: 100%;
          height: 100px;
          z-index: 1;
          transform: rotateX(180deg);
        }

      }

      .slick-track {
        width: 110px;
      }

      .slick-slide {
        margin-bottom: 10px;
      }

      .slick-slide .slick-active .slick-current {
        width: 110px !important;
      }

      .slick-slide {
        width: 110px !important;
      }

      .slider-container-item {
        display: flex !important;
        width: 110px !important;
        border-radius: 10px;
        align-items: center;
        text-decoration: none;
        flex-direction: column;

        &__left {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 46px;
          height: 46px;
          border-radius: 50%;
          box-shadow: 0 3px 8px #0000003d;

          img {
            width: 30px;
            height: 30px;
          }
        }

        &__right {
          color: rgba(71, 71, 71, 1);
          font-size: 14px;
          margin: 8px;
          text-align: center;
        }

      }

      .slick-prev {
        top: -7px !important;
        left: 39% !important;
        width: 30px !important;
        height: 30px !important;
        z-index: 1;
      }

      .slick-next {
        top: 100% !important;
        right: 36% !important;
        width: 30px !important;
        height: 30px !important;
        z-index: 1;
      }

      .slick-next:before {
        content: none;
      }

      .slick-prev:before {
        content: none;
      }
    }
  }

  .hide-menu {
    display: flex;
  }

  .layout__app-logout {
    display: flex;
  }
}

@media (max-width: 835px) {
  .layout__app-otherSwiper {
    width: 120px;
  }
}

@media (max-width: 800px) {
  .layout__content {
    width: 60%;
  }
  .layout__app-otherSwiper {
    width: 130px;
  }
}

@media only screen and (max-width: 800px) {
  .hide__layout {
    flex-direction: column;
    .layout__content {
      width: 100%;
    }
    .header-text__title {
      display: none;
    }
    .right-basket {
      width: 100%;
      margin-top: 36px;
      border: 2px solid #F1F1F1;
      border-left: none;
      border-right: none;
      box-shadow: none;
      border-radius: 0;
    }
    .layout__app  {
      margin-left: 0;
    }
  }
}

@media (max-width: 720px) {
  .layout {
    padding-left: 10px;
  }
}

@media (max-width: 615px) {
  .layout__app {
    margin-left: 5px;
  }
}

@media (max-width: 600px) {
  .slider-container-item__right {
    font-size: 12px !important;
  }
}

@media (max-width: 520px) {
  .slider-container {

    .slider-container-item__left {
      width: 40px !important;
      height: 40px !important;

      img {
        width: 24px !important;
        height: 24px !important;
      }
    }
  }
}

@media (max-width: 500px) {
  .layout__app-otherWrap {
    display: none;
  }
}