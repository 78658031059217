@import "../../variables/index";


.sidebar {
  background: $white-color;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12);
  border-radius: 15px;
  overflow: hidden;
  &__header {
    display: flex;
    margin-right: -9px;
    border-bottom: 1px solid #F1F1F1;
    justify-content: space-between;
    padding: 12px;

  }

  &__header-item {
    border-radius: 15px;
    padding: 5px 8px;
    margin-right: 9px;
    width: 33%;
      text-decoration: none
    //width: 102px;
    //height: 103px;
  }

  &__header-item--green {
    background: $yellow-white-color;
    color: #8AC12D;
  }

  &__header-item--purple {
    background: #F3EFFF;
    color: #9673FF;
  }

  &__header-item--pink {
    background: $background-orange-color-o;
    color: #FF4239;
  }

  &__header-image {
    width: 30px;
    height: 30px;
    margin-bottom: 15px;
  }

  &__icon {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &__header-title {
    font-weight: 500;
    font-size: 12px;
    line-height: 17px;
    color: inherit;
  }

  &__header-subtitle {
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: $black-color;

    span {
      font-size: 14px;
      margin-left: 5px;
    }
  }

  &__header-dept {
    display: flex;
    justify-content: space-between;
  }

  &__header-currency-box {
    display: flex;
    flex-direction: column;
    //margin-left: 20px;
  }

  &__header-currency {

    font-size: 12px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    //padding: 10px 0 10px 10px;
    padding: 10px 0 10px 0;
  }

  &__content-item {
    display: flex;
    //margin-bottom: 10px;
    text-decoration: none;
    cursor: pointer;
    padding: 14px 10px;
    border-left: 3.5px solid #fff;
    transition: 0.1s border-left ease;
  }

  &__content-item:hover {
    background-color: $background-orange-color-o;
    border-left: 3.5px solid #EF5C27;
  }

  &__content-item--active {
    background-color: $background-orange-color-o;
    position: relative;
    border-left: none;
    &::before {
      position: absolute;
      content: '';
      bottom: 0;
      top: 0;
      left: 0;
      width: 3px;
      background-color: $orange-color;
      border-radius: 15px;
    }
  }

  &__content-image {
    width: 30px;
    height: 30px;
    margin-right: 11px;
  }

  &__content-title {
    align-self: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: $black-color;
  }

  &__content-item--cards {
    padding-bottom: 10px;
    border-bottom: 1px solid #F1F1F1;
  }

  &__content-count {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    padding: 5px;
    border-radius: 50%;
    background-color: $orange-color;
    color: $white-color;
    margin-left: 5px;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
  }

}


.registration-block {
  height: max-content;
  //width: 367px;
  //margin: 20px 0;
  border-radius: 15px;
  box-shadow: $shadow-box-color 0px 3px 8px;
  //width: 32%;

  &__modal {
    width: 100%;
    margin: 0;

    &-head {
      display: flex;
      justify-content: space-between;

      &-text {
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        color: #474747;
      }
    }

    &-body {
      display: flex;
      margin-top: 24px;
      width: 100%;
      &-logout {
        width: 100%;
        display: flex;
        gap: 15px;
        justify-content: space-evenly;

        .custom-button, .modal-exit__text {
          flex: 1;
          text-align: center;
        }
      }
    }
  }
}

@media only screen and (max-width: 1100px) {
    .sidebar__header-title {
      font-size: 12px;
      line-height: 12px;
    }

  .sidebar__header-subtitle {
    font-size: 12px;
    line-height: 15px;

    span {
      font-size: 10px;
      line-height: 12px;
    }
  }
  .sidebar__header-item {
    padding: 10px 8px;
  }
  .sidebar__header-image {
    width: 25px;
    height: 25px;
  }

  .sidebar__header-currency {
    font-size: 10px;
  }
}

@media only screen and (max-width: 960px) {
  .sidebar__header-title {
    font-size: 12px;
    line-height: 10px;
    padding-bottom: 5px;
  }

  .sidebar__header-subtitle {
    font-size: 12px;
    line-height: 12px;

    span {
      font-size: 8px;
      line-height: 10px;
    }
  }

  .sidebar__header-image {
    width: 20px;
    height: 20px;
  }

  .sidebar__header-currency {
    font-size: 8px;
  }

  .sidebar__header {
    //padding: 10px;
  }

  .sidebar__header-item {
    margin-right: 6px;
    padding: 8px 6px;
  }

  .sidebar__content-image {
    width: 25px;
    height: 25px;
  }

  .sidebar__content-title {
    font-size: 12px;
    line-height: 15px;
  }
}

@media only screen and (max-width: 1100px) {
  .sidebar{
    &__header {
      padding: 7px;
    }
    &__content-title {
      font-size: 12px;
      line-height: 15px;
    }
  }
}

@media only screen and (max-width: 900px) {
  .sidebar{
    &__header {
      padding: 0;
    }
  }
}

@media (max-width: 900px) {
  .exit-modal-header {
    .registration-block__modal-body-logout {
      .custom-button, .modal-exit__text {
        // width: 100% !important;
        flex: 1;
        padding: 14px 100px !important;
      }
    }
  }
  .sidebar__content-item {
    padding: 10px 0;
  }
}

@media only screen and (max-width: 760px) {
  .registration-block__modal-body-logout {
    flex-direction: column;
    .custom-button {
      width: 100% !important;
    }
  }

}

@media (max-width: 510px) {
  .sidebar__content {
    padding: 10px 0 10px 10px;
  }

}

@media only screen and (max-width: 400px) {
  .registration-block__modal-head-text {
    font-size: 16px;
  }
  .registration-block__modal-body-logout {
    .custom-button {
      width: 100% !important;
      padding: 12px 80px !important;
    }
  }
  .exit-modal-header {
    .registration-block__modal-body-logout {
      .custom-button {
        width: 100% !important;
        padding: 12px 10px !important;
      }
    }
  }
}




//
//  &__wrapper {
//    width: 100%;
//    margin: 0 auto;
//  }
//
//  &__line {
//    border: 1px solid $white-color-border;
//    width: 90%;
//    margin: 0 auto;
//  }
//
//  &__header {
//    //padding: 20px 15px 20px;
//    //display: flex;
//    //justify-content: space-between;
//
//    padding: 10px 0;
//    display: flex;
//    justify-content: space-between;
//    width: 95%;
//    //border: 1px solid red;
//    margin: 0 auto;
//
//    &-wrap {
//      display: flex;
//    }
//
//    &-image {
//      width: 26px;
//      height: 23px;
//    }
//
//    &-text {
//      padding-top: 19px;
//      font-style: normal;
//      font-weight: 500;
//      font-size: 14px;
//      line-height: 17px;
//    }
//
//    &-price {
//      font-style: normal;
//      font-weight: 500;
//      font-size: 18px;
//      line-height: 22px;
//      color: $black-color;
//
//      @media only screen and (max-width: 950px) {
//        font-size: 14px;
//      }
//    }
//
//    &-bill {
//      font-style: normal;
//      font-weight: 300;
//      font-size: 12px;
//      line-height: 22px;
//      color: $black-color;
//      padding: 2px 0 0 5px;
//
//      @media only screen and (max-width: 950px) {
//        font-size: 10px;
//      }
//    }
//
//    &-item {
//      padding: 6px 0 6px;
//    }
//
//    &-exit {
//      padding: 6px 0 20px;
//    }
//  }
//
//  &__header-div {
//    width: 102px;
//    height: 103px;
//    border-radius: 15px;
//    display: flex;
//    cursor: pointer;
//
//    &-block {
//      width: 80%;
//      display: flex;
//      flex-direction: column;
//      margin: 0 auto;
//      align-self: center;
//      text-decoration: none;
//    }
//  }
//}

//.add-friend {
//  width: 394px;
//  height: 225px;
//  display: flex;
//  flex-direction: column;
//  padding: 20px;
//
//  &__btn {
//    padding-top: 20px;
//  }
//
//  &__wrap {
//    display: flex;
//    justify-content: space-between;
//
//
//    &-text {
//      font-weight: 600;
//      font-size: 20px;
//      line-height: 24px;
//      color: $black-color;
//    }
//
//    img {
//      cursor: pointer;
//    }
//
//    &-number {
//      font-weight: 500;
//      font-size: 14px;
//      line-height: 17px;
//      color: $gray-color;
//      padding: 20px 0;
//    }
//
//    &-inputNumber {
//      width: 350px;
//    }
//  }
//}
