.auto-payment {
  display: flex;
  align-items: center;
  padding: 15px 0px;
  border-bottom: 1px solid #F1F1F1;

  &__image-wrapper {
    margin-right: 15px;
    width: 45px;
    height: 45px;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &__payment-info {
    display: flex;
    flex-direction: column;
    min-width: 312px;
    margin-right: 20px;
  }

  &__payment-title {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #474747;
  }

  &__payment-subtitle {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #888888;
  }

  &__payment-price {
    line-height: 100%;
    color: #474747;
    font-size: 16px;
    text-transform: uppercase;
    min-width: 142px;
    margin-right: 20px;
  }

  &__price-value {
    font-weight: 600;
  }

  &__payment-date {
    display: flex;
    flex-direction: column;
    font-weight: 500;
    margin-right: 37px;
  }

  &__payment-switch {
    margin-right: 20px;
  }

  &__date-title {
    font-size: 12px;
    line-height: 15px;
    color: #888888;
    margin-bottom: 4px;
  }

  &__date-subtitle {
    font-size: 16px;
    line-height: 100%;
    color: #474747;
  }

  &__payment-icon {
    text-decoration: none;
    display: flex;
    align-content: center;
  }
}