@import "../../variables/index";

.no-registration-main {
  height: max-content;
  width: 100%;
  margin: 0 0 20px 0;
  padding: 5px;
  border-radius: 15px;
  box-shadow: $shadow-box-color 0 3px 8px;

  &__forgot-password {
    display: flex;
    margin: 10px auto;
    color: $orange-color;
    cursor: pointer;
    border-bottom: 1px dotted $orange-color;
    //font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    width: 125px;
  }

  .css-19kzrtu {
    padding: 10px 0 !important;
  }
  .css-1aquho2-MuiTabs-indicator {
    background-color: $orange-color !important;
  }

  &__header {
    width: 90%;
    margin: 0 auto;

    &-div {
      padding-bottom: 26px;

      &-text {
        font-style: normal;
        font-weight: 500;
        font-size: 26px;
        line-height: 32px;
        color: $black-color;
      }
    }

    &-head {
      padding-bottom: 30px;
    }

    &-image-wrap {
      display: flex;
      padding-bottom: 8px;
    }

    &-img {
      object-fit: cover;
      width: 26px;
      height: 26px;
    }

    &-item {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: $black-color;
      align-items: center;
      padding-left: 10px;
      display: flex;
      justify-content: center;
    }
  }

  &__register-main {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: $gray-color;
    padding-bottom: 19px;
  }

  &__register-input {
    padding-bottom: 45px;
  }

  &__register-footer {
    display: flex;
    padding-bottom: 25px;

    &-div {
      display: flex;
    }

    &-text {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: $black-color;
      padding-left: 8px;
    }

    &-link {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: $orange-color;
      text-decoration: none;
      padding-left: 8px
    }
  }

  &__register-button {
    width: 100%;
    display: flex;
    padding-bottom: 10px;
    padding-top: 15px;
  }
}
.no-registration-main__register-forgot-btn {
  width: 30%;
  display: flex;
  align-content: center;
  justify-content: center;
  margin: 0 auto;
  padding: 15px 0;
}

@media only screen and (max-width: 2000px) {
  .no-registration-main__header {
    #simple-tab-0 {
      //display: none;
      //display: flex;
    }
    .makeStyles-tabTwo-4.MuiButtonBase-root.MuiTab-root {
      width: 50%;
    }
  }

  .registerTab {
    #simple-tab-0 {
      display: none;
    }

    .css-ttwr4n {
      position: absolute;
      height: 2px;
      bottom: 0px;
      //width: 100%;
      transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      background-color: red !important;
    }

    #simple-tab-1 {
      width: 50%;
    }

    #simple-tab-2 {
      width: 50%;
    }

    //.makeStyles-tabTwo-28.MuiButtonBase-root.MuiTab-root {
    //  width: 50%;
    //}
  }

  .register-style {
    #simple-tab-0 {
      display: flex;
    }

    #simple-tab-1 {
      display: none;
    }
    .css-ttwr4n {
      background-color: #e74c3c !important;
    }
  }
}

@media only screen and (max-width: 935px) {
  .no-registration-main__header-div-text {
    font-size: 22px;
    line-height: 28px;
  }
}

@media only screen and (max-width: 900px) {
  .no-registration-main__header {

    .image-asan {
      width: 62px;
    }
    #simple-tab-0 {
      display: flex;
      font-size: 12px;

      .makeStyles-tabTwo-20.MuiButtonBase-root.MuiTab-root {
        width: 31%;
      }
    }
    #simple-tab-1 {
      font-size: 12px;
    }
    .registerTab {

      #simple-tab-0 {
        width: 31%;
      }

      #simple-tab-1 {
        width: 31%;
      }

      #simple-tab-2 {
        width: 31%;
      }

      .css-1ujnqem-MuiTabs-root {
        width: 100%;
      }
    }
    .makeStyles-tabTwo-4.MuiButtonBase-root.MuiTab-root {
      width: 31%;
    }

    .css-heg063-MuiTabs-flexContainer {
      display: flex;
      justify-content: space-between;
    }

    //.makeStyles-tabTwo-4.MuiButtonBase-root.MuiTab-root.Mui-selected {
    //  color: #EF5C27;
    //  display: flex;
    //  flex-direction: row;
    //}
  }

  .register-style {
    #simple-tab-0 {
      width: 31%;
    }

    #simple-tab-1 {
      display: flex;
      width: 31%;
    }

    #simple-tab-2 {
      width: 31%;
    }

    .css-ttwr4n {
      background-color: #e74c3c !important;
    }
  }
}

@media only screen and (max-width: 797px) {
  .no-registration-main__header-div-text {
    font-size: 18px;
    line-height: 24px;
  }
}

@media only screen and (max-width: 600px) {
  .registerTab {
    .css-ahj2mt-MuiTypography-root {
      font-size: 13px;
    }
  }

}

@media only screen and (max-width: 480px) {
  .no-registration-main__header-div-text {
    text-align: center;
  }
  .registerTab {
    .css-ahj2mt-MuiTypography-root {
      font-size: 14px;
    }
    .css-9l3uo3 {
      font-size: 14px;
    }
    .css-ttwr4n {
      background-color: #EF5C27 !important;
    }
  }

  .no-registration-main__register-forgot-btn {
    width: 60%;
  }
}
@media only screen and (max-width: 350px) {
  .no-registration-main__header-div-text {
    text-align: center;
  }
  .registerTab {
    .css-ahj2mt-MuiTypography-root {
      font-size: 12px;
    }
    .css-9l3uo3 {
      font-size: 12px;
    }
  }
}

