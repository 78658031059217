@import "../../../variables/index";

.wrap-imza {
  max-width: $width;
  margin: 0 auto;
  display: flex;
  flex-grow: 1;
  width: 100%;
  justify-content: space-between;
  padding: 0 10px 0 0 ;

  &__body {
    width: 66%;
    //padding-right: 20px;
    padding: 0 10px;

    &-content {
      display: flex;
      flex-direction: column;

      &-text {
        display: flex;
        margin-bottom: 30px;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #474747;
      }

      &-about {
        display: flex;
        margin-bottom: 30px;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #EF5C27;
      }
    }
  }

  &-right {
    width: 33%;
    //margin-top: 10px;
  }

  &__footer {
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;

    &-w {
      width: 100%;
    }

    &-span {
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: #757575;
      margin-bottom: 10px;
    }

    &-btn {
      display: flex;
      margin-top: 30px;
    }
  }
}

@media (max-width: 900px) {
  .wrap-imza-right {
    display: none;
  }
  .wrap-imza__body {
    width: 100%;
    padding: 10px 10px 0 10px;
  }
  .wrap-imza {
    border-radius: 25px 25px 0 0;
    margin-top: -22px;
    background: white;
  }

}

@media (max-width: 330px) {
  .wrap-imza__footer {
    width: 100%;
  }
  .wrap-imza__footer-btn {
    .custom-button {
      width: 100%;
    }
  }

}


