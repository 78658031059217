@import "../../../../variables/index";

.safety-code {
  max-width: $width;
  margin: 0 auto;
  display: flex;

  padding-bottom: 80px;
  flex-grow: 1;

  &-body {
    min-width: 753px;
    padding-right: 20px;

    &__title {
      padding-bottom: 30px;
      font-size: 26px;
      font-weight: 600;
    }
  }
  &__form {
    width: 25%;
    display: flex;
    flex-direction: column;
    height: 80px;
    justify-content: space-between;
  }

  &__form-input {
    border: none;
    border-bottom: 1px solid $orange-color;
    outline: none;
    width: 367px;
  }

  &__buttons {
    padding-top: 20px;
    display: flex;
  }
}
