@import "../../variables/index";


.home-wrapper{
    //margin-right: -20px;
    overflow: hidden;
    margin-right: -20px;
    margin-left: -10px;
}

.home {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 50px;
    padding-top: 10px;
    padding-left: 15px;

    .service-main {
        display: flex;
        width: 100%;
        //justify-content: space-between;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
        padding-bottom: 20px;

        &__image {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &__img {
            width: 20px;
            height: 20px;
        }

        &__text {
            cursor: pointer;
            color: $black-color;
            font-size: 14px;
            line-height: 18px;
            margin-left: 10px;
            display: flex;
            align-items: center;

            &-span {
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 20px;
                color: $black-color;
            }
        }
    }

    .service-main__item {
        width: calc((100% / 3) - 15px);
        margin: 0 15px 15px 0;
        text-decoration: none;
        transition-duration: 0.3s;
        display: flex;

        .service-main__icon {
            width: 48px;
            height: 48px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            cursor: pointer;
        }

    }

    .service-main__item:hover {
        transform: scale(1.1);
        transition-duration: 0.3s;
    }
}

.home .service-main__img.filtered-img {
    width: 48px;
    height: 48px;
}

@media (max-width: 1110px) {
    .home .service-main__text {
        font-size: 14px;
        width: 70%;
    }
}

@media (max-width: 980px) {
    .home .service-main__text {
        font-size: 12px;
    }

    .home .service-main__item .service-main__icon {
        width: 50px;
        height: 50px;
    }

    .home .service-main__img {
        width: 20px;
        height: 20px;
    }
}

//@media only screen and (max-width: 960px) {
//
//    .home .service-main__item .service-main__icon {
//        width: 57px;
//        height: 57px;
//    }
//
//    .home .service-main__img {
//        width: 24px;
//        height: 24px;
//    }
//}

@media (max-width: 900px) {
    .home .service-main__item:hover {
        transform: scale(1);
    }
}


@media (max-width: 720px) {
    .home {
        margin-bottom: 20px;
    }
}

@media (max-width: 560px) {
    .home .service-main__text {
        font-size: 10px;
    }

    .home .service-main__item .service-main__icon {
        width: 40px;
        height: 40px;
    }

    .home .service-main__img {
        width: 15px;
        height: 15px;
    }
}

@media only screen and (max-width: 520px) {

    .home .service-main__item {
        width: calc((100% / 2) - 15px);
    }
}
