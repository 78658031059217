@import "../../../variables/index";

.edit-payment {
  width: $width;
  margin: 0 auto;
  padding-bottom: 100px;

  &__body {
    display: flex;
    justify-content: space-between;
  }

  &__content {
    padding-top: 14px;
  }

  &__content-title {
    margin-bottom: 32px;
  }

  &__content-box {
    display: flex;
    flex-direction: column;
    padding-bottom: 11px;
    position: relative;
    margin-bottom: 36px;
    width: 100%;
    &::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 753px;
      height: 1px;
      background-color: $white-color-border;
    }
  }

  &__content-field {
    display: flex;
    margin-bottom: 22px;
    align-items: center;
  }

  &__content-field-title {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: $gray-color;
    min-width: 30%;
  }

  &__content-field-value {
    display: flex;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: $black-color;
    align-items: center;
  }

  &__input {
    border: none;
    outline: none;
    padding: 0 6px 0 0;
    color: #474747;
    width: fit-content;
    max-width: 150px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    margin-left: 0;
    &:disabled {
      background: transparent;
      opacity: 0.7;
    }
  }

  &__icon {
    margin-left: 10px;
    cursor: pointer;
  }


}

.btns-box {
  display: flex;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #EF5C27;
  height: fit-content;

  &__btn {
    cursor: pointer;
    margin: 0 5px;
    border-bottom: 1px dashed #EF5C27;
  }
}

@media (max-width: 900px) {
  .transfer__body {
    flex-direction: column;

    .transaction__side-menu {
      margin-top: 20px;
    }
  }
}