.messageInfo {
  display: flex;
  max-width: 1180px;
  margin: 0 auto;
  //padding: 0 20px;
  width: 100%;
  justify-content: space-between;
}
.message-info {
  display: flex;
  width: 66%;
  flex-direction: column;
  //padding: 10px 0;

  &__header-back {
    width: fit-content;
    align-items: baseline;
    display: flex;
    margin: 0 0 15px 0 !important;
    //padding-bottom: 15px;
    z-index: 1;

    &-icon {
      width: 20px;
      height: 20px;
      background-color: #ffeae9;
      border-radius: 50%;
      cursor: pointer;
    }

    &-img {
      width: 10px;
      height: 10px;
      margin-left: 4px;
    }

    &-text {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: #ef5c27;
      margin-left: 9px;
      cursor: pointer;
    }
  }

  &__header-information {
    display: flex;
    flex-direction: column;

    &-head {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 15px;
    }
    &-text {
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      color: #474747;
      align-self: center;
    }

    &-date {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      color: #474747;
      align-self: center;
    }
  }
  &__banner {
    box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
    border-radius: 15px;
    margin-bottom: 30px;
    //margin-top: 20px;

    &-text {
      padding: 30px;

      &-title {
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 29px;
        color: #474747;
        margin-bottom: 15px;
      }

      &-bonus {
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        color: #888888;
      }
    }
  }

  &__header-text {
    display: flex;
    margin-bottom: 20px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #474747;
  }
}

.message-right-menu {
  width: 33%;
}

@media (max-width: 900px) {
  //.message-info__header-back {
  //  display: none;
  //}

  .message-info__header-back {
    margin: 0 0 5px 0;
  }

  //.messageInfo {
  //  border-radius: 25px 25px 0 0;
  //  margin-top: -22px;
  //  background: white;
  //}

  .message__header {
    display: flex;
    margin: 10px 0 25px 0;
  }
  .message-right-menu {
    display: none;
  }

  .message-info {
    width: 100%;
    padding: 10px 0 100px 0;
  }
}

@media (max-width: 500px) {
  .message-info__header-information-text {
    font-size: 16px;
  }

  .message-info__header-information-date {
    font-size: 12px;
  }
  .message-info__header-text {
    font-size: 12px;
  }

  .message-info__banner-text-title {
    font-size: 20px;
    //padding: 0 15px 0 0;
  }

  .message-info__banner-text-bonus {
    font-size: 14px;
  }
}

@media (max-width: 440px) {
  .message-info__banner-text-title {
    font-size: 18px;
    padding: 0 15px 0 0;
  }
  .message-info__banner-text-bonus {
    font-size: 12px;
  }
}
